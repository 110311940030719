import { BiPlusCircle, BiDotsVerticalRounded } from "react-icons/bi";
import { MdOutlineDescription } from "react-icons/md";
import Button from "../../components/button/Button";
import TranslatableText from "../../components/translations/TranslatableText";
import { TranslationKey } from "../../types/modulesInterfaces";

export const ProjectDescription = ({ user }: { user: string | null }) => {
	return (
		<section className="pt-1">
			<h3 className="welcome-user-header ff-medium-ext">
				<TranslatableText translationKey="main.welcome"/> <span className="capitalize">{user}</span>
			</h3>
			<div className="py-2 fs-11 ff-normal select-project-p">
				<p>
					<TranslatableText translationKey="main.welcome_message_1"/>			
				</p>
				<p>
					<TranslatableText translationKey="main.welcome_message_2"/>			
				</p>
				{/* <MaintenanceBanner/> */}
			</div>
		</section>
	);
};
const MaintenanceBanner = () => {
	const maintenanceDate = "Thursday, January 26th";
	const maintenanceStartHour = "12:00 am EST";
    const maintenanceEndHour = "6:00 am EST";
    return (
        <div className="maintenance-banner p-2 mt-2">
            <TranslatableText
				style={{ display: "flex" }}
                translationKey="main.maintenance_banner" 
                translationParams={{ date:maintenanceDate, startHour: maintenanceStartHour, endHour: maintenanceEndHour }} 
				paramsComponents={Array(3).fill(<div style={{ color: "red", margin: "0px 2px 0px 2px", fontWeight: "bold" , opacity: "90%" }}/>)}
            />
        </div>
    );
};

export const StartNewProjectButton = ({
	handleClick,
}: {
	handleClick: () => void;
}) => {
	return (
		<Button
			classes="btn-new-project d-flex align-items-center"
			onClick={handleClick}
		>
			<div className="h-100 d-flex align-items-center btn-arrow bg-quat-de p-1">
				<BiPlusCircle size={20} color="var(--light-gray)" />
			</div>
			<TranslatableText className="h-100 fs-11 px-2 d-flex align-items-center ff-medium bg-white" translationKey="project.start_project"/>
		</Button>
	);
};

export const NoteComponent = ({
	link,
	disabled,
}: {
	link: string;
	disabled?: boolean;
}) => {
	const handleClick = () => {};

	return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-center">
			<Button
				onClick={handleClick}
				disabled={disabled}
				classes="bg-white"
				whileHoverProps={{
					scale: 1,
					padding: "0.2rem",
					border: "1px solid black",
					borderRadius: "5px",
				}}
			>
				<MdOutlineDescription
					size={16}
					opacity={disabled ? 0.25 : 1}
					color="var(--darker-gray)"
				/>
			</Button>
		</div>
	);
};

export const ThreeDots = ({ handler }: { handler: () => void }) => {
	return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-center">
			<Button
				onClick={handler}
				classes="bg-white"
				whileHoverProps={{
					scale: 1,
				}}
			>
				<BiDotsVerticalRounded size={16} />
			</Button>
		</div>
	);
};

export const HeaderSpan = ({
	name,
	center,
}: {
	name: TranslationKey;
	center?: boolean;
}) => {
	return (
		<span
			className={`fs-11 text-white fw-400 ${
				center ? " w-100 text-center" : ""
			}`}
		>
			<TranslatableText translationKey={name}/>
		</span>
	);
};

/* const oldData: Project[] = [
 {
  projectName: "Nambia Project Name",
  location: "Nambia",
  climate: "Dry",
  moisture: "Medium",
  soilType: "Weathered",
  carbonBalance: 2000,
  notes: {
   link: "",
   disabled: false,
  },
  id: 0,
 },
 {
  projectName: "Bhutan Project Name",
  location: "Bhutan",
  climate: "Humid",
  moisture: "High",
  soilType: "Rocky",
  carbonBalance: 2400,
  notes: {
   link: "",
   disabled: false,
  },
  id: 1,
 },
 {
  projectName: "Turkey Project Name",
  location: "Turkey",
  climate: "Longer cell content get a small pop over on hover",
  moisture: "low",
  soilType: "Rocky",
  carbonBalance: 2000,
  notes: {
   link: "",
   disabled: false,
  },
  id: 2,
 },
 {
  projectName: "Sudan Project Name",
  location: "Sudan",
  climate: "Dry",
  moisture: "low",
  soilType: "Rocky",
  carbonBalance: 1900,
  notes: {
   link: "",
   disabled: false,
  },
  id: 3,
 },
]; */
