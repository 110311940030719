import type { LandUseChangeResponse } from "../pages/activityBuilder/modules/landUseChanges/landUseChangeTypes";
import type { Activity, ModuleStatus } from "../types/interfaces";
import { TranslationKey } from "../types/modulesInterfaces";
import { BEModules } from "./beModules";

export enum FEModules {
	PerennialCropland = 1,
	AnnualCropland = 2,
	LivestockManagement = 3,
	Energy = 4,
	Irrigation = 5,
	Inputs = 6,
	FloodedRice = 7,
	ForestManagement = 8,
	LandUseChange = 9,
	LargeFisheries = 10,
	SmallFisheries = 11,
	Aquaculture = 12,
	OrganicSoil = 13,
	CoastalWetlands = 14,
	Waterbodies = 15,
	SetAside = 16,
	OtherLand = 17,
	Settlements = 18,
	Grassland = 19,
	Packaging = 21,
	Transport = 22,
	Storage = 23,
	Processing = 24,
	ActivityComplete = 20,
}

export const MODULES_WITH_LAND_USE = [
	FEModules.PerennialCropland,
	FEModules.AnnualCropland,
	FEModules.FloodedRice,
	FEModules.ForestManagement,
	FEModules.Grassland,
	FEModules.SetAside,
	FEModules.OtherLand,
	FEModules.Settlements,
	// FEModules.CoastalWetlands, 
];

export const isInFEModules = (num: number) =>
	Object.values(FEModules).includes(num);

export const isInBEModules = (num: number) =>
	Object.values(BEModules).includes(num);

export type FE2BEModuleObj = {
	[key in FEModules]: {
		name: TranslationKey;
		beModuleId?: BEModules;
		path: string
	};
};

export const FEModulesObj: FE2BEModuleObj = {
	[FEModules.LandUseChange]: {
		name: "project.land_use_change",
		beModuleId: BEModules.LandUseChange,
		path: "land-use-changes",
	},
	[FEModules.AnnualCropland]: {
		name: "project.annual_cropland",
		beModuleId: BEModules.AnnualCropland,
		path: "annual-croplands",
	},
	[FEModules.PerennialCropland]: {
		name: "project.perennial_cropland",
		beModuleId: BEModules.PerennialCropland,
		path: "perennial-croplands",
	},
	[FEModules.FloodedRice]: {
		name: "project.flooded_rice",
		beModuleId: BEModules.FloodedRice,
		path: "flooded-rices",
	},
	[FEModules.SetAside]: {
		name: "project.set_aside",
		beModuleId: BEModules.SetAside,
		path: "set-asides",
	},
	[FEModules.Grassland]: {
		name: "project.grassland",
		beModuleId: BEModules.Grassland,
		path: "grasslands",
	},
	[FEModules.ForestManagement]: {
		name: "project.forest_management",
		beModuleId: BEModules.ForestManagement,
		path: "forest-managements",
	},
	[FEModules.Settlements]: {
		name: "project.settlement",
		beModuleId: BEModules.Settlements,
		path: "settlements",
	},
	[FEModules.OtherLand]: {
		name: "project.other_land",
		beModuleId: BEModules.OtherLand,
		path: "other-lands",
	},
	[FEModules.OrganicSoil]: {
		name: "project.organic_soil",
		beModuleId: BEModules.OrganicSoil,
		path: "organic-soils",
	},
	[FEModules.CoastalWetlands]: {
		name: "project.coastal_wetlands",
		beModuleId: BEModules.CoastalWetlands,
		path: "coastal-wetlands",
	},
	[FEModules.Waterbodies]: {
		name: "project.waterbodies",
		beModuleId: BEModules.Waterbodies,
		path: "waterbodies",
	},
	[FEModules.LivestockManagement]: {
		name: "project.livestock_management",
		beModuleId: BEModules.LivestockManagement,
		path: "livestocks",
	},
	[FEModules.SmallFisheries]: {
		name: "project.small_fisheries",
		beModuleId: BEModules.SmallFisheries,
		path: "small-fisheries",
	},
	[FEModules.LargeFisheries]: {
		name: "project.large_fisheries",
		beModuleId: BEModules.LargeFisheries,
		path: "large-fisheries",
	},
	[FEModules.Aquaculture]: {
		name: "project.aquaculture",
		beModuleId: BEModules.Aquaculture,
		path: "aquacultures",
	},
	[FEModules.Inputs]: {
		name: "project.inputs",
		beModuleId: BEModules.Inputs,
		path: "inputs",
	},
	[FEModules.Energy]: {
		name: "project.energy",
		beModuleId: BEModules.Energy,
		path: "energies",
	},
	[FEModules.Irrigation]: {
		name: "project.irrigation",
		beModuleId: BEModules.Irrigation,
		path: "irrigations",
	},
	[FEModules.Packaging]: {
		name: "project.packaging",
		beModuleId: BEModules.Packaging,
		path: "packagings",
	},
	[FEModules.Transport]: {
		name: "project.transport",
		beModuleId: BEModules.Transport,
		path: "transports",
	},
	[FEModules.Storage]: {
		name: "project.storage",
		beModuleId: BEModules.Storage,
		path: "storages",
	},
	[FEModules.Processing]: {
		name: "project.processing",
		beModuleId: BEModules.Processing,
		path: "processings",
	},
	[FEModules.ActivityComplete]: {
		name: "project.activity_complete",
		path: "complete",
	},
};


export interface FeActivityModule {
	id: FEModules;
	name: string;
	path: string;
	uniqueId?: number; //this id refers to the unique id that the specific module has
}

interface Mod {
	beModuleId: BEModules,
	uniqueId: number,
	status: ModuleStatus,
}

// Helper function to create BE to FE module mapping
const createBeToUIDModuleMapping = (mods: Mod[]) => {
	const beModules = mods.map(el => el.beModuleId);
	const ids = mods.map(el => el.uniqueId);

	return beModules.reduce((acc, mod, i) => {
		acc[mod] = ids[i];
		return acc;
	}, {} as Record<number, number>);
};

// Helper function to determine module category
const getModuleCategory = (
	module: BEModules,
	modulesToMiddle: BEModules[],
	modulesToEnd: BEModules[]
): number => {
	if (modulesToEnd.includes(module)) return 2;
	if (modulesToMiddle.includes(module)) return 1;
	return 0;
};

// Helper function to check if module is LUC-related
const isLUCRelated = (
	module: BEModules,
	lucInfo?: {
		start: BEModules | null;
		without: BEModules | null;
		with: BEModules | null;
	}
): boolean => {
	if (!lucInfo) return false;
	return (
		module === BEModules.LandUseChange ||
		module === lucInfo.start ||
		module === lucInfo.without ||
		module === lucInfo.with
	);
};

// Helper function to compare LUC-related modules
const compareLUCModules = (
	x: BEModules,
	y: BEModules,
	lucInfo: {
		start: BEModules | null;
		without: BEModules | null;
		with: BEModules | null;
	}
): number => {
	// Sort order: start -> LUC -> without -> with
	if (x === lucInfo.start) return -1;
	if (y === lucInfo.start) return 1;
	if (x === BEModules.LandUseChange) return -1;
	if (y === BEModules.LandUseChange) return 1;
	if (x === lucInfo.without) return -1;
	if (y === lucInfo.without) return 1;
	if (x === lucInfo.with) return -1;
	if (y === lucInfo.with) return 1;
	return 0;
};

// Helper function to determine module position within category
const getModulePosition = (
	module: BEModules,
	modulesToMiddle: BEModules[],
	modulesToEnd: BEModules[]
): number => {
	const middleIndex = modulesToMiddle.indexOf(module);
	if (middleIndex !== -1) return middleIndex;

	const endIndex = modulesToEnd.indexOf(module);
	if (endIndex !== -1) return endIndex;

	return 0;
};

// Main sorting function that handles both cases
const sortModules = (
	modules: BEModules[],
	lucInfo?: {
		start: BEModules | null;
		without: BEModules | null;
		with: BEModules | null;
	}
) => {
	const sorted = [...modules];
	const modulesToMiddle = [BEModules.Inputs, BEModules.Energy, BEModules.Irrigation];
	const modulesToEnd = [BEModules.Transport, BEModules.Processing, BEModules.Packaging, BEModules.Storage];

	sorted.sort((x, y) => {
		// Handle LUC-related sorting if lucInfo is provided
		const xIsLUCRelated = isLUCRelated(x, lucInfo);
		const yIsLUCRelated = isLUCRelated(y, lucInfo);

		if (lucInfo && (xIsLUCRelated || yIsLUCRelated)) {
			return compareLUCModules(x, y, lucInfo);
		}

		// Handle category-based sorting
		const xCategory = getModuleCategory(x, modulesToMiddle, modulesToEnd);
		const yCategory = getModuleCategory(y, modulesToMiddle, modulesToEnd);

		// If categories are different, sort by category
		if (xCategory !== yCategory) {
			return xCategory - yCategory;
		}

		// If in same category, sort by position within category array
		return getModulePosition(x, modulesToMiddle, modulesToEnd) -
			getModulePosition(y, modulesToMiddle, modulesToEnd);
	});

	return sorted;
};

// Main function refactored
export const generateActivityModuleList = (
	mods: Mod[],
	lucInfo?: {
		start: BEModules | null;
		without: BEModules | null;
		with: BEModules | null;
	},
) => {
	const beModules = mods.map(el => el.beModuleId);
	const moduleMapping = createBeToUIDModuleMapping(mods);

	// Sort modules based on whether LUC is present
	const sortedModules = sortModules(beModules, lucInfo)

	// Map BE modules to FE modules
	const feMods = sortedModules.map(beMod => {
		const correctBeMod = mods.find(mod => mod.beModuleId === beMod);
		return {
			mod: BEModulesObj[beMod],
			id: lucInfo ? moduleMapping[beMod] : correctBeMod?.uniqueId,
			status: correctBeMod?.status,
		};
	});

	// Create final module list
	const moduleList = new Set<FeActivityModule>();
	for (const mod of feMods) {
		moduleList.add({
			id: mod.mod,
			name: FEModulesObj[mod.mod].name,
			path: FEModulesObj[mod.mod].path ?? "",
			uniqueId: mod.id,
		});
	}

	return Array.from(moduleList);
};

//in realtà questo sarà il nuovo ModuleStep
export type BE2FEModulesObj = {
	[key in BEModules]: FEModules;
};

export const BEModulesObj: BE2FEModulesObj = {
	[BEModules.AnnualCropland]: FEModules.AnnualCropland,
	[BEModules.SmallFisheries]: FEModules.SmallFisheries,
	[BEModules.LargeFisheries]: FEModules.LargeFisheries,
	[BEModules.PerennialCropland]: FEModules.PerennialCropland,
	[BEModules.LivestockManagement]: FEModules.LivestockManagement,
	[BEModules.Energy]: FEModules.Energy,
	[BEModules.Irrigation]: FEModules.Irrigation,
	[BEModules.IrrigationSystem]: FEModules.Irrigation,
	[BEModules.OperationPhaseOfIrrigation]: FEModules.Irrigation,
	[BEModules.FloodedRice]: FEModules.FloodedRice,
	[BEModules.ForestManagement]: FEModules.ForestManagement,
	[BEModules.LandUseChange]: FEModules.LandUseChange,
	[BEModules.Aquaculture]: FEModules.Aquaculture,
	[BEModules.OrganicSoil]: FEModules.OrganicSoil,
	[BEModules.CoastalWetlands]: FEModules.CoastalWetlands,
	[BEModules.Waterbodies]: FEModules.Waterbodies,
	[BEModules.SetAside]: FEModules.SetAside,
	[BEModules.OtherLand]: FEModules.OtherLand,
	[BEModules.Settlements]: FEModules.Settlements,
	[BEModules.Road]: FEModules.Settlements,
	[BEModules.Building]: FEModules.Settlements,
	[BEModules.OtherInfrastructure]: FEModules.Settlements,
	[BEModules.Grassland]: FEModules.Grassland,
	[BEModules.Inputs]: FEModules.Inputs,
	[BEModules.Packaging]: FEModules.Packaging,
	[BEModules.Transport]: FEModules.Transport,
	[BEModules.Storage]: FEModules.Storage,
	[BEModules.Processing]: FEModules.Processing,
};

export const activityCompleteInfo: FeActivityModule = {
	id: FEModules.ActivityComplete,
	name: FEModulesObj[FEModules.ActivityComplete].name,
	path: FEModulesObj[FEModules.ActivityComplete].path ?? "",
};

type FEModuleDescription = {
	[key in FEModules]: TranslationKey | "";
};
export const moduleDescriptions: FEModuleDescription = {
	[FEModules.PerennialCropland]: "activity.perennialcropland_description",
	[FEModules.AnnualCropland]: "activity.annualcropland_description",
	[FEModules.LivestockManagement]: "activity.livestockmanagement_description",
	[FEModules.Energy]: "activity.energy_description",
	[FEModules.Irrigation]: "activity.irrigation_description",
	[FEModules.FloodedRice]: "activity.floodedrice_description",
	[FEModules.ForestManagement]: "activity.forestmanagement_description",
	[FEModules.LandUseChange]: "activity.landusechange_description",
	[FEModules.LargeFisheries]: "activity.largefisheries_description",
	[FEModules.SmallFisheries]: "activity.smallfisheries_description",
	[FEModules.Aquaculture]: "activity.aquaculture_description",
	[FEModules.OrganicSoil]: "activity.organicsoil_description",
	[FEModules.CoastalWetlands]: "activity.coastalwetlands_description",
	[FEModules.Waterbodies]: "activity.waterbodies_description",
	[FEModules.SetAside]: "activity.setaside_description",
	[FEModules.OtherLand]: "activity.otherland_description",
	[FEModules.Settlements]: "activity.settlements_description",
	[FEModules.Grassland]: "activity.grassland_description",
	[FEModules.Inputs]: "activity.inputs_description",
	[FEModules.Packaging]: "activity.packaging_description",
	[FEModules.Transport]: "activity.transport_description",
	[FEModules.Storage]: "activity.storage_description",
	[FEModules.Processing]: "activity.processing_description",
	[FEModules.ActivityComplete]: ""
};

export const getFEModuleListFromActivity = (activity: Activity) => {
	const luc = activity.modules?.find(
		(mod) => mod.module_type?.id === BEModules.LandUseChange,
	) as LandUseChangeResponse | undefined;
	// const beModuleIds = activity.module_types.map((mod) => mod.id);
	const beMods: Mod[] = activity.modules?.map((mod) => {
		return {
			uniqueId: mod.id,
			beModuleId: mod.module_type.id,
			status: mod.status,
		};
	});

	const feModuleList = generateActivityModuleList(
		beMods,
		luc
			? {
				start: luc.module_type_start,
				with: luc.module_type_w,
				without: luc.module_type_wo,
			}
			: undefined
	);

	return feModuleList;
}

export function getModuleFromUrl(url: string) {
	const sections = url.split('/');
	const section = sections[sections.length - 1];
	return getModulefromModulePathName(section);
}
export function isLandUseModule(module: BEModules | undefined) {
	if (!module) return false;
	return MODULES_WITH_LAND_USE.includes(BEModulesObj[module]);
}
export function getModulefromModulePathName(modulePathName: string) {
	return Object.values(FEModulesObj).find((module) => module.path === modulePathName)
}

export function getModuleNameFromBEId(beModuleId: number) {
	const frontId = BEModulesObj[beModuleId as keyof typeof BEModulesObj]
	return FEModulesObj[frontId].name
}

export function getLandUseModuleIdFromActivity(modules: FeActivityModule[]): number | undefined {
	return modules.find((mod) => MODULES_WITH_LAND_USE.includes(mod.id))?.uniqueId;
}
export function getAreaFromLUCModule(activity: Activity | null | undefined, moduleList: FeActivityModule[]) {
	if (!activity) return null
	const landUseModuleInActivityId = getLandUseModuleIdFromActivity(moduleList)
	if (!landUseModuleInActivityId) return null
	const landUseModule = activity?.modules?.find((mod) => mod.id === landUseModuleInActivityId)
	return landUseModule?.area ?? null
}