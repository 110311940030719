import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetActivitiesByIdQuery } from "../../app/features/activities/activityApiSlice";
import { selectCurrentProject, setActivity, setProject } from "../../app/features/project/projectSlice";
import { getFEModuleListFromActivity } from "../../utils/moduleList";
import { useEffect, useMemo } from "react";
import { setModuleList } from "../../app/features/builder/builderSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export const useHandleActivityState = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activity, project } = useAppSelector(selectCurrentProject);
  const { activityId } = useParams<string>();
  const id = useMemo(() => Number(activityId), [activityId]);
  const isActivityIdMismatch = id !== activity?.id;

  const { data: activityData, isLoading, isFetching, isError, error } = useGetActivitiesByIdQuery(
    Number(activityId) ?? 0,
    { skip: activityId === activity?.id },
  );

  useEffect(() => {
    if (id && activityData && activityData.id === id) {
      const feModuleList = getFEModuleListFromActivity(activityData);
      dispatch(setActivity(activityData));
      dispatch(setModuleList(feModuleList));

      const splitPath = pathname.split("/").filter(path => path !== "")
      if (splitPath.length <= 2) {
        navigate(
          feModuleList.length > 0
            ? `${feModuleList[0].path}`
            : `complete`,
          { replace: true },
        );
      }
    }
  }, [activity, activityData, id, dispatch, pathname]);


  useEffect(() => {
    if (activityData?.project && project?.id !== activityData.project.id) {
      dispatch(setProject(activityData.project));
    }
  }, [project, dispatch, activityData]);

  return { activity, isLoading: isLoading || isActivityIdMismatch, isFetching, isError, error: error as FetchBaseQueryError };
}