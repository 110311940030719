import { useMemo } from "react";
import {
	useGetProjectByIdQuery,
	useGetProjectResultsByGasQuery,
	useGetProjectResultsQuery,
} from "../../../app/features/project/projectApiSlice";
import { aggregateTotalProjectEmissions, calculateProjectCO2Balance } from "./resultsUtils";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ResultSlider from "../../../components/resultSlider/ResultSlider";
import DescriptionBlock from "./DescriptionBlock";
import BalanceByStatus from "../../../components/graphs/BalanceByStatus";
import CarbBalanceByYear from "../../../components/graphs/CarbBalanceByYear";
import CO2ByActivityHorizontal from "../../../components/graphs/CO2ByActivityHorizontal";
import useErrorMessage from "../../../utils/useErrorMessage";
import BalanceCostRatio from "../../../components/graphs/BalanceCostRatio";
import TranslatableText from "../../../components/translations/TranslatableText";
import { translate } from "../../../utils/helperFunctions";
import { ResultSliderItem } from "../../../types/modulesInterfaces";
import { useCustomTranslation } from "../../../app/hooks";
import i18n from "../../../i18n";
import DownloadExcelButton from "./ProjectResultsReport";

const ProjectResultsContainer = () => {
	let { projectId } = useParams<string>();
	const {
		data: project,
		isError,
		error,
		isLoading
	} = useGetProjectByIdQuery(projectId || "", {
		refetchOnMountOrArgChange: true,
	});

	const location = useLocation();
	const isProjecIdValid = useMemo<boolean>(
		() => projectId !== undefined && projectId !== "new",
		[projectId],
	);

	const { errorMsg } = useErrorMessage({ error, isError });

	return errorMsg ? (
		<section>
			<div className="module-group">
				<span className="error fs-13 pb-2">
					{errorMsg
						? errorMsg
						: `An error occurred while fetching for project ${projectId}`}
				</span>
			</div>
		</section>
	) : isProjecIdValid ? (
		<section className="ov-auto">
			<div className="module-group" style={{ display: "flex" }}>
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey="project.project_results" />
					</h2>
					<div className="module-number header-number ff-light-ext">3.0</div>
				</div>
			</div>

			<div className="d-flex f-column pb-2" style={{ marginLeft: "90px" }}>
				<div className="d-flex pb-2 fs-13 lh-1 project-results-dc description-container">
					<DescriptionBlock
						country={project?.country?.name}
						climate={project?.climate?.name}
						moisture={project?.moisture?.name}
						soilType={project?.soil_type?.name}
						implDuration={project?.implementation_years}
						capDuration={project?.capitalization_years}
						isLoading={isLoading}
						totalHectares={project?.total_hectares}
						totalCatch={project?.total_catch?.w}
						totalLivestock={project?.total_livestock?.w}
					/>
				</div>

				<DownloadExcelButton />

				<TotalCO2Generated projectId={project?.id ?? 0} />
				<CO2ByActivityHorizontal
					projectId={project?.id ?? 0}
					height={100}
					className="mb-3"
				/>
				<BalanceByStatus
					projectId={project?.id ?? 0}
					height={350}
					className="my-3 pe-2"
				/>
				<CarbBalanceByYear
					projectId={project?.id ?? 0}
					height={350}
					className="my-3 pe-2"
				/>
				<CarbonBalance projectId={project?.id ?? 0} />
				<BalanceCostRatio projectId={project?.id ?? 0} />
				{/* 
    <PrintAndShare /> */}
			</div>
		</section>
	) : (
		<Navigate
			to={`/project/new/description`}
			state={{ from: location }}
			replace
		/>
	);
};

const TotalCO2Generated = ({ projectId }: { projectId: number }) => {
	const { data, isLoading } = useGetProjectResultsQuery(projectId, {
		refetchOnMountOrArgChange: true,
		selectFromResult: (result) => ({
			...result,
			data: calculateProjectCO2Balance(result.data),
		}),
	});

	const sliders = useMemo(() => {
		return [
			{
				name: "Balance",
				value: data?.balance ?? 0,
			},
			{
				name: "With",
				value: data?.total_w ?? 0,
			},
			{
				name: "Without",
				value: data?.total_wo ?? 0,
			},
		];
	}, [data]);

	return (
		<>
			<div
				className="mb-2 w-fit"
				style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
			>
				<h2 className="ff-bold fs-13 text-black">
					<TranslatableText translationKey="main.total_co2_eq_generated" />
				</h2>
			</div>
			<div className="co2-blocks-grid">
				{isLoading
					? sliders.map((slider) => (
						<Skeleton
							height={80}
							borderRadius={8}
							key={slider.name}
							style={{
								gridColumn: slider.name === "Balance" ? "span 2" : "span 1",
							}}
						/>
					))
					: sliders.map((slider) => (
						<div
							className="d-flex f-column"
							key={slider.name}
							style={{
								gridColumn: slider.name === "Balance" ? "span 2" : "span 1",
							}}
						>
							<span className="text-black fs-13">{slider.name}</span>
							<ResultSlider value={slider.value} />
						</div>
					))}
			</div>
		</>
	);
}

const CarbonBalance = ({ projectId }: { projectId: number }) => {
	const { data: customRes, isLoading } = useGetProjectResultsByGasQuery(
		projectId,
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: (result) => ({
				...result,
				data: aggregateTotalProjectEmissions(result.data),
			}),
		},
	);
	const customLabel = useCustomTranslation("module.OTHER_GHG_fluxes")
	const { language } = i18n

	const sliders = useMemo((): ResultSliderItem[] => {
		return [
			{ name: `CO<sub>2</sub> ${translate("module.fluxes")}`, value: customRes?.CO2 ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
			{ name: `CH<sub>4</sub> ${translate("module.fluxes")}`, value: customRes?.CH4 ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
			{ name: `N<sub>2</sub>O ${translate("module.fluxes")}`, value: customRes?.N2O ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
			{ name: `${translate("module.OTHER_GHG_fluxes")}`, value: customRes?.OTHER ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
		];
	}, [customRes, language])
	return (
		<>
			<div
				className="mt-2 mb-3 w-fit"
				style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
			>
				<h2 className="ff-bold fs-13 text-black">
					<TranslatableText translationKey="main.carbon_balance" />
				</h2>
			</div>
			<div className="co2-blocks-grid pb-4">
				{isLoading
					? sliders.map((slider) => (
						<Skeleton height={80} borderRadius={8} key={slider.name} />
					))
					: sliders.map((slider) => (
						<div
							className="d-flex f-column text-black mb-2"
							key={slider.name}
						>
							<span className="text-black fs-13 ff-bold lh-90" dangerouslySetInnerHTML={{ __html: slider.name }} />
							<TranslatableText className="text-black fs-12 ff-light" translationKey={slider.unit} />
							<ResultSlider value={slider.value} />
						</div>
					))}
			</div>
		</>
	);
};

const PrintAndShare = () => {
	return <>PrintAndShare</>;
};

export default ProjectResultsContainer;
