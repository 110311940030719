import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TranslationKey } from '../../types/modulesInterfaces';

interface TranslationParams {
    [key: string]: string
}

const TranslatableText = ({ 
    translationKey,
    translationParams,
    style,
    className,
    paramsComponents
 }: { 
    translationKey: TranslationKey,
    style?: React.CSSProperties,
    className?: string
    translationParams?: TranslationParams | {},
    paramsComponents?: React.ReactNode[]
}) => {
    const { t } = useTranslation();
    return (
        <span style={style} className={className}>
            <Trans i18nKey={translationKey as any} values={translationParams} components={paramsComponents ?? [<></>] as any} />
        </span>
    )
}

export default TranslatableText
