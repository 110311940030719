import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";
import {
	initialValues,
	validationSchema,
	getSchemas,
	t2InitialValues,
} from "./waterbodies";
import {
	useAppSelector,
	useSyncNotesWithStore,
	useThreadsProcessor,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/beModules";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetWaterbodiesDefaultsQuery,
	useGetWaterbodiesQuery,
	useUpdateWaterbodiesMutation,
} from "../../../../app/features/api/modules/waterbodiesApiSlice";
import type { ModuleFull } from "./waterbodiesTypes";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";

const Waterbodies = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: waterbodiesData,
		isLoading,
		isError,
		error,
	} = useGetWaterbodiesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateWaterbodies,
		{ isLoading: isLoadingUpdate, isError: isUpdateError, error: updateError },
	] = useUpdateWaterbodiesMutation();

	const { data: defaults, isError: isDefaultsError, error: defaultsError, isLoading: isLoadingDefaults } = useGetWaterbodiesDefaultsQuery(
		waterbodiesData?.module.id ?? 0,
		{ skip: !waterbodiesData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });
	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();
	useEffect(() => {
		if (waterbodiesData) {
			setInitValues({
				module: waterbodiesData.module,
				tiertwo: { ...waterbodiesData.tiertwo, ...defaults },
			});
			processModuleThreads({
				module: waterbodiesData.module,
			})
		}
	}, [waterbodiesData, defaults,processModuleThreads]);

	const { notes } = useSyncNotesWithStore({
		notes: waterbodiesData?.module.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		console.log(values);
		const module = { ...values.module };
		const payload = {
			data: { ...values, module },
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updateWaterbodies(payload).unwrap());
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			initialValues={initValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.waterbodies"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					beModuleType={BEModules.Waterbodies}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="project.waterbodies"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default Waterbodies;
