import { FC } from "react";
import { FieldProps, useFormikContext } from "formik";

import toggleOff from "../../assets/icons/switch_false.svg";
import toggleOn from "../../assets/icons/switch_true.svg";
import Button from "../button/Button";
import TranslatableText from "../translations/TranslatableText";
import { TranslationKey } from "../../types/modulesInterfaces";

interface CustomToggleProps {
	label: TranslationKey;
	id: string;
	value: boolean;
	classes?: string;
	handleClick?: () => void;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => void;
	autosubmit?: boolean;
	disabled?: boolean;
  labelSuffixValue?: string;
}

const CustomToggle: FC<CustomToggleProps & FieldProps> = ({
	label,
	id,
	field,
	setFieldValue,
	disabled,
	form: { touched },
	labelSuffixValue,
}) => {
	const { setTouched } = useFormikContext();
	const handleClick = (e: React.MouseEvent) => {
		if (disabled) return;
		const newValue = !field.value;
		setFieldValue(id, newValue);
		setTouched({ ...touched, [field.name]: true }, false);
	};

	return (
		<section className="h-100 d-flex align-items-center">
			{label?.length > 1 ? <TranslatableText className="toggle-label" translationKey={label} /> : null}
			{labelSuffixValue ? ` - ${labelSuffixValue}` : ""}
			<TranslatableText
				className={`fs-12 ${!field.value && "ff-bold"} px-1 ${disabled && "op-5"}`}
				translationKey="main.no"
			/>
			<Button classes="pos-relative" onClick={handleClick} disabled={disabled}>
				<input
					type="checkbox"
					checked={field.value ?? false}
					className="hidden-plus h-100 w-100"
					aria-hidden="true"
					{...field}
				/>
				<img
					src={field.value === true ? toggleOn : toggleOff}
					alt="toggle"
					className={disabled ? "op-5" : ""}
				/>
			</Button>
			<TranslatableText
				className={`fs-12 ${field.value && "ff-bold"} ps-1 ${disabled && "op-5"}`}
				translationKey="main.yes"
			/>
		</section>
	);
};

export default CustomToggle;
