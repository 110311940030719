import { useMemo, useEffect, useState } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, useSyncNotesWithStore, useThreadsProcessor } from "../../../../app/hooks";
import { Formik, FormikHelpers } from "formik";
import { BEModules } from "../../../../utils/beModules";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";

import { ModuleFull } from "./transportTypes";
import { useGetTransportsQuery, useUpdateTransportsMutation, useUpdateTransportEntryMutation, useGetTransportEntriesQuery } from "../../../../app/features/api/modules/transportApiSlice";
import { getSchemas, validationSchema, initialValues } from "./transport";
import TransportTierOne from "./TransportTierOne";
import GenericOptionalTierTwo from "../GenericOptionalTierTwo";
import TransportEntryT2 from "./TransportEntryT2";


const TransportModule = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { data: transportsData, isLoading, isError, error } = useGetTransportsQuery({ activityId: activeActivityId ?? 0 });
  const { data: entriesData, isLoading: isEntriesLoading, isError: isEntriesError, error: entriesError } = useGetTransportEntriesQuery({ activityId: activeActivityId ?? 0 });
  const [updateTransports, { isError: isUpdateError, error: updateError }] = useUpdateTransportsMutation();
  const [updateTransportEntry, { isError: isUpdateTransportEntryError, error: updateTransportEntryError, isLoading: isUpdateTransportEntryLoading }] = useUpdateTransportEntryMutation();

  const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
  const [initValues, setInitValues] = useState<ModuleFull>(initialValues);
  const { errorMsg: updateParentErrorMsg } = useModuleHook({
    skeletonsLoadingDeps: [isLoading, isEntriesLoading],
    isMutateError: isUpdateError,
    mutateError: updateError,
  });

  const { errorMsg: fetchError } = useErrorMessage({ isError: isEntriesError ?? isError, error: entriesError ?? error });
  const { errorMsg: updateEntryErrorMsg } = useErrorMessage({ isError: isUpdateTransportEntryError, error: updateTransportEntryError });
  const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

  useEffect(() => {
    if (!transportsData) return
    setInitValues((cur) => ({ ...cur, parent: transportsData }))
  }, [transportsData])

  useEffect(() => {
    if (entriesData)
      processModuleThreads({ transports: entriesData })
  }, [processModuleThreads, entriesData])

  const { notes } = useSyncNotesWithStore({
    notes: transportsData?.note ?? null,
  })

  const handleSubmit = async (values: ModuleFull, { resetForm }: FormikHelpers<ModuleFull>) => {
    console.log(values)
    const { transports, parent } = values
    try {
      const promises = []
      const parentPayload = { body: { ...parent }, activityId: activeActivityId ?? 0 }
      if (parent.id) promises.push(updateTransports(parentPayload))

      transports.map((entry) => {
        const payload = { body: entry, activityId: activeActivityId ?? 0 }
        if (entry.id) promises.push(updateTransportEntry(payload))
        return Promise.resolve()
      })

      await Promise.all(promises);
      resetForm({ values })
    } catch (error) {
      console.error(error)
    }
    resetForm({ values })
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <TransportTierOne title="project.transport" initValues={initValues} t2Schema={t2Schema} beModuleType={BEModules.Transport} moduleSchema={moduleSchema} isLoading={isLoading || isEntriesLoading || isUpdateTransportEntryLoading} note={notes?.content ?? null} footerError={updateParentErrorMsg ?? updateEntryErrorMsg} blockingError={fetchError} isEntriesLoading={isEntriesLoading} setInitValues={setInitValues} />
        <GenericOptionalTierTwo
          setInitValues={setInitValues}
          titleKey="project.transport"
          listInputName="transports"
          EntryComponent={TransportEntryT2}
        />
      </>
    </Formik>
  );
};

export default TransportModule;
