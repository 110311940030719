import type { FormikHelpers } from "formik";
import type { ModuleFull } from "./grasslandTypes";
import { Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
	initialValues,
	t2InitialValues,
	validationSchema,
	getSchemas,
} from "./grasslandManagement";
import { useAppSelector, useThreadsProcessor, useSyncNotesWithStore } from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetGrasslandsDefaultsQuery,
	useGetGrasslandsQuery,
	useUpdateGrasslandsMutation,
} from "../../../../app/features/api/modules/grasslandApiSlice";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";
import useModuleHook from "../useModuleHook";
import { BEModules } from "../../../../utils/beModules";
import useErrorMessage from "../../../../utils/useErrorMessage";
import ModuleContext from "../../ModuleContext";

const GrasslandManagementModule = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { belongsToLuc } = useContext(ModuleContext);

	const {
		data: grasslandData,
		isLoading,
		isError,
		error,
	} = useGetGrasslandsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateGrassland,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateGrasslandsMutation();

	const { data: defaults, error: defaultsError, isError: isDefaultsError, isLoading: isLoadingDefaults } = useGetGrasslandsDefaultsQuery(grasslandData?.module.id ?? 0, { skip: !grasslandData?.module.id });

	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });
	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

	useEffect(() => {
		if (grasslandData) {
			setInitValues({ module: grasslandData.module, tiertwo: { ...grasslandData.tiertwo, ...defaults }, });
			processModuleThreads({
				module: grasslandData.module,
			})
		}
	}, [grasslandData, defaults, processModuleThreads]);

	const { notes } = useSyncNotesWithStore({ notes: grasslandData?.module.note ?? null });
 
	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module, notes };
		const payload = { data: { ...values, module }, activityId: activeActivityId ?? 0 };

		try {
			values.module.id && await updateGrassland(payload).unwrap();
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema(belongsToLuc)}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.grassland_management"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					beModuleType={BEModules.Grassland}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
					/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="project.grassland_management"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg ?? null}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default GrasslandManagementModule;
