import { AnimatePresence, motion } from "framer-motion";

interface FadeAnimationProps {
  children: React.ReactNode;
  duration?: number;
  ease?: string;
  isShown?: boolean;
  className?: string;
  delay?: number;
}

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const FadeAnimation = ({ children, duration = 1, ease = "easeOut", isShown = false, className, delay = 0 }: FadeAnimationProps) => {
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{
            enter: { duration: duration },
            exit: { duration: duration / 1.5 },
            ease: ease,
            delay: delay
          }}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FadeAnimation;