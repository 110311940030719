import type {
	HectaresInputGroup,
	InputGroup,
	ModuleSchema,
	T2Schema,
	StartWithWithout,
	StartWithWithoutGeneric,
	Option,
} from "../../../../types/modulesInterfaces";
import type { LandUseChangeResponse } from "./landUseChangeTypes";
import { FieldType } from "../../../../types/modulesInterfaces";
import { BEModules } from "../../../../utils/beModules";
import * as Yup from "yup";

export const includedPropList = [
	"id",
	"start_year",
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"area",
	"note",
	"is_fire_used_start",
	"is_fire_used_w",
	"is_fire_used_wo",
	"dry_matter_w",
	"dry_matter_wo",
	"module_type_start",
	"module_type_w",
	"module_type_wo",
];

export interface Module {
	hectares: number | undefined;
	typeOfLandUse: StartWithWithout;
	fireUse: {
		start: string;
		with: boolean;
		without: boolean;
	};
	dryMatterRemoved: StartWithWithout;
}

export const validationSchema = Yup.object({});
export const initialValues: LandUseChangeResponse = {
	id: undefined,
	area: null,
	module_type: null,
	note: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	is_fire_used_w: false,
	is_fire_used_wo: false,
	dry_matter_w: null,
	dry_matter_wo: null,
	module_type_start: null,
	module_type_w: null,
	module_type_wo: null,
	activity: null,
	created_at: null,
	updated_at: null,
	is_fire_used_start: false,
	dry_matter_start: null,
	status: null,
};

export const mainActivityLandUseOptions: Option[] = [
	{ id: BEModules.ForestManagement, name: "Forest management" },
	{ id: BEModules.FloodedRice, name: "Flooded rice management" },
	{ id: BEModules.PerennialCropland, name: "Perennial cropland management" },
	{ id: BEModules.AnnualCropland, name: "Annual cropland management" },
	{ id: BEModules.CoastalWetlands, name: "Coastal wetland management" },
	{ id: BEModules.Settlements, name: "Infrastructure creation" },
	{ id: BEModules.SetAside, name: "Set land aside" },
	{ id: BEModules.LivestockManagement, name: "Livestock management" },
	{ id: BEModules.Waterbodies, name: "Water body management" },
	{ id: BEModules.LargeFisheries, name: "Large fisheries management" },
	{ id: BEModules.SmallFisheries, name: "Small fisheries management" },
	{ id: BEModules.Aquaculture, name: "Aquaculture" },
	{ id: BEModules.Grassland, name: "Grassland" },
	{
		id: BEModules.Energy,
		name: "Use of agricultural inputs, energy or irrigation",
	},
];

export const landUseOptions: Option[] = [
	{ id: BEModules.ForestManagement, name: "Forest" },
	{ id: BEModules.FloodedRice, name: "Flooded rice" },
	{ id: BEModules.PerennialCropland, name: "Perennial cropland" },
	{ id: BEModules.AnnualCropland, name: "Annual cropland" },
	{ id: BEModules.CoastalWetlands, name: "Coastal Wetland" },
	{ id: BEModules.Settlements, name: "Settlements and infrastructure" },
	{ id: BEModules.SetAside, name: "Set aside land" },
	{ id: BEModules.Grassland, name: "Grassland" },
	{ id: BEModules.OtherLand, name: "Other land" },
];

const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "module.area",
		disabled: true,
	};

	const landUse: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		inputName: "module.module_type",
		label: "module.type_of_land_use",
		startProps: { dropdownOptions: landUseOptions, disabled: true },
		withProps: { dropdownOptions: [...mainActivityLandUseOptions, { id: BEModules.OtherLand, name: "Other land" }], disabled: true },
		withoutProps: { dropdownOptions: landUseOptions, disabled: true },
	};

	const fireUse: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "module.is_fire_used",
		label: "module.fire_use_during_conversion",
		startProps: {
			inputType: "free",
			disabled: true,
		},
		withProps: {
			inputType: "boolean",
		},
		withoutProps: {
			inputType: "boolean",
		},
	};

	const dryMatterRemoved: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.dry_matter_removed_during_conversion",
		unit: "[t/ha]",
		inputName: "module.dry_matter",
		startProps: { disabled: true },
	};

	return [hectaresInputGroup, landUse, fireUse, dryMatterRemoved];
};

/* T2 */
export const t2InitialValues = {};
export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: [],
		optionalSubmodules: [],
	};
	const t2Schema: T2Schema = {
		submodules: [],
	};

	return { moduleSchema, t2Schema };
};
