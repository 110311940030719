import { useMemo, useEffect, useState } from "react";
import { usePermissions } from "../../../../app/hooks";
import { EntryComponentProps } from "../GenericOptionalTierTwo";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import { TierTwoBlock } from "../GenericTierTwo";
import { useGetStorageEntriesDefaultsQuery } from "../../../../app/features/api/modules/storageApiSlice";
import { StorageEntryData, } from "./storageTypes";
import { getSchemas } from "./storage";
import { getIn, useFormikContext } from "formik";
import useErrorMessage from "../../../../utils/useErrorMessage";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import FadeAnimation from "../../../../components/animations/FadeAnimation";

interface StorageEntryT2Props extends EntryComponentProps<StorageEntryData> { }

const StorageEntryT2 = ({ entry, index, onDefaultsError }: StorageEntryT2Props) => {
  const { isReadOnly } = usePermissions();
  const { t2EntrySchema } = useMemo(() => getSchemas(), []);
  const [showConditionalSection, setShowConditionalSection] = useState(false)

  const { data: defaults, isError, error } = useGetStorageEntriesDefaultsQuery(entry.id, { skip: !entry.id });
  const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });
  useEffect(() => {
    if (defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);
  }, [defaultsErrorMsg, onDefaultsError])

  //merge defaults with entry
  const { setFieldValue } = useFormikContext<{ storages: StorageEntryData[] }>();
  useEffect(() => {
    const entryWithDefaults = defaults ? { ...entry, ...defaults } : null
    if (entryWithDefaults && index !== undefined)
      setFieldValue(`storages.${index}`, entryWithDefaults);
  }, [defaults, setFieldValue, index]);


  useEffect(() => {
    if (t2EntrySchema.conditionalSection && t2EntrySchema.conditionalSection.conditionName) {
      setShowConditionalSection(getIn(entry, t2EntrySchema.conditionalSection.conditionName))
    }
  }, [entry, t2EntrySchema.conditionalSection])

  return (
    <TranslatedFormSubmodule
      key={entry.id}
      submoduleName={t2EntrySchema.name}
      itemId={entry.id}
      itemIndex={index + 1}
      className="pt-2"
    >
      <TierTwoBlock>
        {t2EntrySchema.inputGroups.map((input) => (
          <GenericInputGroup key={`${input.label}-${entry.id}`} inputGroup={{ ...input, disabled: isReadOnly, index }} />
        ))}

        <FadeAnimation isShown={showConditionalSection}>
          <>
            {t2EntrySchema.conditionalSection?.inputGroups.map((input) => (
              <GenericInputGroup key={`${input.label}-${entry.id}`} inputGroup={{ ...input, disabled: isReadOnly, index }} />
            ))}
          </>
        </FadeAnimation>
      </TierTwoBlock>
    </TranslatedFormSubmodule>
  )
}

export default StorageEntryT2;