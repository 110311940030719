import type { IconType } from "react-icons";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { SerializedError } from "@reduxjs/toolkit";
import type { BEModules } from "../utils/beModules";
import type { Option } from "./modulesInterfaces";

//generally we want to define interfaces in the component so that's private unless we reuse it
interface ProjectOption {
	name: string;
	value: string;
	id: number;
}
export enum Role {
	ADMIN = "Admin",
	SECOND_REVIEWER = "Second Reviewer",
	ANALYST = "Analyst",
	RECEIVER = "Viewer",
}

interface Project {
	id: number;
	climate: Option;
	country: {
		id: number;
		name: string;
		region?: Option;
		ipcc_region?: Option;
		gleam_region?: Option;
	};
	created_at: string;
	updated_at: string;
	moisture: Option;
	soil_type: Option;
	gw_potential?: {
		id: number;
		name: string;
		co2: number;
		ch4: number;
		n2o: number;
	};
	status?: {
		id: number;
		name: string;
		value: number;
	};
	date: Date;
	name: string;
	code: string;
	cost: number;
	funding_agency: string | null;
	executing_agency: string | null;
	implementation_years: number;
	capitalization_years: number;
	start_year_of_activities: number;
	last_year_of_accounting: number;
	soc_ref_t2: number | null;
	owner: User,
	area: ProjectArea | null;
	role: Role[];
	total_hectares: number | null;
	total_catch: {
		start: number | null;
		w: number | null;
		wo: number | null;
	} | null;
	total_livestock: {
		start: number | null;
		w: number | null;
		wo: number | null;
	} | null;
	/* sent by backend but not used in FE
	isLocked: boolean,
	lockedAt: string,
	lockUpdatedAt: string,
	lockedBy: number,
	*/
}

interface FastProject {
	id: number;
	name: string;
	country: string; //Option[]
	updated_at: string;
	owner: number, //User
	role: string[], //Role[]
}

interface LandUseType {
	id: number
	module_types: ModuleType[]
	name: string
	is_active: boolean
	climates: number[]
	moistures: number[]
}

type ProjectArea = L.LatLngLiteral[]

interface User {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	country: number;
	organization?: string
}

interface Activity {
	id: number;
	name: string;
	project?: Project;
	change_rate: Option | null;
	status: Status | null;
	climate_t2?: Option | null;
	moisture_t2?: Option | null;
	soil_type_t2?: Option | null;
	capitalization_years?: number | null;
	module_types: ModuleType[];
	owner: User;
	description: string | null;
	completion_percentage: number | null;
	cost: number | null;
	duration_t2?: number | null;
	start_year_t2?: number | null;
	created_at: string;
	updated_at: string;
	modules: Module[];
}

interface FastActivity {
	id: number;
	name: string;
	module_types: number[] //ModuleType[];
	completion_percentage: number;
}

interface Module {
	id: number;
	module_type: ModuleType;
	activity: Activity,
	status: Status;
	notes: string;
	start_year: number;
	created_at: string;
	updated_at: string;
	area?: number;
}

interface ModuleType {
	id: BEModules;
	name: string;
	is_luc: boolean;
	is_submodule: boolean;
	is_fixed_assessment: boolean;
}

interface Status {
	id: number;
	value: number;
	name: string;
	name_en: "READY" | "EMPTY" | "IN PROGRESS"
}

interface TopbarStep {
	id: number; //FEModules
	name: string; //ex label
	path: string;
}

export enum ProjectNavigation {
	Description = "main.description",
	Activities = "activity.activities",
	Results = "main.results",
}

interface BuilderAction {
	id: Actions;
	label: string;
	icon: IconType;
	color: string;
	component: () => JSX.Element | null;
}

interface TimeOptions {
	start?: number | null;
	without: number | null;
	with: number | null;
}

interface ModuleInfo {
	name: string;
	area?: TimeOptions;
	totalEmission: TimeOptions;
}

interface BackendError {
	status: number;
	data: { detail: string };
}

export enum Actions {
	LiveUpdates = 0,
	Notes = 1,
	Comments = 2,
	Help = 3,
	Changes = 4,
	ProgressiveModules = 5,
	Map = 6,
	Documentation = 7,
	Definitions = 9
}

type ErrorType = FetchBaseQueryError | SerializedError | undefined;
type ErrorFormat = {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	[key: string]: any;
	details: string[];
};

export interface GasType {
	name: "CO2" | "CH4" | "N2O";
}
interface BasePaginationResponse {
	count: number
	next: string
	previous: string
}

export type {
	LandUseType,
	ProjectOption,
	Project,
	TopbarStep,
	BuilderAction,
	ModuleInfo,
	BackendError,
	Activity,
	User,
	ErrorType,
	ModuleType,
	Status as ModuleStatus,
	ErrorFormat,
	Module,
	ProjectArea,
	BasePaginationResponse,
	FastProject,
	FastActivity
};
