import type { Activity } from "../../types/interfaces";
import { Actions } from "../../types/interfaces";
import type {
	ClassicInputGroup,
	DropdownInputGroup,
	InputGroup,
} from "../../types/modulesInterfaces";
import { FieldType } from "../../types/modulesInterfaces";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import { getOptions } from "../../app/features/dropdownOptions/fetchOptions";
import { Form, Formik } from "formik";
import { useGetModulesStatuesQuery } from "../../app/features/activities/activityApiSlice";
import { useHandleActivityState } from "./useHandleActivityState";
import { useEffect, useMemo } from "react";
import { selectCurrentBuilder, setActiveAction, toggleTabsOpen } from "../../app/features/builder/builderSlice";
import { BiCheck } from "react-icons/bi";
import { FeActivityModule } from "../../utils/moduleList";
import ModuleButton from "../../components/button/ModuleButton";
import GenericInputGroup from "../../components/input/inputGroup/GenericInputGroup";
import ModuleCard from "../../components/actions/modules/ModuleCard";
import Button from "../../components/button/Button";

const activityTitle: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "activityTitle",
	inputType: "text",
	label: "activity.activity_title",
};
const duration: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "duration",
	inputType: "number",
	label: "activity.duration",
	unit: "[years]",
};
const startYear: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "startYear",
	inputType: "number",
	label: "activity.start_year",
	unit: "[years]",
};
const climate: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "climate",
	label: "activity.climate",
	dropdownOptions: () => getOptions("climate"),
};
const moisture: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "moisture",
	label: "activity.moisture",
	dropdownOptions: () => getOptions("moisture"),
};
const typeOfSoil: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "soilType",
	label: "activity.soil_type",
	dropdownOptions: () => getOptions("soil"),
};
const activityCost: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "activityCost",
	inputType: "number",
	label: "activity.activity_cost",
};

const inputGroups: InputGroup[] = [
	activityTitle,
	duration,
	startYear,
	climate,
	moisture,
	typeOfSoil,
	activityCost,
];

const ActivityCompleteStep = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { project } = useAppSelector(selectCurrentProject);
	const { activity } = useHandleActivityState();

	useEffect(() => {
		dispatch(setActiveAction(Actions.LiveUpdates))
		dispatch(toggleTabsOpen(true))
	}, [])

	const handleAddActivityClick = () => {
		navigate(`/project/${project?.id}/activities`);
	};
	const handleResultsClick = () => {
		navigate(`/project/${project?.id}/results`);
	};

	return (
		<section>
			<div className="module-group">
				<div className="pos-relative mb-1 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						{activity?.name}
					</h2>
					<div className="module-number header-number ff-light-ext">3.0</div>
				</div>
				<ActivityInfoRecap />
				<ModuleList />
			</div>
			<footer style={{ paddingRight: 40 }}>
				<div
					className="module-group-footer"
					style={{ borderTop: "8px solid rgba(50, 41, 37, 0.25)" }}
				>
					<div className="module-group">
						<div className="module-number header-number ff-light-ext">3.0</div>
						<span className="ff-light fs-12 pb-2">
							Activity is now completed.
						</span>
						<div className="py-1">
							<ModuleButton handleClick={handleAddActivityClick} labelKey="main.add_another_activity" />
						</div>
						<div className="py-1">
							<ModuleButton handleClick={handleResultsClick} labelKey="main.view_project_results" />
						</div>
					</div>
				</div>
			</footer>
		</section>
	);
};

const ActivityInfoRecap = () => {
	const { project } = useAppSelector(selectCurrentProject);
	const { activity } = useHandleActivityState();

	const initValues = useMemo(
		() => {
			const duration = activity?.duration_t2 ?? project?.implementation_years
			const startYear = activity?.start_year_t2 ?? project?.start_year_of_activities
			const moisture = activity?.moisture_t2?.id ?? project?.moisture?.id
			const soilType = activity?.soil_type_t2?.id ?? project?.soil_type?.id
			const climate = activity?.climate_t2?.id ?? project?.climate?.id

			return {
				activityTitle: activity?.name,
				duration,
				climate,
				moisture,
				soilType,
				startYear,
				activityCost: activity?.cost,
			}
		},
		[activity],
	);

	return (
		<div className="d-flex f-column">
			<Formik enableReinitialize initialValues={initValues} onSubmit={() => { }}>
				<Form>
					{inputGroups.map((input, index) => (
						<div className="py-1" key={index}>
							<GenericInputGroup
								inputGroup={{ ...input, disabled: true }}
							/>
						</div>
					))}
				</Form>
			</Formik>
		</div>
	);
};

const ModuleList = () => {
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { data: moduleStatuses } = useGetModulesStatuesQuery(activeActivityId ?? 0, { skip: !activeActivityId });
	if (!moduleList.length) return null;
	return (
		<div className="pt-2 w-50">
			{moduleList.map((module, index) => (
				<ModuleBox key={module.uniqueId} module={module} index={index} isReady={moduleStatuses?.find(({ id }) => id === module.uniqueId)?.status.name_en === "READY"} />
			))}
		</div>
	);
};

const ModuleBox = ({ module, index, isReady }: { module: FeActivityModule, index: number, isReady: boolean }) => {
	return (
		<div className="pos-relative mb-2" key={index}>
			<ModuleCard module={module.id} />
			<div
				className="pos-absolute d-flex align-items-center justify-content-center"
				style={{
					top: "50%",
					bottom: "50%",
					transform: "translate(-50%, -50%)",
					left: -24,
				}}
			>
				<Button classes="step-number" style={{ borderColor: isReady ? "#52A021" : "inherit" }}>
					<BiCheck size={20} color={isReady ? "#52A021" : "inherit"} />
				</Button>
			</div>
		</div>
	)
}

export default ActivityCompleteStep;
