import { useCallback, useEffect, useMemo, useState } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { FieldArray, Form, useFormikContext } from "formik";
import type {
	FloodedMinorSeasonCreatePayload,
	ModuleFull,
} from "./floodedRiceTypes";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import {
	BEModulesObj,
	FEModules,
	moduleDescriptions,
} from "../../../../utils/moduleList";
import type {
	BelongsToLuc,
	ModuleProps,
	ModuleSchema,
	Submodule,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import { NoteButton } from "../../../../components/tabsButtons/TabsButtons";
import {
	useCreateFloodedRiceMinorSeasonMutation,
	useDeleteFloodedRiceMinorSeasonMutation,
	useGetFloodedRiceMinorSeasonsQuery,
} from "../../../../app/features/api/modules/floodedRiceApiSlice";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import { TranslatedAddAccordionButton } from "../../../../components/input/AddAccordionButton";
import Spinner from "../../../../components/spinner/Spinner";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { mergeLocalAndServerChanges, preventFormSubmit } from "../inputs/utils";
import { useGetLandUseChangesQuery } from "../../../../app/features/api/modules/landUseChangeApiSlice";
import { handleDisableSww } from "../moduleUtils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import TranslatableText from "../../../../components/translations/TranslatableText";
import ModuleFooter from "../ModuleFooter";

interface FloodedRicesProps {
	seasonSchema: Submodule;
	parentSchema: ModuleSchema;
	isSeasonsLoading?: boolean;
	belongsToLuc?: BelongsToLuc;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}

const isSWW = (inputType: FieldType) =>
	[FieldType.SWW, FieldType.SWW_BOOL, FieldType.SWW_SELECT].includes(inputType);

const FloodedRiceTierOne = ({
	title,
	seasonSchema,
	parentSchema,
	note,
	isSeasonsLoading,
	footerError,
	blockingError,
	beModuleType,
	isError,
	belongsToLuc,
	setInitValues,
}: Omit<ModuleProps, "moduleSchema" | "t2Schema"> & FloodedRicesProps) => {
	const { activeAction, moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const [nextState, setNextState] = useState<boolean>(false);
	const { isReadOnly } = usePermissions();
	const {
		values,
		touched,
		dirty,
		isValid,
		handleSubmit,
		validateForm,
		isSubmitting,
		errors,
	} = useFormikContext<ModuleFull>();

	const feModuleType = BEModulesObj[beModuleType];
	const description = moduleDescriptions[feModuleType];
	const number = useMemo(() => {
		const currentModule = moduleList.find((step) => step.id === feModuleType);
		return currentModule?.uniqueId ?? "0.0";
	}, [moduleList, feModuleType]);

	const [deleteSeason, { isLoading }] =
		useDeleteFloodedRiceMinorSeasonMutation();
	const [addSeason] = useCreateFloodedRiceMinorSeasonMutation();
	const parentId = moduleList.find(
		(m) => m.id === FEModules.FloodedRice,
	)?.uniqueId;
	const handleRemove = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		remove(index);
		console.log("delete season id: ", id);

		if (id)
			deleteSeason({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
	};
	const handleAddSeason = async () => {
		const payload: FloodedMinorSeasonCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			if (values.seasons.length <= 4) await addSeason(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	const navigate = useNavigate();
	const location = useLocation();
	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);
	const handleSaveAndProceed = async () => {
		await validateForm();
		if (isValid) {
			setNextState(true);
		} else {
			console.log("not valid");
		}
	};

	/* resetting the form is essetial to reset the "dirty" state that's used to control navigation */
	const handleNext = useCallback(async () => {
		// if (!isValid) return;
		const currentModulePath = location.pathname.split("/").pop();
		const currentIndex = moduleList.findIndex(
			(step) => step.path === currentModulePath,
		);
		const nextModulePath =
			currentIndex === moduleList.length - 1
				? "complete"
				: `${moduleList[currentIndex + 1].path}`;
		navigate(`/builder/${activeActivityId}/${nextModulePath}`);
	}, [location.pathname, moduleList, navigate, activeActivityId]);

	useEffect(() => {
		if (!nextState) return;
		if (
			Object.keys(touched).length === 0 &&
			!dirty &&
			!isError &&
			!isSubmitting
		) {
			setNextState(false);
			handleNext();
		}
	}, [nextState, touched, dirty, handleNext, isSubmitting, isError]);

	/* ADD SUBMODULE LOGIC */
	const { data: floodedRiceMinorSeasons } = useGetFloodedRiceMinorSeasonsQuery(
		activeActivityId ?? 0,
		{
			refetchOnMountOrArgChange: true,
		},
	);

	const { data: lucData } = useGetLandUseChangesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const seasons = useMemo(() => {
		return mergeLocalAndServerChanges({
			currentChangesList: values.seasons,
			incomingList: floodedRiceMinorSeasons ?? [],
		});
	}, [floodedRiceMinorSeasons, lucData]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (seasons)
			setInitValues({
				...values,
				seasons,
			});
	}, [seasons, setInitValues]);

	return blockingError ? (
		<section>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey={title} />
					</h2>
					<div className="module-number header-number ff-light-ext">
						{number}
					</div>
				</div>

				<span className="error fs-13 pb-2">{blockingError}</span>
			</div>
		</section>
	) : (
		<section>
			<Form onKeyDown={preventFormSubmit}>
				<div className="module-group">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-14 ff-medium-ext module-header w-fit">
							<TranslatableText translationKey={title} />
						</h2>
						<div className="module-number header-number ff-light-ext">
							{number}
						</div>
					</div>

					<div className="pos-relative mb-3">
						<p className="fs-14">
							<TranslatableText translationKey={description} />
						</p>
						<div
							className="pos-absolute d-flex align-items-center h-100"
							style={{ top: 0, left: "100%" }}
						>
							<NoteButton active={note !== null} />
						</div>
					</div>

					{parentSchema.initInputGroups.map((input, index) => {
						const curatedInput = handleDisableSww({ input, belongsToLuc, index, isReadOnly });

						return input.type === FieldType.HECTARES ? (
							/* if the module belongs to land use change we display the area from luc */
							<GenericInputGroup
								inputGroup={{
									...input,
									inputName: input.inputName,
								}} //, autosubmit: true
								key={input.label}
							/>
						) : (
							<div className="py-2" key={input.label}>
								<GenericInputGroup
									inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
								/>
							</div>
						);
					})}

					{parentSchema.mandatorySubmodules.map((subModule) => (
						<TranslatedFormSubmodule
							submoduleName={subModule.name}
							key={subModule.name}
							note={subModule.note}
						>
							{subModule.inputGroups.map((input, index) => {
								const curatedInput = handleDisableSww({ input, belongsToLuc, index, isReadOnly });
								/* if the module belongs to Land use change: we disabled the statuses that are not related */
								return (
									<div className="py-2" key={input.label}>
										<GenericInputGroup inputGroup={{ ...curatedInput, disabled: isReadOnly }} />
										{/* autosubmit: true */}
									</div>
								);
							})}
						</TranslatedFormSubmodule>
					))}

					{isSeasonsLoading ? (
						<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
							<span className="text-white fs-12">Seasons loading...</span>
							<Spinner size={36} padding={8} />
						</div>
					) : (
						<FieldArray name="seasons">
							{({ remove }) => (
								<>
									{values.seasons.length > 0
										? values.seasons.map((entry, index) => (
											<TranslatedFormSubmodule
												key={entry.id}
												submoduleName={seasonSchema?.name}
												removeHandler={() =>
													handleRemove(() => remove(index), index, entry.id)
												}
												removeLoading={isLoading}
												itemIndex={index + 1}
												itemId={entry.id ?? 0}
											>
												{seasonSchema?.inputGroups.map((input, i) => {
													const curatedInput = handleDisableSww({ input, belongsToLuc, isReadOnly });
													return (
														<GenericInputGroup
															key={input.label}
															inputGroup={{ ...curatedInput, index: index, disabled: isReadOnly }}
														/>
													);
												})}
											</TranslatedFormSubmodule>
										))
										: null}
								</>
							)}
						</FieldArray>
					)}

					<TranslatedAddAccordionButton
						label="module.add_new_season"
						clickHandler={handleAddSeason}
						disabled={(values.seasons.length >= 5) || isReadOnly}
					/>
				</div>

				<ModuleFooter
					footerError={footerError}
					errors={errors}
					touched={touched}
					isLoading={isLoading || isSubmitting}
					isDisabled={isLoading || isSubmitting || isReadOnly}
					handleSaveAndProceed={handleSaveAndProceed}
					handleNext={handleNext}
					shouldBlock={shouldBlock}
				/>
			</Form>
		</section>
	);
};

export default FloodedRiceTierOne;
