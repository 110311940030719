import { useMemo, useEffect, useState } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, useSyncNotesWithStore, useThreadsProcessor } from "../../../../app/hooks";
import { Formik, FormikHelpers } from "formik";
import { BEModules } from "../../../../utils/beModules";
import { ModuleFull } from "./processingTypes";
import { useGetProcessingsQuery, useUpdateProcessingsMutation, useGetProcessingEntriesQuery, useUpdateProcessingEntryMutation } from "../../../../app/features/api/modules/processingApiSlice";
import { getSchemas, validationSchema, initialValues, waterUseNullData } from "./processing";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import GenericOptionalTierTwo from "../GenericOptionalTierTwo";
import ProcessingTierOne from "./ProcessingTierOne";
import ProcessingEntryT2 from "./ProcessingEntryT2";

const ProcessingModule = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);

  const { data: processingsData, isLoading, isError, error } = useGetProcessingsQuery({ activityId: activeActivityId ?? 0 });
  const { data: entriesData, isLoading: isEntriesLoading, isError: isEntriesError, error: entriesError } = useGetProcessingEntriesQuery({ activityId: activeActivityId ?? 0 });
  const [updateProcessings, { isError: isUpdateError, error: updateError }] = useUpdateProcessingsMutation();
  const [updateProcessingEntry, { isError: isUpdateProcessingEntryError, error: updateProcessingEntryError, isLoading: isUpdateProcessingEntryLoading }] = useUpdateProcessingEntryMutation();

  const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
  const [initValues, setInitValues] = useState<ModuleFull>(initialValues);
  const { errorMsg: updateParentErrorMsg } = useModuleHook({
    skeletonsLoadingDeps: [isLoading, isEntriesLoading],
    isMutateError: isUpdateError,
    mutateError: updateError,
  });

  const { errorMsg: fetchError } = useErrorMessage({ isError: isEntriesError ?? isError, error: entriesError ?? error });
  const { errorMsg: updateEntryErrorMsg } = useErrorMessage({ isError: isUpdateProcessingEntryError, error: updateProcessingEntryError });
  const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

  useEffect(() => {
    if (!processingsData) return
    setInitValues((cur) => ({ ...cur, parent: processingsData }))
  }, [processingsData])

  useEffect(() => {
    if (entriesData)
      processModuleThreads({ processings: entriesData })
  }, [processModuleThreads, entriesData])

  const { notes } = useSyncNotesWithStore({
    notes: processingsData?.note ?? null,
  });

  const handleSubmit = async (
    values: ModuleFull,
    { resetForm }: FormikHelpers<ModuleFull>
  ) => {
    console.log(values)

    const { processings, parent } = values
    try {
      const promises = []
      const parentPayload = { body: { ...parent }, activityId: activeActivityId ?? 0 }
      if (parent.id) promises.push(updateProcessings(parentPayload))

      processings.map((entry) => {
        const payload = { body: entry.is_water_used ? { ...entry } : { ...entry, ...waterUseNullData }, activityId: activeActivityId ?? 0 }
        if (entry.id) promises.push(updateProcessingEntry(payload))
        return Promise.resolve()
      })

      await Promise.all(promises);
      resetForm({ values })
    } catch (error) {
      console.error(error)
    }
  }

  return <Formik initialValues={initValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
    <>
      <ProcessingTierOne
        title="project.processing"
        initValues={initValues}
        t2Schema={t2Schema}
        beModuleType={BEModules.Processing}
        moduleSchema={moduleSchema}
        isLoading={isLoading || isEntriesLoading || isUpdateProcessingEntryLoading}
        isEntriesLoading={isEntriesLoading}
        footerError={updateParentErrorMsg ?? updateEntryErrorMsg}
        blockingError={fetchError}
        note={notes?.content ?? null}
        setInitValues={setInitValues}
      />
      <GenericOptionalTierTwo
        setInitValues={setInitValues}
        titleKey="project.processing"
        listInputName="processings"
        EntryComponent={ProcessingEntryT2}
      />
    </>
  </Formik>
};

export default ProcessingModule;