import { apiSlice } from "../api/apiSlice";
import type { ClimateOption, FuelOption, InputTypeOption, Option, SalinityOption } from "../../../types/modulesInterfaces";
interface LandUseProps {
	moduleType?: number;
	climate?: number;
	moisture?: number;
}

interface GWPOption extends Option {
	id: number;
	name: string;
	co2: number;
	ch4: number;
	n2o: number;
}

export const dropdownOptionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getTypesOfFishery: builder.query<Option[], void>({
			query: () => "fishery-types/",
		}),
		getFishCategories: builder.query<Option[], void>({
			query: () => "fish-types/",
		}),
		getSmallFishGearCategory: builder.query<Option[], void>({
			query: () => "small-fishery-gear-types/",
		}),
		getLargeFishGearCategories: builder.query<Option[], void>({
			query: () => "large-fishery-gear-types/",
		}),
		getCropTypes: builder.query<Option[], void>({
			query: () => "crop-types/",
		}),
		getTillageTypes: builder.query<Option[], void>({
			query: () => "tillage-management-types/",
		}),
		getOrganicInputTypes: builder.query<Option[], void>({
			query: () => "organic-input-types/",
		}),
		getResidueTypes: builder.query<Option[], void>({
			query: () => "residue-management-types/",
		}),
		getSoilTypes: builder.query<Option[], void>({
			query: () => "soil-types/",
		}),
		getClimateTypes: builder.query<ClimateOption[], void>({
			query: () => "climates/",
		}),
		getMoistureTypes: builder.query<Option[], void>({
			query: () => "moistures/",
		}),
		getGrasslandTypes: builder.query<Option[], void>({
			query: () => "grassland-management-types/",
		}),
		getProjectStatuses: builder.query<Option[], void>({
			query: () => "statuses/",
		}),
		// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
		getCountries: builder.query<Option[], void>({
			query: () => "countries/",
			transformResponse: (response: Option[]) => response.sort((a, b) => a.name.localeCompare(b.name)),
		}),
		getGWPSources: builder.query<GWPOption[], void>({
			query: () => "global-warming-potentials/", // TODO: URL PROVVISORIO
		}),
		getLandUseTypes: builder.query<Option[], LandUseProps>({
			query: ({ moduleType, climate, moisture }) => `land-use-types/${moduleType ? `?module_type=${moduleType}` : ''}${climate ? `&climate=${climate}` : ''}${moisture ? `&moisture=${moisture}` : ''}`
		}),
		getLivestockCategories: builder.query<Option[], void>({
			query: () => "livestock-category-types/",
		}),
		getLivestockProductionTypes: builder.query<Option[], void>({
			query: () => "livestock-production-types/",
		}),
		getManureManagementTypes: builder.query<Option[], void>({
			query: () => "manure-management-types/",
		}),
		getWaterManagementTypesBC: builder.query<Option[], void>({
			query: () => "water-management-types-before-cultivation/",
		}),
		getWaterManagementTypesAC: builder.query<Option[], void>({
			query: () => "water-management-types-after-cultivation/",
		}),
		getOrganicAmendmentTypes: builder.query<Option[], void>({
			query: () => "organic-amendment-types/",
		}),
		getDisturbanceTypes: builder.query<Option[], void>({
			query: () => "disturbance-types/",
		}),
		getForestTypes: builder.query<Option[], void>({
			query: () => "forest-types/",
		}),
		getForestConditionTypes: builder.query<Option[], void>({
			query: () => "forest-condition-types/",
		}),
		getFundingAgencies: builder.query<Option[], void>({
			query: () => "funding-agencies/",
			providesTags: ["FundingAgencies"],
		}),
		getWaterbodyTypes: builder.query<Option[], void>({
			query: () => "waterbody-types/",
		}),
		getTrophicTypes: builder.query<Option[], void>({
			query: () => "trophic-types/",
		}),
		getFuelTypes: builder.query<FuelOption[], { searchTerm?: string, macroFuelTypeId?: number }>({
			query: ({ macroFuelTypeId, searchTerm }) =>
				macroFuelTypeId ? `fuel-types/?macro_fuel_type=${macroFuelTypeId}`
					: searchTerm ? `fuel-types/?search=${searchTerm}`
						: "fuel-types/",
		}),
		getSalinityTypes: builder.query<Option[], void>({
			query: () => 'salinity-types/',
			//TODO: ask to get this types from API as Option
			transformResponse: (response: SalinityOption[]) => response.map(({ id, value }) => ({ id, name: value }))
		}),
		getMacroFuelTypes: builder.query<Option[], void>({
			query: () => "macro-fuel-types/",
		}),
		getPeatTypes: builder.query<Option[], void>({
			query: () => "peat-types/",
		}),
		getFireTypes: builder.query<Option[], void>({
			query: () => "fire-types/",
		}),
		getIrrigationSystemTypes: builder.query<Option[], { moduleTypes?: number }>({
			query: (params) => ({
				url: 'irrigation-system-types/',
				params: {
					module_types: params?.moduleTypes
				}
			})
		}),
		createFundingAgency: builder.mutation<Option, string>({
			// TODO: BODY PROVVISORIO
			query: (name) => ({
				url: "funding-agencies/",
				method: "POST",
				body: { name },
			}),
			invalidatesTags: ["FundingAgencies"],
		}),
		getExecutingAgencies: builder.query<Option[], void>({
			query: () => "executing-agencies/",
			providesTags: ["ExecutingAgencies"],
		}),
		createExecutingAgency: builder.mutation<Option, string>({
			// TODO: BODY PROVVISORIO
			query: (name) => ({
				url: "executing-agencies/",
				method: "POST",
				body: { name },
			}),
			invalidatesTags: ["ExecutingAgencies"],
		}),
		getRoadTypes: builder.query<Option[], void>({
			query: () => 'road-types/'
		}),
		getBuildingTypes: builder.query<Option[], void>({
			query: () => 'building-types/'
		}),
		getEmissionFactorSources: builder.query<Option[], void>({
			query: () => 'emission-factor-sources/'
		}),
		getSettlementTypes: builder.query<Option[], void>({
			query: () => 'settlement-types/'
		}),
		getChangeRates: builder.query<Option[], void>({
			query: () => 'change-rates/'
		}),
		getRefrigerantTypes: builder.query<Option[], void>({
			query: () => 'refrigerant-types/'
		}),
		getPackagingMaterialTypes: builder.query<Option[], void>({
			query: () => 'packaging-material-types/'
		}),
		getInputTypes: builder.query<InputTypeOption[], void>({
			query: () => "input-types/",
		}),
	}),
});

export const {
	useGetLandUseTypesQuery,
	useGetTypesOfFisheryQuery,
	useGetFishCategoriesQuery,
	useGetLargeFishGearCategoriesQuery,
	useGetSmallFishGearCategoryQuery,
	useGetCropTypesQuery,
	useGetOrganicInputTypesQuery,
	useGetResidueTypesQuery,
	useGetTillageTypesQuery,
	useGetGrasslandTypesQuery,
	useGetGWPSourcesQuery,
	useGetCountriesQuery,
	useCreateExecutingAgencyMutation,
	useCreateFundingAgencyMutation,
	useGetExecutingAgenciesQuery,
	useGetFundingAgenciesQuery,
	useGetForestTypesQuery,
	useGetForestConditionTypesQuery,
	useGetTrophicTypesQuery,
	useGetWaterbodyTypesQuery,
	useGetMacroFuelTypesQuery,
	useGetFuelTypesQuery,
	useGetIrrigationSystemTypesQuery,
	useGetSettlementTypesQuery,
	useGetChangeRatesQuery,
	useGetClimateTypesQuery,
	useGetMoistureTypesQuery,
	useGetRefrigerantTypesQuery,
	useGetPackagingMaterialTypesQuery,
	useGetInputTypesQuery
} = dropdownOptionsApiSlice;