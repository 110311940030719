import { validatePercentage, validatePositiveNumber } from "../moduleUtils";
import type { CoastalWetlandsPayload, CoastalWetlandsT2Payload } from "./coastalWetlandsTypes";
import * as Yup from "yup";


export const includedPropList = [
    "id",
    "status",
    "note",
    "ha_start",
    "ha_w",
    "ha_wo",
    "land_use_type",
    "ha_thread",
    "area",

    "area_under_drainage_start",
    "area_under_drainage_w",
    "area_under_drainage_wo",
    "area_under_drainage_thread",
    "drained_area_excavated_start",
    "drained_area_excavated_w",
    "drained_area_excavated_wo",
    "drained_area_excavated_thread",
    "area_not_drained_or_rewetted_start",
    "area_not_drained_or_rewetted_w",
    "area_not_drained_or_rewetted_wo",
    "area_not_drained_or_rewetted_thread",
    "area_w_restored_vegetation_start",
    "area_w_restored_vegetation_w",
    "area_w_restored_vegetation_wo",
    "area_w_restored_vegetation_thread",
]
export const includedT2PropList = [
    "soc_t2_start",
    "soc_t2_w",
    "soc_t2_wo",
    "pc_c_lost_after_excavation_t2_start",
    "pc_c_lost_after_excavation_t2_w",
    "pc_c_lost_after_excavation_t2_wo",
    "agb_t2_start",
    "agb_t2_w",
    "agb_t2_wo",
    "bgb_t2_start",
    "bgb_t2_w",
    "bgb_t2_wo",
    "litter_t2_start",
    "litter_t2_w",
    "litter_t2_wo",
    "deadwood_t2_start",
    "deadwood_t2_w",
    "deadwood_t2_wo",
    "drainage_ef_t2_start",
    "drainage_ef_t2_w",
    "drainage_ef_t2_wo",
    "co2_rewetting_t2_start",
    "co2_rewetting_t2_w",
    "co2_rewetting_t2_wo",
    "ch4_rewetting_t2_start",
    "ch4_rewetting_t2_w",
    "ch4_rewetting_t2_wo",
    "soil_type_t2",
    "avg_salinity_t2",
]

export const validationSchema = Yup.object().shape({
    module: Yup.object({
        land_use_type: validatePositiveNumber.required('validations.land_use_type_required'),
    }),
    tiertwo: Yup.object({
        pc_c_lost_after_excavation_t2_start: validatePercentage,
        pc_c_lost_after_excavation_t2_w: validatePercentage,
        pc_c_lost_after_excavation_t2_wo: validatePercentage,
    })
});
export const initialValues: CoastalWetlandsPayload = {
    id: null,
    status: null,
    note: null,
    ha_start: null,
    ha_w: null,
    ha_wo: null,
    land_use_type: null,
    ha_thread: null,
    area: null,
    activity: null,
    start_year: null,
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,

    area_under_drainage_start: null,
    area_under_drainage_w: null,
    area_under_drainage_wo: null,
    area_under_drainage_thread: null,
    drained_area_excavated_start: null,
    drained_area_excavated_w: null,
    drained_area_excavated_wo: null,
    drained_area_excavated_thread: null,
    area_not_drained_or_rewetted_start: null,
    area_not_drained_or_rewetted_w: null,
    area_not_drained_or_rewetted_wo: null,
    area_not_drained_or_rewetted_thread: null,
    area_w_restored_vegetation_start: null,
    area_w_restored_vegetation_w: null,
    area_w_restored_vegetation_wo: null,
    area_w_restored_vegetation_thread: null,
};
/* T2 */
export const t2InitialValues: CoastalWetlandsT2Payload = {
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    pc_c_lost_after_excavation_t2_start: null,
    pc_c_lost_after_excavation_t2_w: null,
    pc_c_lost_after_excavation_t2_wo: null,
    agb_t2_start: null,
    agb_t2_w: null,
    agb_t2_wo: null,
    bgb_t2_start: null,
    bgb_t2_w: null,
    bgb_t2_wo: null,
    litter_t2_start: null,
    litter_t2_w: null,
    litter_t2_wo: null,
    deadwood_t2_start: null,
    deadwood_t2_w: null,
    deadwood_t2_wo: null,
    drainage_ef_t2_start: null,
    drainage_ef_t2_w: null,
    drainage_ef_t2_wo: null,
    co2_rewetting_t2_start: null,
    co2_rewetting_t2_w: null,
    co2_rewetting_t2_wo: null,
    ch4_rewetting_t2_start: null,
    ch4_rewetting_t2_w: null,
    ch4_rewetting_t2_wo: null,
    soil_type_t2: null,
    avg_salinity_t2: null,
};
