
export enum BEModules {
	PerennialCropland = 5,
	AnnualCropland = 4,
	LivestockManagement = 8,
	Energy = 34,
	Irrigation = 35,
	IrrigationSystem = 36,
	OperationPhaseOfIrrigation = 37,
	Inputs = 44,
	FloodedRice = 6,
	ForestManagement = 24,
	LandUseChange = 40,
	LargeFisheries = 21,
	SmallFisheries = 20,
	Aquaculture = 22,
	OrganicSoil = 38,
	CoastalWetlands = 39,
	Waterbodies = 19,
	SetAside = 33,
	OtherLand = 30,
	Settlements = 23,
	Road = 31,
	Building = 32,
	OtherInfrastructure = 41,
	Grassland = 7,
	Packaging = 57,
	Transport = 58,
	Storage = 55,
	Processing = 56
}
