import {
	ReactElement,
	createContext,
	CSSProperties,
	useContext,
	useState,
	useEffect,
} from "react";
import { inputState } from "../../../types/types";
import { SubModuleInputGroupContext } from "../SubModuleInputGroup";
import {
	ErrorWithTooltip,
	CommentButton,
	HelpButton,
} from "../../tabsButtons/TabsButtons";
import { motion } from "framer-motion";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import Skeleton from "react-loading-skeleton";
import { Actions } from "../../../types/interfaces";

export const FormFieldContext = createContext<{
	state: inputState;
	setState: (value: inputState) => void;
}>({
	setState: () => { },
	state: "idle",
});

const FormRow = ({
	children,
	error,
	topLabel,
	classes,
	style,
	borderColor,
	rowComment,
	rowHelp,
	isFetching,
}: {
	children: ReactElement;
	classes?: string;
	style?: CSSProperties;
	borderColor?: "primary" | "quaternary" | "tertiary";
	error?: string;
	rowComment?: { active: boolean; onClick: () => void };
	rowHelp?: { onClick: () => void; type: "info" | "video" };
	topLabel?: string | string[];
	isFetching?: boolean;
}) => {
	const defaultLabel = Array.isArray(topLabel) ? (
		<div className="d-flex f-column align-items-start default-label">
			{topLabel.map((label) => (
				<motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.2 }} className="fs-9 text-darker-gray" key={label}>
					{label}
				</motion.span>
			))}
		</div>
	) : topLabel ? (
		<span className="fs-9 text-darker-gray default-label">{topLabel}</span>
	) : null;

	const { setIsGroupFocused } = useContext(SubModuleInputGroupContext);
	const [state, setState] = useState<inputState>("idle");
	const [showError, setShowError] = useState<boolean>(false);
	const [showComments, setShowComments] = useState<boolean>(false);
	const [showHelp, setShowHelp] = useState<boolean>(false);

	const { activeAction } = useAppSelector(selectCurrentBuilder);

	useEffect(
		() => setShowError(error?.length !== undefined && error.length > 0),
		[error, activeAction],
	);
	useEffect(
		() =>
			setShowComments(
				rowComment !== undefined &&
				rowComment?.active &&
				activeAction === Actions.Comments,
			),
		[rowComment, activeAction],
	);
	useEffect(() => setShowHelp(activeAction === Actions.Help), [rowHelp]);
	useEffect(() => {
		if (setIsGroupFocused) setIsGroupFocused(state === "focused");
	}, [state, setIsGroupFocused]);

	return (
		<div
			className={`form-row ${classes ?? ""} ${borderColor ? `form-row-border ${borderColor}` : ""}`}
			style={style}
		>
			{defaultLabel}
			<FormFieldContext.Provider value={{ state, setState }}>
				{isFetching ? (
					<Skeleton height="100%" containerClassName="form-row-skeleton" />
				) : (
					children
				)}
			</FormFieldContext.Provider>

			<ErrorWithTooltip showError={showError} errorLabel={error ?? ""} />
			{!showError ? (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className="form-row-icon clickable"
				>
					{showComments ? (
						<CommentButton active={rowComment?.active} />
					) : showHelp && rowHelp ? (
						<HelpButton type={rowHelp?.type} />
					) : (
						<></>
					)}
				</motion.div>
			) : (
				<></>
			)}
		</div>
	);
};

export default FormRow;
