import ProjectSelectTable from "./ProjectSelectTable";
import Skeleton from "react-loading-skeleton";
import {
	ProjectDescription,
	StartNewProjectButton,
	HeaderSpan,
} from "./SelectProjectUtils";
import { createColumnHelper } from "@tanstack/react-table";
import { useAppSelector, useAppDispatch, usePermissions } from "../../app/hooks";
import { selectCurrentUser } from "../../app/features/auth/authSlice";
import {
	clearProject,
	setProject,
} from "../../app/features/project/projectSlice";

import KebabMenu from "../../components/kebabMenu/KebabMenu";
import type { Line } from "../../components/kebabMenu/KebabMenu";
import type { NavigateFunction } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
	useCreateProjectCopyMutation,
	useGetProjectsQuery,
} from "../../app/features/project/projectApiSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FastProject, Role, type User } from "../../types/interfaces";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import useDeleteProject from "./useDeleteProject";
import { usePaginate } from "../project/activities/usePaginate";
import Pagination from "../../components/pagination/Pagination";
import { TranslationKey } from "../../types/modulesInterfaces";
import TranslatableText from "../../components/translations/TranslatableText";
import DownloadExcels from "./DownloadExcels";
import { useAppContext } from "../../contexts/AppProvider";
import { EventTypes } from "../../utils/firebaseUtils";
import { useFirebaseHook } from "../../utils/firebaseUtils";

const ITEMS_PER_PAGE = 15

const SelectProject = () => {
	const user: User | null = useAppSelector(selectCurrentUser);
	const navigate: NavigateFunction = useNavigate();
	const dispatch = useAppDispatch();
	//full initial path for vitest
	const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
	const [url, setUrl] = useState<string>(`${baseUrl}/projects/?page=1&page_size=${ITEMS_PER_PAGE}`);

	useEffect(() => {
		dispatch(setProject(null));
	}, []);

	const {
		data: projectsRes,
		isLoading,
		isFetching,
		isError,
		refetch,
	} = useGetProjectsQuery({ url, fast: true }, {
		refetchOnMountOrArgChange: true,
	});

	const {
		results: projects,
		count: projectsCount,
		next,
		previous,
	} = projectsRes || {};

	const { handleNext, handlePrev, handlePageClick } = usePaginate({
		next,
		previous,
		fetch: refetch,
		dynamicNext: (page: number) => `/projects/?page=${page}&page_size=${ITEMS_PER_PAGE}`,
		setUrl
	})
	const { showDeleteModal, isDeleteDialogOpen, handleDelete, handleCancel, isLoadingDelete } =
		useDeleteProject(refetch);

	const [copyProject, { isLoading: isLoadingCopy }] =
		useCreateProjectCopyMutation();
	const { fireEvent } = useFirebaseHook();
	const tableData = useMemo(
		() => (isLoading ? Array(2).fill({}) as FastProject[] : projects),
		[isLoading, projects],
	);

	const handleNewProject = () => {
		dispatch(clearProject());
		navigate("/project/new/description");
	};

	const columnHelper = createColumnHelper<FastProject>();

	const duplicateSelectedProject = useCallback(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		async (info: any) => {
			const project: FastProject = info?.row?.original;
			if (!project.id || isLoadingCopy) return;
			await copyProject(project.id).unwrap();
			fireEvent(EventTypes.duplicate_project);
			refetch();
		},
		[copyProject, refetch, isLoadingCopy],
	);

	const editProject = useCallback(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		async (info: any) => {
			const project: FastProject = info?.row?.original;
			if (!project.id) return;
			navigate(`/project/${project.id}/description`);
		},
		[navigate],
	);

	const { getUserRole } = usePermissions();
	const columns = useMemo(() => {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		const handleSelectedProject = (info: any) => {
			const project: FastProject = info?.row?.original;
			navigate(`/project/${project.id}/activities`);
			//dispatch(setProject(project)); // TODO: check if this creates errors
		};

		return [
			columnHelper.accessor("name", {
				cell: (info) => (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<span
						className="fs-9 clickable"
						onClick={() => handleSelectedProject(info)}
					>
						{info.getValue()}
					</span>
				),
				header: () => <HeaderSpan name="project.project" />,
				enableSorting: true,
				sortingFn: "alphanumeric",
			}),
			columnHelper.accessor("country", {
				cell: (info) => (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<span
						className="fs-9 clickable"
						onClick={() => handleSelectedProject(info)}
					>
						{info.getValue()}
					</span>
				),
				header: () => <HeaderSpan name="main.location" />,
				enableSorting: true,
				sortingFn: "alphanumeric",
			}),
			columnHelper.accessor("role", {
				cell: (info) => {
					const roles = info.getValue();
					if (Array.isArray(roles)) {
						return (
							<span className="fs-9">
								{roles.join(", ")}
							</span>
						)
					}
					return roles;
				},
				header: () => <HeaderSpan name="main.role" />,
				enableSorting: true,
				sortingFn: (rowA, rowB, columnId) => {

					const a = rowA.getValue(columnId) as string[];
					const b = rowB.getValue(columnId) as string[];
					return a[0].localeCompare(b[0]);
				}
			}),
			columnHelper.accessor("updated_at", {
				cell: (info) => {
					const lastUpdated = info.getValue();
					return (
						<span className="fs-9">
							{new Date(lastUpdated).toLocaleString()}
						</span>
					)
				},
				header: () => <HeaderSpan name="main.last_updated" />,
				enableSorting: true,
				sortingFn: (rowA, rowB, columnId) => {
					const a = rowA.getValue(columnId) as string;
					const b = rowB.getValue(columnId) as string;
					return a.localeCompare(b);
				}
			}),
			/* columnHelper.display({
				id: "notes",
				cell: () => <NoteComponent link={""} disabled={false} />,
				header: () => (
					<div className="w-100 text-center">
						<HeaderSpan name="Notes" center />
					</div>
				),
			}), */
			columnHelper.display({
				id: "edit",
				cell: (info) => {
					const roles = info?.row?.original.role;
					const isAdminOrAnalyst = roles?.includes(Role.ADMIN) || roles?.includes(Role.ANALYST);

					const mutateLines: Line[] = [
						{
							id: 3,
							label: "main.delete",
							icon: BiTrash,
							clickHandler: () => showDeleteModal(info?.row?.original),
							activeColor: "var(--error)"
						},
						{
							id: 2,
							label: "main.edit",
							icon: BiEditAlt,
							clickHandler: () => editProject(info),
						},
					];
					const duplicateLine: Line = {
						id: 1,
						label: "main.duplicate",
						icon: MdContentCopy,
						clickHandler: () => duplicateSelectedProject(info),
					};
					const actionLines = isAdminOrAnalyst ? [...mutateLines, duplicateLine] : [duplicateLine];

					return <WrappedKebabMenu lines={actionLines} />;
				},
				header: () => (
					<div className="w-100 text-center">
						<HeaderSpan name="main.edit" />
					</div>
				),
			}),
		];
	}, [
		navigate,
		dispatch,
		columnHelper,
		duplicateSelectedProject,
		editProject,
		showDeleteModal,
		getUserRole
	]);

	const tableColumns = useMemo(
		() =>
			isLoading || isFetching
				? columns.map((column) => ({
					...column,
					cell: (
						<Skeleton width="100%" containerClassName="form-row-skeleton" />
					),
				}))
				: columns,
		[isLoading, columns, isFetching],
	);

	const bucketUrl = useAppContext();

	const imgProps = {
		src: `${bucketUrl}/project_select_banner.jpg`,
		alt: "Kakuma, Kenya - Martha Kasafi, a refugee from Democratic Republic of Congo, works at her vegetable crops"
	}

	return (
		<div className="login-container">
			<article className="bg-gray">
				<img
					{...imgProps}
					className="w-100"
					style={{ aspectRatio: "700 / 383", objectFit: "cover", objectPosition: "top" }}
				/>
				<div className="d-flex f-column pt-1 pb-2 px-2">
					<div className="d-flex justify-content-between">
						<ProjectDescription user={user?.first_name ?? ""} />
						<a href={`${process.env.REACT_APP_BASE_API_ROUTE}blog/ui/`} className="fs-13" target="_blank" rel="noopener noreferrer">Release Notes</a>
					</div>
					{isError ? (
						<span className="error fs-13 pb-2">
							An error occurred while fetching projects data
						</span>
					) : isLoadingDelete
						? tableData?.map((act) => (
							<Skeleton width="100%" containerClassName="form-row-skeleton" />
						))
						: (
							<>
								<ProjectSelectTable
									data={(tableData ?? []) as FastProject[]}
									columns={tableColumns}
								/>
								<ConfirmationModal
									open={isDeleteDialogOpen}
									onCancel={handleCancel}
									onConfirm={handleDelete}
									message={<TranslatableText translationKey="project.delete_confirmation" />}
								/>
							</>
						)}
					{
						projects && projectsCount && projectsCount > ITEMS_PER_PAGE ? (
							<Pagination
								totalItems={projectsCount ?? 0}
								onNext={handleNext}
								onPrevious={handlePrev}
								onPageClick={handlePageClick}
								itemsPerPage={ITEMS_PER_PAGE}
								size="sm"
							/>
						) : projectsCount === 0 && !isLoading ?
							<div style={{ height: "5rem" }} className="w-100 d-flex align-items-center justify-content-center bg-white">
								<TranslatableText translationKey="project.no_projects_yet" className="ff-medium" />
							</div> : null
					}
					<div className="pt-2">
						<StartNewProjectButton handleClick={handleNewProject} />
					</div>
				</div>

				<DownloadExcels />
			</article>
		</div>
	);
};

const standardColumn = (name: TranslationKey) => {
	return {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		cell: (info: any) => <span className="fs-9">{info.getValue()?.name}</span>,
		header: () => <HeaderSpan name={name} />,
	};
};

const WrappedKebabMenu = ({ lines }: { lines: Line[] }) => {
	return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-center">
			<KebabMenu lines={lines} activeColor="var(--primary-bright)" />
		</div>
	);
};

export default SelectProject;
