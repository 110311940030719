import { useMemo, useEffect, useState } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, useSyncNotesWithStore, useThreadsProcessor } from "../../../../app/hooks";
import { Formik, FormikHelpers } from "formik";
import { BEModules } from "../../../../utils/beModules";
import { ModuleFull } from "./storageTypes";
import { useGetStoragesQuery, useUpdateStoragesMutation, useUpdateStorageEntryMutation, useGetStorageEntriesQuery } from "../../../../app/features/api/modules/storageApiSlice";
import { getSchemas, validationSchema, initialValues, refrigerantNullData } from "./storage";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import GenericOptionalTierTwo from "../GenericOptionalTierTwo";
import StorageTierOne from "./StorageTierOne";
import StorageEntryT2 from "./StorageEntryT2";

const Storages = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);

  const { data: storagesData, isLoading, isError, error } = useGetStoragesQuery({ activityId: activeActivityId ?? 0 });
  const { data: entriesData, isLoading: isEntriesLoading, isError: isEntriesError, error: entriesError } = useGetStorageEntriesQuery({ activityId: activeActivityId ?? 0 });
  const [updateStorages, { isError: isUpdateError, error: updateError }] = useUpdateStoragesMutation();
  const [updateStorageEntry, { isError: isUpdateStorageEntryError, error: updateStorageEntryError, isLoading: isUpdateStorageEntryLoading }] = useUpdateStorageEntryMutation();

  const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
  const [initValues, setInitValues] = useState<ModuleFull>(initialValues);
  const { errorMsg: updateParentErrorMsg } = useModuleHook({
    skeletonsLoadingDeps: [isLoading, isEntriesLoading],
    isMutateError: isUpdateError,
    mutateError: updateError,
  });

  const { errorMsg: fetchError } = useErrorMessage({ isError: isEntriesError ?? isError, error: entriesError ?? error });
  const { errorMsg: updateEntryErrorMsg } = useErrorMessage({ isError: isUpdateStorageEntryError, error: updateStorageEntryError });
  const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

  useEffect(() => {
    if (!storagesData) return
    setInitValues((cur) => ({ ...cur, parent: storagesData }))
  }, [storagesData])

  useEffect(() => {
    if (entriesData)
      processModuleThreads({ storages: entriesData })
  }, [processModuleThreads, entriesData])

  const { notes } = useSyncNotesWithStore({
    notes: storagesData?.note ?? null,
  })

  const handleSubmit = async (
    values: ModuleFull,
    { resetForm }: FormikHelpers<ModuleFull>
  ) => {
    console.log(values)

    const { storages, parent } = values
    try {
      const promises = []
      const parentPayload = { body: { ...parent }, activityId: activeActivityId ?? 0 }
      if (parent.id) promises.push(updateStorages(parentPayload))

      storages.map((entry) => {
        const payload = { body: entry.is_refrigerant_used ? { ...entry } : { ...entry, ...refrigerantNullData }, activityId: activeActivityId ?? 0 }
        if (entry.id) promises.push(updateStorageEntry(payload))
        return Promise.resolve()
      })

      await Promise.all(promises);
      resetForm({ values })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <StorageTierOne
          title="project.storage"
          initValues={initValues}
          t2Schema={t2Schema}
          beModuleType={BEModules.Storage}
          moduleSchema={moduleSchema}
          isLoading={isLoading || isEntriesLoading || isUpdateStorageEntryLoading}
          isEntriesLoading={isEntriesLoading}
          footerError={updateParentErrorMsg ?? updateEntryErrorMsg}
          blockingError={fetchError}
          note={notes?.content ?? null}
          setInitValues={setInitValues}
        />
        <GenericOptionalTierTwo
          setInitValues={setInitValues}
          titleKey="project.storage"
          listInputName="storages"
          EntryComponent={StorageEntryT2}
        />
      </>
    </Formik>
  );
};

export default Storages;

