import { useFormikContext } from "formik";
import { ModuleProps, T2Schema } from "../../../../types/modulesInterfaces";
import { ModuleFull, TransportEntryResponse } from "./transportTypes";
import { usePermissions } from "../../../../app/hooks";
import { FEModules } from "../../../../utils/moduleList";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { useMemo, SetStateAction, useEffect } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector } from "../../../../app/hooks";
import { useCreateTransportEntryMutation, useDeleteTransportEntryMutation, useGetTransportEntriesQuery } from "../../../../app/features/api/modules/transportApiSlice";
import { mergeLocalAndServerChanges } from "../inputs/utils";
import { isEqual } from 'lodash';
import { useVCEnergyHandler } from "../../../../utils/useVCEnergyHandler";
import GenericModule from "../GenericModule";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";

interface TransportTierOneProps {
  initValues: ModuleFull;
  isEntriesLoading: boolean;
  t2Schema: T2Schema;
  setInitValues: (value: SetStateAction<ModuleFull>) => void
}

const TransportTierOne = ({ initValues, isEntriesLoading, setInitValues, ...moduleProps }: TransportTierOneProps & ModuleProps) => {

  const { values } = useFormikContext<ModuleFull>()
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { isReadOnly } = usePermissions()
  const { moduleList } = useAppSelector(selectCurrentBuilder);
  useVCEnergyHandler<ModuleFull, TransportEntryResponse>({ listInputName: "transports", energyTypeInputName: "fuel_type" })

  const parentId = useMemo(() => moduleList.find((m) => m.id === FEModules.Transport)?.uniqueId, [moduleList]);
  const [deleteTransportEntry, { isLoading: isLoadingDeleteTransportEntry }] = useDeleteTransportEntryMutation()
  const [createTransportEntry, { isLoading: isLoadingCreateTransportEntry }] = useCreateTransportEntryMutation()

  const { data: entriesData } = useGetTransportEntriesQuery({ activityId: activeActivityId ?? 0 });
  const list = useMemo(() => {
    const isEntriesUpdated = !isEqual(values.transports, entriesData)
    return isEntriesUpdated ? mergeLocalAndServerChanges({
      currentChangesList: values.transports,
      incomingList: entriesData ?? [],
    }) : values.transports
  }, [entriesData])

  useEffect(() => {
    if (list)
      setInitValues((cur) => ({ ...cur, transports: list }))
  }, [list])  

  const handleDeleteTransport = (
    remove: <T>(index: number) => T | undefined,
    index: number,
    id?: number | null) => {
    remove(index)
    if (id) deleteTransportEntry({ id, activityId: activeActivityId ?? 0, parent: parentId })
  }
  const handleCreateTransport = () => {
    createTransportEntry({ activityId: activeActivityId ?? 0, parent: parentId })
  }

  return (
    <GenericModule {...moduleProps} disableOptionals>
      {
        ({ isSWW }) => (
          <>
            {moduleProps.moduleSchema.optionalSubmodules?.map((subModule, index) => {
              return (
                <GenericOptionalSubModule<TransportEntryResponse>
                  key={`${subModule.name}-${index}`}
                  optionalSchema={subModule}
                  values={values?.transports}
                  addLabel="module.add_new_transport"
                  createHandler={handleCreateTransport}
                  removeHandler={handleDeleteTransport}
                  fieldArrayName={subModule.name}
                  isOptionalLoading={isEntriesLoading || isLoadingCreateTransportEntry || isLoadingDeleteTransportEntry}
                  isCreateLoading={isLoadingCreateTransportEntry}
                  isDeleteLoading={isLoadingDeleteTransportEntry}
                  isReadOnly={isReadOnly}
                  isSWW={isSWW}
                />
              )
            })}
          </>
        )
      }
    </GenericModule>
  );
}

export default TransportTierOne;