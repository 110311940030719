import { useFormikContext } from "formik";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { ModuleProps, T2Schema } from "../../../../types/modulesInterfaces";
import { ModuleFull, ProcessingEntryResponse } from "./processingTypes";
import { useCreateProcessingEntryMutation, useDeleteProcessingEntryMutation, useGetProcessingEntriesQuery } from "../../../../app/features/api/modules/processingApiSlice";
import { FEModules } from "../../../../utils/moduleList";
import { useMemo, useEffect, SetStateAction } from "react";
import { isEqual } from 'lodash';
import { mergeLocalAndServerChanges } from "../inputs/utils";
import { useVCEnergyHandler } from "../../../../utils/useVCEnergyHandler";
import GenericModule from "../GenericModule";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";

interface ProcessingTierOneProps {
  initValues: ModuleFull;
  isEntriesLoading: boolean;
  t2Schema: T2Schema;
  setInitValues: (value: SetStateAction<ModuleFull>) => void
}

const ProcessingTierOne = ({
  initValues,
  isEntriesLoading,
  setInitValues,
  ...moduleProps
}: ModuleProps & ProcessingTierOneProps) => {
  const { values } = useFormikContext<ModuleFull>()
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { isReadOnly } = usePermissions()
  const { moduleList } = useAppSelector(selectCurrentBuilder);
  useVCEnergyHandler<ModuleFull, ProcessingEntryResponse>({ listInputName: "processings", energyTypeInputName: "fuel_type" })


  const parentId = useMemo(() => moduleList.find((m) => m.id === FEModules.Processing)?.uniqueId, [moduleList]);
  const [deleteProcessingEntry, { isLoading: isLoadingDeleteProcessingEntry }] = useDeleteProcessingEntryMutation()
  const [createProcessingEntry, { isLoading: isLoadingCreateProcessingEntry }] = useCreateProcessingEntryMutation()

  const { data: entriesData } = useGetProcessingEntriesQuery({ activityId: activeActivityId ?? 0 });
  const list = useMemo(() => {
    const isEntriesUpdated = !isEqual(values.processings, entriesData)
    return isEntriesUpdated ? mergeLocalAndServerChanges({
      currentChangesList: values.processings,
      incomingList: entriesData ?? [],
    }) : values.processings
  }, [entriesData])

  useEffect(() => {
    if (list)
      setInitValues((cur) => ({ ...cur, processings: list }))
  }, [list])

  const handleDeleteProcessing = (
    remove: <T>(index: number) => T | undefined,
    index: number,
    id?: number | null,) => {
    remove(index)
    if (id) deleteProcessingEntry({ id, activityId: activeActivityId ?? 0, parent: parentId })
  }
  const handleCreateProcessing = () => {
    createProcessingEntry({ activityId: activeActivityId ?? 0, parent: parentId })
  }

  return (
    <GenericModule {...moduleProps} disableOptionals>
      {
        ({ isSWW }) => (
          <>
            {moduleProps.moduleSchema.optionalSubmodules?.map((subModule, index) => {
              return (
                <GenericOptionalSubModule<ProcessingEntryResponse>
                  key={`${subModule.name}-${index}`}
                  optionalSchema={subModule}
                  values={values?.processings}
                  addLabel="module.add_new_processing"
                  createHandler={handleCreateProcessing}
                  removeHandler={handleDeleteProcessing}
                  fieldArrayName={subModule.name}
                  isOptionalLoading={isEntriesLoading || isLoadingCreateProcessingEntry || isLoadingDeleteProcessingEntry}
                  isCreateLoading={isLoadingCreateProcessingEntry}
                  isDeleteLoading={isLoadingDeleteProcessingEntry}
                  isReadOnly={isReadOnly}
                  isSWW={isSWW}
                />
              )
            })}
          </>
        )
      }
    </GenericModule>
  );
};

export default ProcessingTierOne;