import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectCurrentToken, selectCurrentUser } from "./app/features/auth/authSlice";
import {
	selectModals,
	closeUserModal,
	closeShareModal,
	closeManageUsersModal,
} from "./app/features/header/headerSlice";
import { AnimatePresence } from "framer-motion";
import UserModal from "./components/userManagementModals/UserModal";
import ShareProjectModal from "./components/userManagementModals/ShareProjectModal";
import ManageUsersModal from "./components/userManagementModals/ManageUsersModal";
import { User } from "./types/interfaces";

const ProtectedLayout = () => {
	const accessToken: string | null = useAppSelector(selectCurrentToken);
	const user: User | null = useAppSelector(selectCurrentUser);
	const location = useLocation();

	const { shareModalOpen, userModalOpen, manageUsersModalOpen } =
		useAppSelector(selectModals);
	const dispatch = useAppDispatch();

	return ( accessToken && user ) ? (
		<>
			{/* TODO: se abbiamo uno stile comune per le protected routes (tipo l'header) lo definiamo qui*/}
			<Outlet />
			<AnimatePresence>
				{userModalOpen ? (
					<UserModal handleClose={() => dispatch(closeUserModal())} />
				) : manageUsersModalOpen ? (
					<ManageUsersModal
						handleClose={() => dispatch(closeManageUsersModal())}
					/>
				) : shareModalOpen ? (
					<ShareProjectModal handleClose={() => dispatch(closeShareModal())} />
				) : null}
			</AnimatePresence>
		</>
	) : (
		<Navigate to="/auth/login" state={{ from: location }} replace />
	);
};

export default ProtectedLayout;
