import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { MdArrowBack } from 'react-icons/md';
import TranslatableText from '../translations/TranslatableText';
import { TranslationKey } from '../../types/modulesInterfaces';

const BackButton = ({ to, backLabel, style }: { to: string, backLabel: TranslationKey, style?: React.CSSProperties }) => {
    const navigate = useNavigate();
	const handleClick = () => {
		navigate( to );
	};
  return (
    <Button type="button" classes="btn-login ff-medium" onClick={handleClick} style={style ?? { maxWidth: 150}}>
        <TranslatableText className="bg-quat-de px-1 label" translationKey={backLabel} />
        <div className="bg-quat-br icon">
            <MdArrowBack size={20} color="var(--white)" />
        </div>
    </Button>
  )
}

export default BackButton