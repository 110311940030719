import { getIn, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useGetFuelTypesQuery } from "../app/features/dropdownOptions/dropdownOptionsApiSlice"
import { isEqual } from 'lodash';

interface VCEnergyHandlerProps {
  listInputName: string,
  energyTypeInputName: string,
}

const ELECTRICITY_ID = 35

// this hook is setting the values for fuel units and if electricity is selected
export const useVCEnergyHandler = <T, L>({ listInputName, energyTypeInputName }: VCEnergyHandlerProps) => {
  const { values, setFieldValue } = useFormikContext<T>()
  const { data } = useGetFuelTypesQuery({})
  const [list, setList] = useState<L[]>([])

  useEffect(() => {
    const valuesList: L[] = getIn(values, listInputName)
    const isListUpdated = !isEqual(valuesList, list)
    if (isListUpdated) { setList([...valuesList]); }
  }, [values, list])

  useEffect(() => {
    for (const [index, item] of list.entries()) {
      const energyTypeStart = getIn(item, `${energyTypeInputName}_start`)
      const energyTypeWith = getIn(item, `${energyTypeInputName}_w`)
      const energyTypeWithout = getIn(item, `${energyTypeInputName}_wo`)

      const isElectricitySelected = [energyTypeStart, energyTypeWith, energyTypeWithout].includes(ELECTRICITY_ID)

      const startUnit = data?.find(fuelType => fuelType.id === energyTypeStart)?.unit ?? ""
      const withUnit = data?.find(fuelType => fuelType.id === energyTypeWith)?.unit ?? ""
      const withoutUnit = data?.find(fuelType => fuelType.id === energyTypeWithout)?.unit ?? ""

      setFieldValue(`${listInputName}.${index}.${energyTypeInputName}_start_unit`, startUnit)
      setFieldValue(`${listInputName}.${index}.${energyTypeInputName}_w_unit`, withUnit)
      setFieldValue(`${listInputName}.${index}.${energyTypeInputName}_wo_unit`, withoutUnit)
      setFieldValue(`${listInputName}.${index}.is_electricity_selected`, isElectricitySelected)
    }
  }, [list])
}
