import { apiSlice } from "../apiSlice";
import { convertEmptyStringsToNull, convertIncomingPercentage, convertOutgoingPercentage } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { PackagingEntryDefaults, PackagingEntryData, PackagingEntryResponse, } from "../../../../pages/activityBuilder/modules/packaging/packagingTypes";
import { EntryCreatePayload, EntryDeletePayload, EntryUpdatePayload, ParentUpdatePayload, ParentResponse, ParentPayload } from "../../../../types/modulesInterfaces";

const parentEndpoint = "packagings/"
const entryEndpoint = "packaging-entries/"

const packagingApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPackagings: builder.query<ParentResponse, { activityId: number }>({
      query: ({ activityId }) => `${parentEndpoint}?activity=${activityId}`,
      transformResponse: (response: ParentResponse[]) => {
        return response[0]
      },
      providesTags: (_result, _err, { activityId }) => [{ type: 'Packagings', id: activityId }]
    }),
    updatePackagings: builder.mutation<ParentResponse, ParentUpdatePayload<ParentPayload>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `${parentEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'Packagings', id: activityId },
        { type: 'ModulesStatuses', id: activityId },
        { type: 'ModuleResults', id: body?.id ? `packagings-${body.id}` : undefined },
        { type: 'ModuleHistory', id: body?.id ? `packagings-${body.id}` : undefined },
      ]
    }),
    getPackagingEntriesDefaults: builder.query<PackagingEntryDefaults, number>({
      query: (id) => `${entryEndpoint}${id}/defaults/`,
      providesTags: (_result, _err, id) => [{ type: 'PackagingEntriesDefaults', id }]
    }),
    getPackagingEntries: builder.query<PackagingEntryData[], { activityId: number }>({
      query: ({ activityId }) => `${entryEndpoint}?activity=${activityId}`,
      transformResponse: (response: PackagingEntryData[]) => {
        response.forEach(entry => convertIncomingPercentage(entry, ["transmission_loss_t2"]));
        return response.sort((a, b) => a.id - b.id)
      },
      providesTags: (_result, _err, { activityId }) => [{ type: 'PackagingEntries', id: activityId }]
    }),
    updatePackagingEntry: builder.mutation<PackagingEntryResponse, EntryUpdatePayload<PackagingEntryResponse>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        convertOutgoingPercentage(rest, ["transmission_loss_t2"]);
        return { url: `${entryEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'PackagingEntries', id: activityId },
        { type: 'PackagingEntriesDefaults', id: body?.id ? body?.id : undefined },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    createPackagingEntry: builder.mutation<PackagingEntryResponse, EntryCreatePayload>({
      query: ({ activityId, parent }) => ({ url: `${entryEndpoint}`, method: 'POST', body: { activity: activityId, parent } }),
      invalidatesTags: (_result, _err, { activityId, parent }) => [
        { type: 'PackagingEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `packagings-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `packagings-${parent}` : undefined },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    deletePackagingEntry: builder.mutation<void, EntryDeletePayload>({
      query: ({ id }) => ({ url: `${entryEndpoint}${id}/`, method: 'DELETE' }),
      invalidatesTags: (_result, _err, { activityId, parent, id }) => [
        { type: 'PackagingEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `packagings-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `packagings-${parent}` : undefined },
        { type: 'PackagingEntriesDefaults', id },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
  })
});

export const { useGetPackagingsQuery, useUpdatePackagingsMutation, useGetPackagingEntriesQuery, useUpdatePackagingEntryMutation, useCreatePackagingEntryMutation, useDeletePackagingEntryMutation, useGetPackagingEntriesDefaultsQuery } = packagingApiSlice;