import { useCallback, useEffect, useMemo, useState } from "react";
import type {
	InputGroup,
	ModuleProps,
	Submodule,
} from "../../../../types/modulesInterfaces";
import { useAppDispatch, useAppSelector, usePermissions } from "../../../../app/hooks";
import { selectCurrentBuilder, setActiveAction } from "../../../../app/features/builder/builderSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { FieldArray, Form, useFormikContext } from "formik";
import type {
	ElectricityCreatePayload,
	FuelCreatePayload,
	FuelResponse,
	ModuleFull,
} from "./energyTypes";
import {
	useCreateElectricityMutation,
	useCreateFuelMutation,
	useDeleteElectricityMutation,
	useDeleteFuelMutation,
	useGetElectricityQuery,
	useGetFuelQuery,
} from "../../../../app/features/api/modules/energyApiSlice";
import { Actions } from "../../../../types/interfaces";
import {
	BEModulesObj,
	FEModules,
	moduleDescriptions,
} from "../../../../utils/moduleList";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { useGetFuelTypesQuery } from "../../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { NoteButton } from "../../../../components/tabsButtons/TabsButtons";
import { TranslatedAddAccordionButton } from "../../../../components/input/AddAccordionButton";
import { mergeLocalAndServerChanges, preventFormSubmit } from "../inputs/utils";
import { useEnergyContext } from "../../../../contexts/EnergyProvider";
import { handleDisableSww } from "../moduleUtils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import Spinner from "../../../../components/spinner/Spinner";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import TranslatableText from "../../../../components/translations/TranslatableText";
import ModuleFooter from "../ModuleFooter";
import { isEqual } from 'lodash';

interface EnergyProps {
	fuelSchema: Submodule;
	isFuelLoading: boolean;
	isElectricityLoading: boolean;
	electricitySchema: Submodule;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}

const MAXELECTRICITIES = 1;
const MAXFUELS = 10;

const EnergyTierOne = (
	props: Omit<ModuleProps, "moduleSchema" | "t2Schema"> & EnergyProps,
) => {
	const {
		electricitySchema,
		fuelSchema,
		isElectricityLoading,
		isFuelLoading,
		title,
		blockingError,
		footerError,
		note,
		beModuleType,
		isError,
		setInitValues,
	} = props;
	const moduleContext = useEnergyContext();
	const dispatch = useAppDispatch();
	const { activeAction, moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const [nextState, setNextState] = useState<boolean>(false);
	const { isReadOnly } = usePermissions();

	const {
		values,
		touched,
		dirty,
		isValid,
		validateForm,
		handleSubmit,
		isSubmitting,
		errors,
	} = useFormikContext<ModuleFull>();

	const feModuleType = BEModulesObj[beModuleType];
	const description = moduleDescriptions[feModuleType];
	const number = useMemo(() => {
		const currentModule = moduleList.find((step) => step.id === feModuleType);
		return currentModule?.uniqueId ?? "0.0";
	}, [moduleList, feModuleType]);


	const parentId = useMemo(
		() => moduleList.find((m) => m.id === FEModules.Energy)?.uniqueId,
		[moduleList],
	);

	//delete functions
	const [deleteElectricity, { isLoading: isElectricityDelLoading }] =
		useDeleteElectricityMutation();
	const [deleteFuel, { isLoading: isFuelDelLoading }] = useDeleteFuelMutation();

	const handleRemoveElectricity = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		if (id) {
			remove(index);
			deleteElectricity({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
		}
	};

	const handleRemoveFuel = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		if (id) {
			remove(index);
			deleteFuel({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
		}
	};

	const handleShowNote = () => {
		if (activeAction !== Actions.Notes) {
			dispatch(setActiveAction(Actions.Notes))
		}
	};

	//create functions
	const [addElectricity] = useCreateElectricityMutation();
	const [addFuel, { isLoading: isAddFuelLoading }] = useCreateFuelMutation();

	const handleAddElectricity = async () => {
		const payload: ElectricityCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			if (values.electricities.length < MAXELECTRICITIES)
				await addElectricity(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};
	const handleAddFuel = async () => {
		const payload: FuelCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			if (values.fuels.length < MAXFUELS) await addFuel(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	const navigate = useNavigate();
	const location = useLocation();
	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);

	const handleSaveAndProceed = async () => {
		await validateForm();
		if (isValid) {
			setNextState(true);
		} else {
			console.log("not valid");
		}
	};

	/* resetting the form is essetial to reset the "dirty" state that's used to control navigation */
	const handleNext = useCallback(async () => {
		// if (!isValid) return;
		const currentModulePath = location.pathname.split("/").pop();
		const currentIndex = moduleList.findIndex(
			(step) => step.path === currentModulePath,
		);
		const nextModulePath =
			currentIndex === moduleList.length - 1
				? "complete"
				: `${moduleList[currentIndex + 1].path}`;
		navigate(`/builder/${activeActivityId}/${nextModulePath}`);
	}, [location.pathname, moduleList, navigate, activeActivityId]);

	useEffect(() => {
		if (!nextState) return;
		if (
			Object.keys(touched).length === 0 &&
			!dirty &&
			!isError &&
			!isSubmitting
		) {
			setNextState(false);
			handleNext();
		}
	}, [nextState, touched, dirty, handleNext, isSubmitting, isError]);

	/* ADD SUBMODULES TO INITVALUES LOGIC */
	const { data: electricityData } = useGetElectricityQuery(
		activeActivityId ?? 0,
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { data: fuelData } = useGetFuelQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const { data: fuelTypes } = useGetFuelTypesQuery({ macroFuelTypeId: undefined }, {
		refetchOnMountOrArgChange: true,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: we can't add values to the dependecies because it would cause a loop
	const lists = useMemo(() => {
		const isElectricityUpdated = !isEqual(values.electricities, electricityData);
		const isFuelUpdated = !isEqual(values.fuels, fuelData);

		//macro type is not saved on the backend, so we enter it manually
		const mappedFuels = fuelData?.map((fuelEntry) => {
			if (fuelEntry.fuel_type && !fuelEntry.fuel_macro_type) {
				const completeFuelType = fuelTypes?.find(
					(type) => type.id === fuelEntry.fuel_type,
				);

				return completeFuelType
					? {
						...fuelEntry,
						fuel_macro_type: completeFuelType?.macro_fuel_type.id,
					}
					: fuelEntry;
			}
			return fuelEntry;
		});

		return {
			electricities: isElectricityUpdated
				? mergeLocalAndServerChanges({
					currentChangesList: values.electricities,
					incomingList: electricityData ?? [],
				})
				: values.electricities,
			fuels: isFuelUpdated
				? mergeLocalAndServerChanges({
					currentChangesList: values.fuels,
					incomingList: mappedFuels ?? [],
				})
				: values.fuels,
		};
	}, [electricityData, fuelData, fuelTypes]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (lists.electricities || lists.fuels) {
			setInitValues(() => ({
				...values,
				electricities: lists.electricities,
				fuels: lists.fuels,
			}));
		}
		if (moduleContext) {
			if (lists.electricities) moduleContext.setElectricityIds(lists.electricities.map(e => e.id) as number[]);
			if (lists.fuels) moduleContext.setFuelsIds(lists.fuels.map(f => f.id) as number[]);
		}
		// eslint-disable-next-line
	}, [lists, setInitValues]);

	return blockingError ? (
		<section>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey={title} />
					</h2>
					<div className="module-number header-number ff-light-ext">
						{number}
					</div>
				</div>

				<span className="error fs-13 pb-2">{blockingError}</span>
			</div>
		</section>
	) : (
		<section>
			<Form onKeyDown={preventFormSubmit}>
				<div className="module-group">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-14 ff-medium-ext module-header w-fit">
							<TranslatableText translationKey={title} />
						</h2>
						<div className="module-number header-number ff-light-ext">
							{number}
						</div>
					</div>

					<div className="pos-relative mb-3">
						<p className="fs-14">
							<TranslatableText translationKey={description} />
						</p>
						<div
							className="pos-absolute d-flex align-items-center h-100"
							style={{ top: 0, left: "100%" }}
						>
							{note ? <NoteButton active={note !== null} onClick={handleShowNote} /> : <></>}
						</div>
					</div>

					{/* Electricities */}
					{isElectricityLoading ? (
						<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
							<span className="text-white fs-12">Electricities loading...</span>
							<Spinner size={36} padding={8} />
						</div>
					) : (
						<FieldArray name="electricities">
							{({ remove }) => (
								<>
									{values.electricities.length > 0
										? values.electricities.map((entry, index) => (
											<TranslatedFormSubmodule
												key={`${entry?.id ?? "electricities"}-${index}`}
												submoduleName={
													electricitySchema?.name
												}
												removeHandler={() =>
													handleRemoveElectricity(
														() => remove(index),
														index,
														entry.id,
													)
												}
												removeLoading={isElectricityLoading}
												itemId={entry.id ?? 0}
												itemIndex={index + 1}
											>
												{electricitySchema?.inputGroups.map((input, i) => {
													const curatedInput = handleDisableSww({ input, isReadOnly });
													return (
														<div className="py-1" key={`${input.label}-${i}`}>
															<GenericInputGroup
																inputGroup={{ ...curatedInput, index, disabled: isReadOnly }}
															/>
														</div>
													)
												})}
											</TranslatedFormSubmodule>
										))
										: null}
								</>
							)}
						</FieldArray>
					)}

					<TranslatedAddAccordionButton
						label="module.add_new_electricity"
						clickHandler={handleAddElectricity}
						disabled={!(values.electricities.length < MAXELECTRICITIES) || isReadOnly || isAddFuelLoading || isSubmitting}
					/>

					{/* Fuels */}
					{isFuelLoading ? (
						<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
							<span className="text-white fs-12">Fuels loading...</span>
							<Spinner size={36} padding={8} />
						</div>
					) : (
						<FieldArray name="fuels">
							{({ remove }) => (
								<>
									{values.fuels.length > 0
										? values.fuels.map((entry, index) => {
											const handleRemove = () =>
												handleRemoveFuel(
													() => remove(index),
													index,
													entry.id,
												);

											return (
												<Fuel
													key={`${entry?.id ?? "fuels"}-${index}`}
													entry={entry}
													index={index}
													fuelSchema={fuelSchema}
													handleRemove={handleRemove}
													isLoading={isFuelLoading}
													isReadOnly={isReadOnly}
												/>
											);
										})
										: null}
								</>
							)}
						</FieldArray>
					)}

					<TranslatedAddAccordionButton
						label="module.add_new_fuel"
						clickHandler={handleAddFuel}
						disabled={!(values.fuels.length < MAXFUELS) || isReadOnly}
					/>
				</div>

				<ModuleFooter
					footerError={footerError}
					errors={errors}
					touched={touched}
					isLoading={isElectricityDelLoading || isFuelDelLoading}
					isDisabled={isElectricityDelLoading || isFuelDelLoading || isReadOnly}
					handleSaveAndProceed={handleSaveAndProceed}
					handleNext={handleNext}
					shouldBlock={shouldBlock}
				/>
			</Form>
		</section>
	);
};

export default EnergyTierOne;

const Fuel = ({
	entry,
	index,
	fuelSchema,
	handleRemove,
	isLoading,
	isReadOnly = false,
}: {
	entry: FuelResponse;
	index: number;
	fuelSchema: Submodule;
	handleRemove: () => void;
	isLoading: boolean;
	isReadOnly?: boolean;
}) => {
	const [macroFuelType, setMacroFuelType] = useState<number | null | undefined>(
		null,
	);
	const [fuelType, setFuelType] = useState<number | null>(null);
	const unit = useMemo(
		() => (macroFuelType === 1 ? "[m³/yr]" : "[t.d.m./yr]"),
		[macroFuelType],
	);

	const { setFieldValue } = useFormikContext<ModuleFull>();
	const { data: fuelTypesAll } = useGetFuelTypesQuery({ macroFuelTypeId: undefined });
	const { data: fuelTypes, isLoading: isFuelTypesLoading } =
		useGetFuelTypesQuery({ macroFuelTypeId: macroFuelType ?? undefined }, {
			refetchOnMountOrArgChange: true,
		});

	useEffect(() => {
		if (entry.fuel_type) setFuelType(entry.fuel_type);
		if (entry?.fuel_macro_type) setMacroFuelType(entry.fuel_macro_type);
		else {
			const fuelType = fuelTypesAll?.find(
				(type) => type.id === entry.fuel_type,
			);
			if (fuelType)
				setFieldValue(
					`fuels.${index}.fuel_macro_type`,
					fuelType.macro_fuel_type.id,
				);
		}
	}, [entry, fuelTypesAll, index, setFieldValue]);

	/* if we set a new macro, and the current fuel type is not of that macro type we set it to null */
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (fuelType && macroFuelType) {
			const completeFuelType = fuelTypesAll?.find(
				(type) => type.id === fuelType,
			);
			if (
				completeFuelType &&
				completeFuelType.macro_fuel_type.id !== macroFuelType
			)
				setFieldValue(`fuels.${index}.fuel_type`, null);
		}
	}, [macroFuelType]);

	return (
		<TranslatedFormSubmodule
			key={index}
			submoduleName={fuelSchema?.name}
			removeHandler={handleRemove}
			removeLoading={isLoading}
			itemId={entry.id ?? 0}
			itemIndex={index + 1}
		>
			{fuelSchema?.inputGroups.map((input, i) => {
				const isFuelTypeInput = input.label === "module.type_of_fuel_required"; //not so great i know
				const isFuelConsumptionInput = input.label === "module.fuel_consumption_required"; //not so great i know
				const curatedInput = handleDisableSww({ input, isReadOnly, index });
				return (
					<div className="py-1" key={`${input.label}-${i}`}>
						<GenericInputGroup
							inputGroup={
								isFuelTypeInput
									? ({
										...input,
										index,
										dropdownOptions: isFuelTypesLoading ? [] : fuelTypes,
										disabled: isReadOnly,
									} as InputGroup)
									: {
										...curatedInput,
										index,
										unit: isFuelConsumptionInput ? unit : input.unit,
										disabled: isReadOnly,
									}
							}
						/>
					</div>
				);
			})}
		</TranslatedFormSubmodule>
	);
};
