import { Field, getIn, useFormikContext } from "formik";
import { EmissionsByGasTierTwo } from "../../../types/modulesInterfaces";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import { TierTwoIcon } from "../../../utils/customIcons";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import TranslatableText from "../../translations/TranslatableText";
import SubModuleInputGroup from "../SubModuleInputGroup";
import FormRow from "../formRow/FormRow";
import CustomInput from "../CustomInput";

export interface GasEmissions {
  co2: string | number | boolean;
  ch4: string | number | boolean;
  n2o: string | number | boolean;
}

const TierTwoEmissionsByGas = ({ inputName, label, links, unit, index, autosubmit, isLiquidOrGas }: EmissionsByGasTierTwo) => {
  const { values, errors, touched } = useFormikContext();
  const [rowValues, setRowValues] = useState<GasEmissions | undefined>(undefined);
  const [defaultValues, setDefaultValues] = useState<GasEmissions | undefined>(undefined);

  const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const rowChanged = rowValues?.co2 || rowValues?.ch4 || rowValues?.n2o;
  const translatedUnit = useCustomTranslation(unit);

  const units = isLiquidOrGas ? "[tCO2/m³]" : "[tCO2-e/t.d.m.]"

  useEffect(() => {
    setName(
      typeof inputName === "function" ? inputName(index ?? 0) : inputName,
    );

    return () => {
      setName("");
    };
  }, [inputName, index]);
  useEffect(() => {
    const errorPath = [`${name}_co2_t2`, `${name}_ch4_t2`, `${name}_n2o_t2`].find(
      (inputName) => {
        const errorMessage = getIn(errors, inputName);
        const wasTouched = getIn(touched, inputName);
        return errorMessage?.length && wasTouched;
      },
    );
    if (errorPath) setError(getIn(errors, errorPath));

    return () => setError(undefined);
  }, [name, errors, touched]);

  useEffect(() => {
    setRowValues({
      co2: getIn(values, `${name}_co2_t2`),
      ch4: getIn(values, `${name}_ch4_t2`),
      n2o: getIn(values, `${name}_n2o_t2`),
    });
  }, [values, name]);

  useEffect(() => {
    const co2 = getIn(values, `${name}_co2_t2_default`);
    const ch4 = getIn(values, `${name}_ch4_t2_default`);
    const n2o = getIn(values, `${name}_n2o_t2_default`);
    setDefaultValues({
      co2: co2?.toFixed(2) ?? "",
      ch4: ch4?.toFixed(2) ?? "",
      n2o: n2o?.toFixed(2) ?? "",
    });
  }, [values, name]);
  return (
    <TierTwoRowTooltip links={links} label={label}>
      <div
        className={`tiertwo-row ${links?.length ? "tiertwo-row-hover" : ""
          }`}
      >
        <TierTwoIcon fill={links?.length ? "active" : undefined} />
        <div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
          {rowChanged ? <div className="has-changed-dot" /> : null}
          <TranslatableText className="fs-12" translationKey={label} />
          <span className="fs-10 op-5">
            {translatedUnit}
          </span>
        </div>
        <SubModuleInputGroup
          error={error}
          name=""
          gridTemplateColumns="1fr 1fr 1fr"
          gap="8px"
        >
          <FormRow
            style={{ gridColumn: "1" }}
            topLabel={`Default = ${defaultValues?.co2}`}
            isFetching={isFetchingData}
          >
            <Field
              name={`${name}_co2_t2`}
              id={`${name}_co2_t2`}
              component={CustomInput}
              label={units}
              type="number"
              textend
              classes={rowValues?.co2 ? "tiertwo-input-changed" : ""}
              autosubmit={autosubmit}
            />
          </FormRow>
          <FormRow
            style={{ gridColumn: "2" }}
            topLabel={`Default = ${defaultValues?.ch4}`}
            isFetching={isFetchingData}
          >
            <Field
              name={`${name}_ch4_t2`}
              id={`${name}_ch4_t2`}
              component={CustomInput}
              label={units}
              type="number"
              textend
              classes={rowValues?.ch4 ? "tiertwo-input-changed" : ""}
              autosubmit={autosubmit}
            />
          </FormRow>
          <FormRow
            style={{ gridColumn: "3" }}
            topLabel={`Default = ${defaultValues?.n2o}`}
            isFetching={isFetchingData}
          >
            <Field
              name={`${name}_n2o_t2`}
              id={`${name}_n2o_t2`}
              component={CustomInput}
              label={units}
              type="number"
              textend
              classes={rowValues?.n2o ? "tiertwo-input-changed" : ""}
              autosubmit={autosubmit}
            />
          </FormRow>
        </SubModuleInputGroup>
      </div>
    </TierTwoRowTooltip>
  );
};

export default TierTwoEmissionsByGas;