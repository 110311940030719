import type {
	InputEntryResponse,
	InputEntryData,
	InputEntryDefaults,
} from "../../../../pages/activityBuilder/modules/inputs/inputsTypes";
import { convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { EntryCreatePayload, EntryDeletePayload, EntryUpdatePayload, ParentPayload, ParentResponse, ParentUpdatePayload } from "../../../../types/modulesInterfaces";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getInputs: builder.query<ParentResponse, { activityId: number }>({
			query: ({ activityId }) => `inputs/?activity=${activityId}`,
			transformResponse: (response: ParentResponse[]) => {
				return response[0]
			},
			providesTags: (_results, _error, { activityId }) => [{ type: 'Inputs', id: activityId }]
		}),
		updateInputs: builder.mutation<ParentResponse, ParentUpdatePayload<ParentPayload>>({
			query: ({ body }) => {
				const { id, ...rest } = body
				convertEmptyStringsToNull(rest)
				return { url: `inputs/${id}/`, method: 'PUT', body: rest }
			},
			invalidatesTags: (_results, _error, { activityId, body }) => [
				{ type: 'Inputs', id: activityId },
				{ type: "ModulesStatuses", id: activityId },
				{ type: "ModuleResults", id: body?.id ? `inputs-${body.id}` : undefined },
				{ type: "ModuleHistory", id: body?.id ? `inputs-${body.id}` : undefined }]
		}),
		getInputEntries: builder.query<InputEntryData[], { activityId: number }>({
			query: ({ activityId }) => `input-entries/?activity=${activityId}`,
			transformResponse: (response: InputEntryData[]) => response.sort((a, b) => a.id - b.id),
			providesTags: (_result, _error, { activityId }) => [
				{
					type: "InputEntries",
					id: activityId,
				},
			],
		}),
		updateInputEntry: builder.mutation<
			InputEntryResponse,
			EntryUpdatePayload<InputEntryResponse>
		>({
			query: ({ body }) => {
				const { id, ...rest } = body;
				convertEmptyStringsToNull(rest)
				return {
					url: `input-entries/${id}/`,
					method: "PUT",
					body: rest,
				};
			},
			invalidatesTags: (_results, _error, { activityId, body }) => [
				{ type: "InputEntries", id: activityId },
				{ type: "InputEntriesDefaults", id: body?.id ? body?.id : undefined },
			],
		}),
		createInputEntry: builder.mutation<
			InputEntryResponse,
			EntryCreatePayload
		>({
			query: ({ activityId, parent }) => ({ url: "input-entries/", method: 'POST', body: { activity: activityId, parent } }),
			invalidatesTags: (_results, _error, { activityId, parent }) => [
				{ type: "InputEntries", id: activityId },
				{ type: "ModuleResults", id: parent ? `inputs-${parent}` : undefined },
				{ type: "ModuleHistory", id: parent ? `inputs-${parent}` : undefined },
				{ type: "ModulesStatuses", id: activityId },
			],
		}),
		deleteInputEntry: builder.mutation<void, EntryDeletePayload>({
			query: ({ id }) => ({ url: `input-entries/${id}/`, method: 'DELETE' }),
			invalidatesTags: (_results, _error, { activityId, parent, id }) => [
				{ type: "InputEntries", id: activityId },
				{ type: "ModuleResults", id: parent ? `inputs-${parent}` : undefined },
				{ type: "ModuleHistory", id: parent ? `inputs-${parent}` : undefined },
				{ type: "InputEntriesDefaults", id },
				{ type: "ModulesStatuses", id: activityId },
			],
		}),
		getInputEntriesDefaults: builder.query<InputEntryDefaults, number>({
			query: (id) => `input-entries/${id}/defaults/`,
			providesTags: (_results, _error, id) => [{ type: "InputEntriesDefaults", id }],
		}),
	}),
});

export const {
	useGetInputsQuery,
	useUpdateInputsMutation,
	useGetInputEntriesQuery,
	useCreateInputEntryMutation,
	useUpdateInputEntryMutation,
	useDeleteInputEntryMutation,
	useGetInputEntriesDefaultsQuery
} = moduleApiSlice;
