import { useMemo } from "react";
import { useGetInputTypesQuery } from "../../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { InputEntryData } from "./inputsTypes";
import { roundToDecimals } from "./utils";
import { TierTwoIcon } from "../../../../utils/customIcons";
import { checkNullorUndefined } from "./utils";
import { selectIsFetchingData } from "../../../../app/features/builder/builderSlice";
import { useAppSelector } from "../../../../app/hooks";
import { Field } from "formik";
import SubModuleInputGroup from "../../../../components/input/SubModuleInputGroup";
import FormRow from "../../../../components/input/formRow/FormRow";
import CustomInput from "../../../../components/input/CustomInput";

const CustomInputsTierTwo = ({
  entry,
  index,
  disabled
}: {
  entry: InputEntryData;
  index: number;
  disabled?: boolean
}) => {
  const isFetchingData = useAppSelector(selectIsFetchingData);
  const { data: inputTypes } = useGetInputTypesQuery();
  const inputType = useMemo(() => inputTypes?.find(input => input.id === entry.input_type), [inputTypes, entry.input_type]);
  const rowChanged = useMemo<boolean>(
    () =>
      !!entry.co2_e_emissions_t2 ||
      !!entry.n2o_emissions_t2 ||
      !!entry.co2_emissions_t2,
    [entry],
  );

  return (
    <div className="tiertwo-row">
      <TierTwoIcon />
      <div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
        {rowChanged ? <div className="has-changed-dot" /> : null}
        <span className="fs-12">{inputType ? inputType.name : "Please, select Input Type"}</span>
      </div>
      <SubModuleInputGroup
        name=""
        gridTemplateColumns="1fr 1fr 1fr"
        gap="8px"
      >
        <FormRow
          style={{ gridColumn: "1" }}
          topLabel={!checkNullorUndefined(entry.co2_emissions_t2_default) ? [
            `Default = ${roundToDecimals(entry.co2_emissions_t2_default as number, 3)}`,
            "CO2 Emissions",
          ] : undefined}
          isFetching={isFetchingData}
        >
          <Field
            name={`entries.${index}.co2_emissions_t2`}
            id={`entries.${index}.co2_emissions_t2`}
            component={CustomInput}
            label={inputType?.has_co2_emissions ? "[unit]" : "/"}
            disabled={!inputType?.has_co2_emissions || disabled}
            type="number"
            textend
            classes={entry.co2_emissions_t2 ? "tiertwo-input-changed" : ""}
          />
        </FormRow>
        <FormRow
          style={{ gridColumn: "2" }}
          topLabel={!checkNullorUndefined(entry.n2o_emissions_t2_default) ? [
            `Default = ${roundToDecimals(entry.n2o_emissions_t2_default as number, 3)}`,
            "N2O Emissions",
          ] : undefined}
          isFetching={isFetchingData}
        >
          <Field
            name={`entries.${index}.n2o_emissions_t2`}
            id={`entries.${index}.n2o_emissions_t2`}
            component={CustomInput}
            label={inputType?.has_n2o_emissions ? "[unit]" : "/"}
            disabled={!inputType?.has_n2o_emissions || disabled}
            type="number"
            textend
            classes={entry.n2o_emissions_t2 ? "tiertwo-input-changed" : ""}
          />
        </FormRow>
        <FormRow
          style={{ gridColumn: "3" }}
          topLabel={!checkNullorUndefined(entry.co2_e_emissions_t2_default) ? [
            `Default = ${roundToDecimals(entry.co2_e_emissions_t2_default as number, 3)}`,
            "CO2-e Emissions",
          ] : undefined}
          isFetching={isFetchingData}
        >
          <Field
            name={`entries.${index}.co2_e_emissions_t2`}
            id={`entries.${index}.co2_e_emissions_t2`}
            component={CustomInput}
            label={inputType?.has_co2_e_emissions ? "[unit]" : "/"}
            disabled={!inputType?.has_co2_e_emissions || disabled}
            type="number"
            textend
            classes={entry.co2_e_emissions_t2 ? "tiertwo-input-changed" : ""}
          />
        </FormRow>
      </SubModuleInputGroup>
    </div>
  );
};

export default CustomInputsTierTwo;