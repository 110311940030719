import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";
import {
	initialValues,
	validationSchema,
	t2InitialValues,
} from "./perennialCropland";
import {
	useAppSelector,
	useSyncNotesWithStore,
	useThreadsProcessor,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/beModules";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetPerennialCroplandsDefaultsQuery,
	useGetPerennialCroplandsQuery,
	useUpdatePerennialCroplandsMutation,
} from "../../../../app/features/api/modules/perennialCroplandApiSlice";
import type { ModuleFull } from "./perennialCroplandTypes";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import usePerennialCroplandSchema from "./usePerennialCroplandSchema";
import ModuleContext from "../../ModuleContext";

const PerennialCropland = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { belongsToLuc } = useContext(ModuleContext);
	const {
		data: perennialCroplandsData,
		isLoading,
		isError,
		error,
	} = useGetPerennialCroplandsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updatePerennialCroplands,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdatePerennialCroplandsMutation();

	const { data: defaults, error: defaultsError, isError: isDefaultsError, isLoading: isLoadingDefaults } = useGetPerennialCroplandsDefaultsQuery(
		perennialCroplandsData?.module.id ?? 0,
		{ skip: !perennialCroplandsData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });

	const { moduleSchema, t2Schema } = usePerennialCroplandSchema();
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});
	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

	const { notes } = useSyncNotesWithStore({
		notes: perennialCroplandsData?.module.note ?? null,
	});

	useEffect(() => {
		if (perennialCroplandsData) {
			setInitValues({
				module: perennialCroplandsData.module,
				tiertwo: { ...perennialCroplandsData.tiertwo, ...defaults },
			});

			processModuleThreads({ module: perennialCroplandsData.module });
		}

	}, [perennialCroplandsData, defaults, processModuleThreads]);

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const payload = {
			data: { ...values },
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updatePerennialCroplands(payload).unwrap());
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema(belongsToLuc)}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.perennial_cropland"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					isLoading={isLoadingUpdate}
					beModuleType={BEModules.PerennialCropland}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="project.perennial_cropland"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg ?? null}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default PerennialCropland;
