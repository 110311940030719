import { useEffect, useState } from "react";
import { usePermissions } from "../../../../app/hooks";
import { EntryComponentProps } from "../GenericOptionalTierTwo";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import { TierTwoBlock } from "../GenericTierTwo";
import { useGetInputEntriesDefaultsQuery } from "../../../../app/features/api/modules/inputsApiSlice";
import { InputEntryData } from "./inputsTypes";
import { useFormikContext } from "formik";
import { isEqual } from "lodash";
import useErrorMessage from "../../../../utils/useErrorMessage";
import CustomInputsTierTwo from "./CustomInputsTierTwo";

interface InputEntryT2Props extends EntryComponentProps<InputEntryData> { }

const InputEntryT2 = ({ entry, index, onDefaultsError }: InputEntryT2Props) => {
  const { isReadOnly } = usePermissions();

  const { data: defaults, isError, error, refetch } = useGetInputEntriesDefaultsQuery(entry.id, { skip: !entry.id });
  const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });
  useEffect(() => {
    if (defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);
  }, [defaultsErrorMsg, onDefaultsError])

  const { setFieldValue } = useFormikContext<{ inputs: InputEntryData[] }>();
  const [entryWithDefaults, setEntryWithDefaults] = useState<InputEntryData | null>(null);
  useEffect(() => {
    const entryWithDefaults = defaults ? { ...entry, ...defaults } : null
    if (!isEqual(entryWithDefaults, entry))
      setEntryWithDefaults(entryWithDefaults);
  }, [defaults, entry]);

  useEffect(() => {
    if (entryWithDefaults)
      setFieldValue(`entries.${index}`, entryWithDefaults);
  }, [entryWithDefaults, index, setFieldValue]);

  useEffect(() => {
    if (entry.input_type) refetch();
  }, [entry.input_type, refetch])

  return (
    <TranslatedFormSubmodule
      key={entry.id}
      submoduleName="project.inputs"
      itemId={entry.id}
      itemIndex={index + 1}
      className="pt-2"
    >
      <TierTwoBlock>
        <CustomInputsTierTwo entry={entry} index={index} disabled={isReadOnly} />
      </TierTwoBlock>
    </TranslatedFormSubmodule>
  )
}


export default InputEntryT2;
