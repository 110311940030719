import React, { useCallback, useMemo } from 'react'
import type {
	HectaresInputGroup,
	InputGroup,
	ModuleSchema,
	T2Schema,
	StartWithWithoutGeneric,
	Submodule,
	StartWithWithoutTierTwo,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BEModules } from "../../../../utils/beModules";
import { useAppSelector, useFetchLucTypes } from '../../../../app/hooks';
import { selectCurrentProject } from '../../../../app/features/project/projectSlice';
import { fetchLandUseTypes } from '../moduleUtils';

const usePerennialCroplandSchema = () => {
	// const { activity,project } = useAppSelector(selectCurrentProject);

	const tillageTypes = () => getOptions("tillage");
	const organicInputTypes = () => getOptions("organicInput");
	const { landUseTypes } = useFetchLucTypes({beModuleId: BEModules.PerennialCropland})
	const getInitInputGroups: () => InputGroup[] = () => {
		const hectaresInputGroup: HectaresInputGroup = {
			type: FieldType.HECTARES,
			label: "module.hectares",
			unit: "[ha]",
			inputName: "module.area",
			disabled: true,
		};
		return [hectaresInputGroup];
	};
	const getMandatorySubmodules: () => Submodule[] = () => {
		const agroforestrySystem: StartWithWithoutGeneric = {
			type: FieldType.SWW_SELECT,
			label: "module.agroforestry_system_required",
			inputName: "module.land_use_type",
			startProps: { dropdownOptions: landUseTypes },
			withProps: { dropdownOptions: landUseTypes },
			withoutProps: { dropdownOptions: landUseTypes },
			thread: "module.land_use_type_thread",
		};
		const tillageManagement: StartWithWithoutGeneric = {
			type: FieldType.SWW_SELECT,
			label: "module.tillage_management_required",
			inputName: "module.tillage_management_type",
			startProps: { dropdownOptions: tillageTypes },
			withProps: { dropdownOptions: tillageTypes },
			withoutProps: { dropdownOptions: tillageTypes },
			thread: "module.tillage_management_type_thread",
		};
		const inputOfOrganicMaterial: StartWithWithoutGeneric = {
			type: FieldType.SWW_SELECT,
			label: "module.input_of_organic_material_required",
			inputName: "module.organic_input_type",
			startProps: { dropdownOptions: organicInputTypes },
			withProps: { dropdownOptions: organicInputTypes },
			withoutProps: { dropdownOptions: organicInputTypes },
			thread: "module.organic_input_type_thread",
		};
		const residueBiomassAvailable: StartWithWithoutGeneric = {
			type: FieldType.SWW_BOOL,
			label: "module.residue_biomass_burning",
			inputName: "module.is_biomass_burned",
			thread: "module.is_biomass_burned_thread",
		};
		const yieldInputGroup: StartWithWithoutGeneric = {
			type: FieldType.SWW,
			label: "module.yield",
			unit: "[t/ha/yr]",
			inputName: "module.crop_yield_t2",
			thread: "module.crop_yield_t2_thread",
		};

		return [
			{
				name: "module.main_season",
				inputGroups: [
					agroforestrySystem,
					tillageManagement,
					inputOfOrganicMaterial,
					residueBiomassAvailable,
					yieldInputGroup,
				],
			},
		];
	};
	/* THEY'LL NEED TO BE DEVELOPED SORT OF LIKE MINOR SEASONS IN FLOODED RICE */
	/* const getOptionalSubmodules: () => OptionalSubmodule<MinorSeason>[] = () => {
		const minorSeasonCrop: StartWithWithoutGeneric = {
			type: FieldType.SWW_SELECT,
			label: "Minor season Crop",
			inputName: "module.minorSeason.minor_land_use_type",
			startProps: { dropdownOptions: landUseTypes },
			withProps: { dropdownOptions: landUseTypes },
			withoutProps: { dropdownOptions: landUseTypes },
			help: { type: "info", onClick: () => { } },
		};
		const residueManagement: StartWithWithoutGeneric = {
			type: FieldType.SWW_SELECT,
			label: "Residue Management",
			inputName: "module.minorSeason.minor_residue_management_type",
			startProps: { dropdownOptions: residueTypes },
			withProps: { dropdownOptions: residueTypes },
			withoutProps: { dropdownOptions: residueTypes },
			thread: "module.minorSeason.minor_residue_management_type_thread",
		};
		const yieldManagement: StartWithWithoutGeneric = {
			type: FieldType.SWW,
			label: "Yield",
			unit: "[t/ha/yr]",
			inputName: "module.minorSeason.minor_yield",
			thread: "module.minorSeason.minor_yield_thread",
		};
	
		return [
			{
				name: "Minor Season (Optional)",
				inputName: "module.minorSeason",
				inputGroups: [minorSeasonCrop, residueManagement, yieldManagement],
				linkedTierTwoInputs: ["tiertwo.minorSeasons"], //posso rimuovere?
			},
		];
	}; */

	const getT2Submodules: () => Submodule[] = () => {
		const rows: StartWithWithoutTierTwo[] = [
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.soc_t2",
				label: "module.soil_carbon",
				unit: "[tC/ha]",
				links: [],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.flu_t2",
				label: "module.land_use_factor",
				unit: "[#]",
				links: [],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.fmg_t2",
				label: "module.tillage_factor",
				unit: "[#]",
				links: ["Tillage Management"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.fi_t2",
				label: "module.input_factor",
				unit: "[unit]",
				links: ["Input of Organic Material"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.agb_t2",
				label: "module.above_ground_biomass_growth",
				unit: "[tC/ha/yr]",
				links: ["Agroforestry System"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.agb_max_t2",
				label: "module.maximum_agb_in_the_system",
				unit: "[tC/ha/yr]",
				links: ["Agroforestry System"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.bgb_t2",
				label: "module.below_ground_biomass_growth",
				unit: "[tC/ha/yr]",
				links: ["Agroforestry System"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.residue_availability_t2",
				label: "module.residue_biomass_available",
				unit: "[tDm/ha]",
				links: ["Agroforestry System"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
			{
				type: FieldType.SWW_T2,
				inputName: "tiertwo.fire_periodicity_t2",
				label: "module.fire_periodicity",
				unit: "[#/yr]",
				links: ["Residues / Biomass Burning"],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
			},
		];

		return [
			{
				name: "module.main_season",
				inputGroups: [...rows],
			},
		];
	};

	/* const getT2OptionalSubmodules: () => OptionalSubmodule<MinorSeasonT2>[] =
		() => {
			const minorBiomassAvailable: StartWithWithoutTierTwo = {
				type: FieldType.SWW_T2,
				inputName: "tiertwo.minorSeason.minor_biomass_factor_t2",
				label: "Residue/ Biomass Avail.",
				links: [],
				startProps: { inputType: "number", defaultValue: 0 },
				withoutProps: { inputType: "number", defaultValue: 0 },
				withProps: { inputType: "number", defaultValue: 0 },
				unit: "[unit]",
			};
	
			return [
				{
					name: "Minor Season",
					inputName: "tiertwo.minorSeason",
					inputGroups: [minorBiomassAvailable],
				},
			];
		}; */

	const getSchemas = () => {
		const moduleSchema: ModuleSchema = {
			initInputGroups: getInitInputGroups(),
			mandatorySubmodules: getMandatorySubmodules(),
		};
		const t2Schema: T2Schema = {
			submodules: getT2Submodules(),
		};

		return { moduleSchema, t2Schema };
	};

	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	return { moduleSchema, t2Schema };
}

export default usePerennialCroplandSchema