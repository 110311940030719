import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
	CartesianGrid,
} from "recharts";
import { getGasColor } from "./graphsUtils";
import { useGetProjectResultsByGasQuery } from "../../app/features/project/projectApiSlice";
import { useMemo } from "react";
import { calculateEmissionByProjectStatus } from "../../pages/project/results/resultsUtils";
import GraphContainer from "./GraphContainer";
import Skeleton from "react-loading-skeleton";
import { NumericObject, roundPropertiesToTwoDecimals } from "./utils";
import TranslatableText from "../translations/TranslatableText";
import CustomLegend from "./CustomLegend";
import CustomTooltip from "./CustomTooltip";

const BalanceByStatus = ({
	width,
	height,
	className,
	projectId,
}: {
	width?: string | number;
	height: string | number;
	className?: string;
	projectId: number;
}) => {
	const { data: customRes, isLoading } = useGetProjectResultsByGasQuery(
		projectId,
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: (result) => ({
				...result,
				data: calculateEmissionByProjectStatus(result.data),
			}),
		},
	);

	const data = useMemo(
		() => [
			{
				name: "With",
				...roundPropertiesToTwoDecimals(customRes?.total_w as unknown as NumericObject),
				balance: 0,
			},
			{
				name: "Without",
				...roundPropertiesToTwoDecimals(customRes?.total_wo as unknown as NumericObject),
				balance: 0,
			},
			{
				name: "Balance",
				...roundPropertiesToTwoDecimals(customRes?.balance as unknown as NumericObject),
				balance: 0,
			},
		],
		[customRes],
	);

	return (
		<>
			<div
				className=" w-fit"
				style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
			>
				<h2 className="ff-bold fs-13 text-black">
					<TranslatableText translationKey="main.carbon_balance_by_specific_ghg_grouped_by_status"/>
				</h2>
			</div>
			<GraphContainer height={height}>
				{isLoading ? (
					<Skeleton width={width ?? "97%"} height={height} />
				) : (
					<ResponsiveContainer
						width={width ?? "97%"}
						height={height}
						className={className}
						style={{ paddingBottom: height }}
					>
						<BarChart
							data={data}
							stackOffset="sign"
							margin={{
								top: 5,
								right: 10,
								left: 15,
								bottom: 5,
							}}
						>
							<CartesianGrid stroke="#f5f5f5" />
							<XAxis dataKey="name" width={10} style={{ fontSize: "12px" }} />
							<YAxis width={25} style={{ fontSize: "8px" }} />
							<Tooltip content={<CustomTooltip />}/>
							<Legend content={<CustomLegend />} />
							<ReferenceLine y={0} stroke="#000" />
							<Bar
								dataKey="CH4"
								fill={getGasColor({ name: "CH4" })}
								stackId="stack"
							/>
							<Bar
								dataKey="N2O"
								fill={getGasColor({ name: "N2O" })}
								stackId="stack"
							/>
							<Bar
								dataKey="CO2"
								fill={getGasColor({ name: "CO2" })}
								stackId="stack"
							/>
							{/* TODO: add dot (maybe with <Customized /> or maybe with Dot) */}
						</BarChart>
					</ResponsiveContainer>
				)}
			</GraphContainer>
		</>
	);
};

export default BalanceByStatus;
