import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { ModuleProps, T2Schema } from "../../../../types/modulesInterfaces";
import { ModuleFull, InputEntryResponse } from "../inputs/inputsTypes";
import { useCreateInputEntryMutation, useDeleteInputEntryMutation, useGetInputEntriesQuery } from "../../../../app/features/api/modules/inputsApiSlice";
import { FEModules } from "../../../../utils/moduleList";
import { useMemo, SetStateAction, useEffect } from "react";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { useFormikContext } from "formik";
import { isEqual } from 'lodash';
import { mergeLocalAndServerChanges } from "../inputs/utils";
import GenericModule from "../GenericModule";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";

interface InputsTierOneProps {
	initValues: ModuleFull;
	isEntriesLoading: boolean;
	t2Schema: T2Schema;
	setInitValues: (value: SetStateAction<ModuleFull>) => void
}

const InputsTierOne = ({
	initValues,
	isEntriesLoading,
	setInitValues,
	...moduleProps
}: ModuleProps & InputsTierOneProps) => {
	const { values } = useFormikContext<ModuleFull>()
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { isReadOnly } = usePermissions()
	const { moduleList } = useAppSelector(selectCurrentBuilder);

	const parentId = useMemo(() => moduleList.find((m) => m.id === FEModules.Inputs)?.uniqueId, [moduleList]);
	const [deleteInputEntry, { isLoading: isLoadingDeleteInputEntry }] = useDeleteInputEntryMutation()
	const [createInputEntry, { isLoading: isLoadingCreateInputEntry }] = useCreateInputEntryMutation()

	const { data: entriesData } = useGetInputEntriesQuery({ activityId: activeActivityId ?? 0 });
	const isEntriesUpdated = !isEqual(values.entries, entriesData)
	const list = useMemo(() => {
		return isEntriesUpdated ? entriesData : values.entries
	}, [isEntriesUpdated, entriesData, values.entries])

	useEffect(() => {
		if (list)
			setInitValues((cur) => ({ ...cur, entries: list }))
	}, [list])

	const handleDeleteInput = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,) => {
		remove(index)
		if (id) deleteInputEntry({ id, activityId: activeActivityId ?? 0, parent: parentId })
	}
	const handleCreateInput = () => {
		createInputEntry({ activityId: activeActivityId ?? 0, parent: parentId })
	}


	return (
		<GenericModule {...moduleProps} disableOptionals>
			{
				() => (
					<>
						{moduleProps.moduleSchema.optionalSubmodules?.map((subModule, index) => {
							return (
								<GenericOptionalSubModule<InputEntryResponse>
									key={`${subModule.name}-${index}`}
									optionalSchema={subModule}
									values={values?.entries}
									addLabel="module.add_new_input"
									createHandler={handleCreateInput}
									removeHandler={handleDeleteInput}
									fieldArrayName={subModule.name}
									isOptionalLoading={isEntriesLoading || isLoadingCreateInputEntry || isLoadingDeleteInputEntry}
									isCreateLoading={isLoadingCreateInputEntry}
									isDeleteLoading={isLoadingDeleteInputEntry}
									isReadOnly={isReadOnly}
								/>
							)
						})}
					</>
				)
			}
		</GenericModule>
	)
};

export default InputsTierOne;
