import { convertEmptyStringsToNull, convertIncomingPercentage, convertOutgoingPercentage } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { StorageEntryDefaults, StorageEntryData, StorageEntryResponse, } from "../../../../pages/activityBuilder/modules/storage/storageTypes";
import { EntryCreatePayload, EntryDeletePayload, EntryUpdatePayload, ParentUpdatePayload, ParentResponse, ParentPayload } from "../../../../types/modulesInterfaces";
import { apiSlice } from "../apiSlice";

const storageApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getStorages: builder.query<ParentResponse, { activityId: number }>({
      query: ({ activityId }) => `storages/?activity=${activityId}`,
      transformResponse: (response: ParentResponse[]) => {
        return response[0]
      },
      providesTags: (_result, _err, { activityId }) => [
        { type: 'Storages', id: activityId }
      ]
    }),
    updateStorages: builder.mutation<ParentResponse, ParentUpdatePayload<ParentPayload>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `storages/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'Storages', id: activityId },
        { type: 'ModulesStatuses', id: activityId },
        { type: 'ModuleResults', id: body?.id ? `storages-${body.id}` : undefined },
        { type: 'ModuleHistory', id: body?.id ? `storages-${body.id}` : undefined },
      ]
    }),
    getStorageEntries: builder.query<StorageEntryData[], { activityId: number }>({
      query: ({ activityId }) => `storage-entries/?activity=${activityId}`,
      transformResponse: (response: StorageEntryData[]) => {
        response.forEach(entry => convertIncomingPercentage(entry, ["transmission_loss_t2"]));
        return response.sort((a, b) => a.id - b.id)
      },
      providesTags: (_result, _err, { activityId }) => [
        { type: 'StorageEntries', id: activityId }
      ]
    }),
    updateStorageEntry: builder.mutation<StorageEntryResponse, EntryUpdatePayload<StorageEntryResponse>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        convertOutgoingPercentage(rest, ["transmission_loss_t2"]);
        return {
          url: `storage-entries/${id}/`,
          method: 'PUT',
          body: rest
        }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'StorageEntries', id: activityId },
        { type: 'StorageEntriesDefaults', id: body?.id ? body?.id : undefined },
      ]
    }),
    createStorageEntry: builder.mutation<StorageEntryResponse, EntryCreatePayload>({
      query: ({ activityId, parent }) => ({ url: "storage-entries/", method: 'POST', body: { activity: activityId, parent } }),
      invalidatesTags: (_result, _err, { activityId, parent }) => [
        { type: 'StorageEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `storages-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `storages-${parent}` : undefined },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    deleteStorageEntry: builder.mutation<void, EntryDeletePayload>({
      query: ({ id }) => ({ url: `storage-entries/${id}/`, method: 'DELETE' }),
      invalidatesTags: (_result, _err, { activityId, parent, id }) => [
        { type: 'StorageEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `storages-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `storages-${parent}` : undefined },
        { type: 'StorageEntriesDefaults', id },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    getStorageEntriesDefaults: builder.query<StorageEntryDefaults, number>({
      query: (id) => `storage-entries/${id}/defaults/`,
      providesTags: (_result, _err, id) => [{ type: 'StorageEntriesDefaults', id }]
    })
  })
})

export const { useGetStoragesQuery, useUpdateStoragesMutation, useGetStorageEntriesQuery, useUpdateStorageEntryMutation, useCreateStorageEntryMutation, useDeleteStorageEntryMutation, useGetStorageEntriesDefaultsQuery } = storageApiSlice;