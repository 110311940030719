import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/waterbodies/waterbodies";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import type {
	ModuleFull,
	WaterbodiesDefaults,
	WaterbodiesPayload,
	WaterbodiesResponse,
	WaterbodiesT2,
	WaterbodiesUpdatePayload,
} from "../../../../pages/activityBuilder/modules/waterbodies/waterbodiesTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getWaterbodies: builder.query<ModuleFull | null, number>({
			query: (activityId) => `waterbodies/?activity=${activityId}`,
			transformResponse: (response: WaterbodiesResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						{ ...response[0] },
						includedPropList,
					) as WaterbodiesPayload;

					const t2 = filterObjectProps(
						{ ...response[0] },
						includedT2PropList,
					) as WaterbodiesT2;

					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_result, _error, activityId) => [
				{
					type: "Waterbodies",
					id: activityId,
				},
			],
		}),
		updateWaterbodies: builder.mutation<
			WaterbodiesResponse,
			WaterbodiesUpdatePayload
		>({
			query: ({ data, activityId }) => {
				const module = { ...data.module };
				const tiertwo = { ...data.tiertwo };
				const lucId = data.module.land_use_change;

				const payload = {
					...module,
					...tiertwo,
				} as unknown as WaterbodiesResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };
				convertEmptyStringsToNull(body);
				return { url: `waterbodies/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { activityId }) => [
				{ type: "Waterbodies", id: activityId },
				{ type: "ModuleResults", id: results?.id ? `waterbodies-${results?.id}` : undefined },
				{ type: "ModuleHistory", id: results?.id ? `waterbodies-${results?.id}` : undefined },
				{ type: "WaterbodiesDefaults", id: results?.id ?? 0 },
				{ type: "ModulesStatuses", id: activityId }
			],
		}),
		getWaterbodiesDefaults: builder.query<WaterbodiesDefaults, number>({
			query: (uniqueId) => `waterbodies/${uniqueId}/defaults/`,
			providesTags: (_result, _err, uniqueId) => [{ type: "WaterbodiesDefaults", id: uniqueId }],
		})
	}),
});

export const { useGetWaterbodiesQuery, useUpdateWaterbodiesMutation, useGetWaterbodiesDefaultsQuery } =
	moduleApiSlice;
