import type {
	Submodule,
	StartWithWithoutGeneric,
	HectaresInputGroup,
	InputGroup,
	StartWithWithoutTierTwo,
	ModuleSchema,
	T2Schema,
	TranslationKey,
	BelongsToLuc,
	OptionalSubmodule,
} from "../../../../types/modulesInterfaces";
import type { LandUseChangeResponse } from "../landUseChanges/landUseChangeTypes";
import type { GrasslandPayload, GrasslandT2Payload } from "./grasslandTypes";
import * as Yup from "yup";
import { FieldType } from "../../../../types/modulesInterfaces";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { createStandardLucValidation, getBelongsToLuc, standardLucValidation, validatePercentage, validatePositiveNumber } from "../moduleUtils";
import { BEModules } from "../../../../utils/beModules";

export const includedPropList = [
	"id",
	"status",
	"is_fire_used_start",
	"is_fire_used_w",
	"is_fire_used_wo",
	"fire_periodicity_start",
	"fire_periodicity_w",
	"fire_periodicity_wo",
	"fire_impact_start",
	"fire_impact_w",
	"fire_impact_wo",
	"yield_start",
	"yield_w",
	"yield_wo",
	"area",
	"note",
	"land_use_change",
	"land_use_type_start",
	"land_use_type_w",
	"land_use_type_wo",
	"land_use_type_thread",
	"grassland_management_type_start",
	"grassland_management_type_w",
	"grassland_management_type_wo",
	"is_fire_used_thread",
	"fire_periodicity_thread",
	"fire_impact_thread",
	"yield_thread",
];
export const includedT2PropList = [
	"combustion_factor_t2_start",
	"combustion_factor_t2_w",
	"combustion_factor_t2_wo",
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",	
	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"biomass_t2_start",
	"biomass_t2_w",
	"biomass_t2_wo",
	"soil_carbon_t2_start",
	"soil_carbon_t2_w",
	"soil_carbon_t2_wo",
];

const createValidateFireUse = (belongsToLuc?: BelongsToLuc | undefined) => {
	return (scenario: 'start' | 'w' | 'wo', message: TranslationKey,)=>validateFireUse(scenario, message, belongsToLuc)
}
const validateFireUse = (scenario: 'start' | 'w' | 'wo', message: TranslationKey, belongsToLuc?: BelongsToLuc | undefined) => {
	return validatePositiveNumber
		.when([`is_fire_used_${scenario}`], {
			is: (
				is_fire_used: boolean | undefined,
			) => (!belongsToLuc || getBelongsToLuc(scenario, belongsToLuc)) && is_fire_used,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required(message),
		})
}
export const validationSchema = (belongsToLuc?: BelongsToLuc | undefined) => {
	const standardLucValidation = createStandardLucValidation(belongsToLuc);
	const fireUseValidation = createValidateFireUse(belongsToLuc);

	return Yup.object({
		module: Yup.object().shape({
			grassland_management_type_start: standardLucValidation('validations.grassland_management_type_required', 'start', BEModules.Grassland),
			grassland_management_type_w: standardLucValidation('validations.grassland_management_type_required', 'w', BEModules.Grassland),
			grassland_management_type_wo: standardLucValidation('validations.grassland_management_type_required', 'wo', BEModules.Grassland),
			fire_periodicity_start: fireUseValidation('start', 'validations.fire_periodicity_required'),
			fire_periodicity_w: fireUseValidation('w', 'validations.fire_periodicity_required'),
			fire_periodicity_wo: fireUseValidation('wo', 'validations.fire_periodicity_required'),
			fire_impact_start: fireUseValidation('start', 'validations.fire_impact_required')
				.max(100, 'validations.percentage_max_min')
				.min(0, 'validations.percentage_max_min'),
			fire_impact_w: fireUseValidation('w', 'validations.fire_impact_required')
				.max(100, 'validations.percentage_max_min')
				.min(0, 'validations.percentage_max_min'),
			fire_impact_wo: fireUseValidation('wo', 'validations.fire_impact_required')
				.max(100, 'validations.percentage_max_min')
				.min(0, 'validations.percentage_max_min'),
		}),
		tiertwo: Yup.object().shape({
			combustion_factor_t2_start: validatePercentage,
			combustion_factor_t2_w: validatePercentage,
			combustion_factor_t2_wo: validatePercentage,
		}),
	});
}
export const initialValues: GrasslandPayload = {
	id: null,
	status: null,
	area: null,
	note: null,
	activity: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	
	grassland_management_type_start: null,
	grassland_management_type_w: null,
	grassland_management_type_wo: null,
	yield_start: null,
	yield_w: null,
	yield_wo: null,

	is_fire_used_start: false,
	is_fire_used_w: false,
	is_fire_used_wo: false,
	fire_periodicity_start: null,
	fire_periodicity_w: null,
	fire_periodicity_wo: null,
	fire_impact_start: null,
	fire_impact_w: null,
	fire_impact_wo: null,

	land_use_change: null,
	land_use_type_start: null,
	land_use_type_w: null,
	land_use_type_wo: null,

	land_use_type_thread: null,
	is_fire_used_thread: null,
	fire_impact_thread: null,
	fire_periodicity_thread: null,
	yield_thread: null,
};

const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "module.area",
		disabled: true /* TO BE CHANGED */,
	};
	return [hectaresInputGroup];
};

const grasslandTypes = () => getOptions("grassland");
const getMandatorySubmodules: () => Submodule[] = () => {
	const grasslandManagement: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.grassland_management_required",
		inputName: "module.grassland_management_type",
		startProps: { dropdownOptions: grasslandTypes },
		withProps: { dropdownOptions: grasslandTypes },
		withoutProps: { dropdownOptions: grasslandTypes },
	};
	const yieldInputGroup: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.yield",
		inputName: "module.yield",
	};

	return [
		{
			name: "module.grassland_management",
			inputGroups: [grasslandManagement, yieldInputGroup],
		}
	];
};

const getOptionalSubmodules: () => OptionalSubmodule<any>[] = () => {
	const occurrence: StartWithWithoutGeneric = {
		type: FieldType.SWW_BOOL,
		label: "module.fire_occurrence",
		inputName: "module.is_fire_used",
	};
	const periodicity: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		unit: "[yrs]",
		label: "module.fire_periodicity",
		inputName: "module.fire_periodicity",
	};
	const impact: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		unit: "[%]",
		label: "module.fire_impact",
		inputName: "module.fire_impact",
	};

	return [
		{
			name: "module.fire_management_optional",
			inputName: "module.fire_management",
			inputGroups: [occurrence, periodicity, impact],
		},
	];
};

/* T2 */
export const t2InitialValues: GrasslandT2Payload = {
	combustion_factor_t2_start: null,
	combustion_factor_t2_w: null,
	combustion_factor_t2_wo: null,

	biomass_t2_start: null,
	biomass_t2_w: null,
	biomass_t2_wo: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,
	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.soc_t2",
			label: "module.soil_carbon",
			unit: "[tC/ha]",
			links: ["Grassland Management"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.biomass_t2",
			label: "module.biomass",
			unit: "[tC/ha]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.flu_t2",
			label: "module.land_use_factor",
			unit: "[#]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.fmg_t2",
			label: "module.tillage_factor",
			unit: "[#]",
			links: ["Tillage Management"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.fi_t2",
			label: "module.input_factor",
			unit: "[#]",
			links: ["Input of Organic Material"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.combustion_factor_t2",
			label: "module.combustion_factor",
			unit: "[%]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
	];

	return [
		{
			name: "module.grassland_management",
			inputGroups: [...rows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: getMandatorySubmodules(),
		optionalSubmodules: getOptionalSubmodules(),
	};
	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
