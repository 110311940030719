import { apiSlice } from "../apiSlice";
import { convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { TransportEntryDefaults, TransportEntryData, TransportEntryResponse, } from "../../../../pages/activityBuilder/modules/transport/transportTypes";
import { EntryCreatePayload, EntryDeletePayload, EntryUpdatePayload, ParentUpdatePayload, ParentResponse, ParentPayload } from "../../../../types/modulesInterfaces";

const parentEndpoint = "transports/"
const entryEndpoint = "transport-entries/"

const transportApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTransports: builder.query<ParentResponse, { activityId: number }>({
      query: ({ activityId }) => `${parentEndpoint}?activity=${activityId}`,
      transformResponse: (response: ParentResponse[]) => {
        return response[0]
      },
      providesTags: (_result, _err, { activityId }) => [
        { type: 'Transports', id: activityId }
      ]
    }),
    updateTransports: builder.mutation<ParentResponse, ParentUpdatePayload<ParentPayload>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `${parentEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'Transports', id: activityId },
        { type: 'ModulesStatuses', id: activityId },
        { type: 'ModuleResults', id: body?.id ? `transports-${body.id}` : undefined },
        { type: 'ModuleHistory', id: body?.id ? `transports-${body.id}` : undefined },
      ]
    }),
    getTransportEntriesDefaults: builder.query<TransportEntryDefaults, number>({
      query: (id) => `${entryEndpoint}${id}/defaults/`,
      providesTags: (_result, _err, id) => [{ type: 'TransportEntriesDefaults', id }]
    }),
    getTransportEntries: builder.query<TransportEntryData[], { activityId: number }>({
      query: ({ activityId }) => `${entryEndpoint}?activity=${activityId}`,
      transformResponse: (response: TransportEntryData[]) => response.sort((a, b) => a.id - b.id),
      providesTags: (_result, _err, { activityId }) => [{ type: 'TransportEntries', id: activityId }]
    }),
    updateTransportEntry: builder.mutation<TransportEntryResponse, EntryUpdatePayload<TransportEntryResponse>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `${entryEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'TransportEntries', id: activityId },
        { type: 'TransportEntriesDefaults', id: body?.id ? body?.id : undefined },
      ]
    }),
    createTransportEntry: builder.mutation<TransportEntryResponse, EntryCreatePayload>({
      query: ({ activityId, parent }) => ({ url: `${entryEndpoint}`, method: 'POST', body: { activity: activityId, parent } }),
      invalidatesTags: (_result, _err, { activityId, parent }) => [
        { type: 'TransportEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `transports-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `transports-${parent}` : undefined },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    deleteTransportEntry: builder.mutation<void, EntryDeletePayload>({
      query: ({ id }) => ({ url: `${entryEndpoint}${id}/`, method: 'DELETE' }),
      invalidatesTags: (_result, _err, { activityId, parent, id }) => [
        { type: 'TransportEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `transports-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `transports-${parent}` : undefined },
        { type: 'TransportEntriesDefaults', id },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
  })
});

export const { useGetTransportsQuery, useUpdateTransportsMutation, useGetTransportEntriesQuery, useUpdateTransportEntryMutation, useCreateTransportEntryMutation, useDeleteTransportEntryMutation, useGetTransportEntriesDefaultsQuery } = transportApiSlice;