import { includedPropList } from "../../../../pages/activityBuilder/modules/floodedRice/floodedRice";
import type {
	FloodedMinorSeasonCreatePayload,
	FloodedMinorSeasonData,
	FloodedMinorSeasonDeletePayload,
	FloodedMinorSeasonPayload,
	FloodedMinorSeasonResponse,
	FloodedRiceDefaults,
	FloodedRiceParent,
	FloodedRiceResponse,
	FloodedRiceUploadPayload,
} from "../../../../pages/activityBuilder/modules/floodedRice/floodedRiceTypes";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFloodedRice: builder.query<FloodedRiceParent | null, number>({
			query: (activityId) => `flooded-rices/?activity=${activityId}`,
			transformResponse: (response: FloodedRiceResponse[]) => {
				if (response && response.length > 0) {
					const parent = filterObjectProps(
						{ ...response[0] }, includedPropList
					) as FloodedRiceParent;

					return parent
				}
				return null
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "FloodedRice", id: activityId },
			],
		}),
		updateFloodedRice: builder.mutation<
			FloodedRiceResponse,
			FloodedRiceUploadPayload
		>({
			query({ data, activityId }) {
				const { id, land_use_change, ...rest } = data;
				const body = { ...rest, activity: activityId };
				convertEmptyStringsToNull(body);
				return { url: `flooded-rices/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { activityId }) => [
				{ type: "FloodedRice", id: activityId },
				{ type: "ModulesStatuses", id: activityId },
				{ type: "ModuleHistory", id: results?.id ? `flooded-rices-${results.id}` : undefined },
				{ type: "ModuleResults", id: results?.id ? `flooded-rices-${results.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "FloodedRiceDefaults", id: results?.id ? results?.id : undefined },
			],
		}),
		getFloodedRiceDefaults: builder.query<FloodedRiceDefaults, number>({
			query: (uniqueId) => `flooded-rices/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: "FloodedRiceDefaults", id: uniqueId }]
		}),
		getFloodedRiceMinorSeasons: builder.query<
			FloodedMinorSeasonResponse[],
			number
		>({
			query(activityId) {
				return `flooded-rices-minor-seasons/?activity=${activityId}`;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "FloodedSeasons", id: activityId },
			],
		}),
		updateFloodedRiceMinorSeason: builder.mutation<
			FloodedMinorSeasonResponse,
			FloodedMinorSeasonPayload
		>({
			query({ body }) {
				const { id, land_use_change, ...rest } = body;
				const payload = { ...rest };

				convertEmptyStringsToNull(payload);
				return {
					url: `flooded-rices-minor-seasons/${id}/`,
					method: "PUT",
					body: payload,
				};
			},
			invalidatesTags: (_results, _error, { activityId, body }) => [
				{ type: "FloodedSeasons", id: activityId },
				{ type: "ModuleResults", id: body.parent ? `flooded-rices-${body.parent}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: body.parent ? `flooded-rices-${body.parent}` : undefined },
				{ type: "FloodedRiceSeasonDefaults", id: body.id ? body.id : undefined },
				{ type: "ModulesStatuses", id: activityId }
			],
		}),
		createFloodedRiceMinorSeason: builder.mutation<
			FloodedMinorSeasonResponse,
			FloodedMinorSeasonCreatePayload
		>({
			query({ body }) {
				return {
					url: "flooded-rices-minor-seasons/",
					method: "POST",
					body,
				};
			},
			invalidatesTags: (_results, _error, { activityId, body }) => [
				{ type: "FloodedSeasons", id: activityId },
				{ type: "ModuleResults", id: body.parent ? `flooded-rices-${body.parent}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: body.parent ? `flooded-rices-${body.parent}` : undefined },
				{ type: "FloodedRiceSeasonDefaults", id: body.parent ? `flooded-rices-${body.parent}` : undefined },
			],
		}),
		deleteFloodedRiceMinorSeason: builder.mutation<
			void,
			FloodedMinorSeasonDeletePayload
		>({
			query({ id }) {
				return {
					url: `flooded-rices-minor-seasons/${id}/`,
					method: "DELETE",
				};
			},
			invalidatesTags: (_results, _error, { activityId, parent, id }) => [
				{ type: "FloodedSeasons", id: activityId },
				{ type: "ModuleResults", id: parent ? `flooded-rices-${parent}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: parent ? `flooded-rices-${parent}` : undefined },
				{ type: "FloodedRiceSeasonDefaults", id: id },
			],
		}),
		getFloodedRiceMinorSeasonDefaults: builder.query<FloodedMinorSeasonData, number>({
			query: (uniqueId) => `flooded-rices-minor-seasons/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: "FloodedRiceSeasonDefaults", id: uniqueId }]
		}),
	}),
});

export const {
	useGetFloodedRiceQuery,
	useUpdateFloodedRiceMutation,
	useGetFloodedRiceDefaultsQuery,
	useGetFloodedRiceMinorSeasonsQuery,
	useUpdateFloodedRiceMinorSeasonMutation,
	useDeleteFloodedRiceMinorSeasonMutation,
	useCreateFloodedRiceMinorSeasonMutation,
	useGetFloodedRiceMinorSeasonDefaultsQuery
} = moduleApiSlice;
