import * as Yup from "yup";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BackEndSearchTerm, DropdownInputGroupTierTwo, EmissionsByGasTierTwo, FieldType, InputType, ModuleSchema, StartWithWithoutTierTwo, T2Schema, type OptionalSubmodule, type StartWithWithoutGeneric } from "../../../../types/modulesInterfaces";
import { validatePositiveNumber } from "../moduleUtils";
import { ModuleFull, StorageEntryResponse } from "./storageTypes";

const refrigerantTypes = () => getOptions("refrigerantTypes")
const countries = () => getOptions("country")
const efSources = () => getOptions("emissionFactorSources")
const fuelTypes = () => getOptions("fuelTypes", { searchTerm: BackEndSearchTerm.Stationary })

export const validationSchema = () => {
  return Yup.object({
    storages: Yup.array().of(
      Yup.object().shape({
        fuel_type_start: validatePositiveNumber.required("validations.energy_type_required"),
        fuel_type_w: validatePositiveNumber.required("validations.energy_type_required"),
        fuel_type_wo: validatePositiveNumber.required("validations.energy_type_required"),
        quantity_consumed_per_year_start: validatePositiveNumber.required("validations.energy_consumption_required"),
        quantity_consumed_per_year_w: validatePositiveNumber.required("validations.energy_consumption_required"),
        quantity_consumed_per_year_wo: validatePositiveNumber.required("validations.energy_consumption_required"),

        refrigerant_type_start: validatePositiveNumber.when("is_refrigerant_used", {
          is: true,
          then: validatePositiveNumber.required("validations.refrigerant_type_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        refrigerant_type_w: validatePositiveNumber.when("is_refrigerant_used", {
          is: true,
          then: validatePositiveNumber.required("validations.refrigerant_type_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        refrigerant_type_wo: validatePositiveNumber.when("is_refrigerant_used", {
          is: true,
          then: validatePositiveNumber.required("validations.refrigerant_type_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        total_refrigerant_leakage_start: validatePositiveNumber.when("is_refrigerant_used", {
          is: true,
          then: validatePositiveNumber.required("validations.total_refrigerant_leakage_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        total_refrigerant_leakage_w: validatePositiveNumber.when("is_refrigerant_used", {
          is: true,
          then: validatePositiveNumber.required("validations.total_refrigerant_leakage_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        total_refrigerant_leakage_wo: validatePositiveNumber.when("is_refrigerant_used", {
          is: true,
          then: validatePositiveNumber.required("validations.total_refrigerant_leakage_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
      })
    )
  })
}

const getOptionalSubmodules: () => OptionalSubmodule<StorageEntryResponse>[] = () => {
  const fuelType: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    label: "module.type_of_energy_required",
    inputName: (index: number) => `storages.${index}.fuel_type`,
    startProps: { dropdownOptions: fuelTypes },
    withProps: { dropdownOptions: fuelTypes },
    withoutProps: { dropdownOptions: fuelTypes },
  }
  const quantityConsumedPerYear: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: "module.energy_consumption_required",
    inputName: (index: number) => `storages.${index}.quantity_consumed_per_year`,
    startProps: { inputType: "number", labelSuffix: (index: number) => `storages.${index}.fuel_type_start_unit` },
    withProps: { inputType: "number", labelSuffix: (index: number) => `storages.${index}.fuel_type_w_unit` },
    withoutProps: { inputType: "number", labelSuffix: (index: number) => `storages.${index}.fuel_type_wo_unit` },
  }
  const refrigerantType: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    label: "module.select_refrigerant_type",
    inputName: (index: number) => `storages.${index}.refrigerant_type`,
    startProps: { dropdownOptions: refrigerantTypes },
    withProps: { dropdownOptions: refrigerantTypes },
    withoutProps: { dropdownOptions: refrigerantTypes },
  }
  const totalRefrigerantLeakage: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: "module.total_refrigerant_leak",
    unit: "[kg/year]",
    inputName: (index: number) => `storages.${index}.total_refrigerant_leakage`,
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
  }

  return [{
    name: "project.storage",
    inputName: "storages",
    inputGroups: [fuelType, quantityConsumedPerYear],
    repeatable: true,
    linkedTierTwoInputs: ["tiertwo.storages"],
    route: "storages",
    cacheTag: "StorageEntriesDefaults",
    conditionalSection: {
      inputGroups: [refrigerantType, totalRefrigerantLeakage],
      conditionName: "is_refrigerant_used",
      conditionInput: {
        type: FieldType.BOOLEAN,
        label: "module.is_refrigerant_used",
        inputName: (index: number) => `storages.${index}.is_refrigerant_used`,
      },
    }
  }]
}

const getStorageEntryT2Submodule: () => OptionalSubmodule<StorageEntryResponse> = () => {
  const efRows: StartWithWithoutTierTwo[] = [
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `storages.${index}.energy_ef_co2_t2`,
      label: "module.ef_of_fuel_used_co2",
      unit: "[tCO2-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number" },
      withoutProps: { inputType: "number" },
      withProps: { inputType: "number" },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `storages.${index}.energy_ef_ch4_t2`,
      label: "module.ef_of_fuel_used_ch4",
      unit: "[tCH4-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number" },
      withoutProps: { inputType: "number" },
      withProps: { inputType: "number" },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `storages.${index}.energy_ef_n2o_t2`,
      label: "module.ef_of_fuel_used_n2o",
      unit: "[tN2O-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number" },
      withoutProps: { inputType: "number" },
      withProps: { inputType: "number" },
    },
  ];

  const electricityEf: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    label: "module.ef_selected_country",
    unit: "[tCO2/MWh]",
    links: ["Electricity"],
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
    inputName: (index: number) => `storages.${index}.electricity_ef_t2`,
  }
  const countryOfOrigin: DropdownInputGroupTierTwo = {
    type: FieldType.SELECT_T2,
    label: "module.power_country_of_origin",
    inputName: (index: number) => `storages.${index}.country_t2`,
    dropdownOptions: countries,
    unit: "",
    links: ["Electricity"],
    defaultValue: "",
  }
  const emissionFactorSources: DropdownInputGroupTierTwo = {
    type: FieldType.SELECT_T2,
    label: "module.ef_source_of_electricity",
    inputName: (index: number) => `storages.${index}.ef_source`,
    dropdownOptions: efSources,
    unit: "",
    links: ["Electricity"],
    defaultValue: "",
  }
  const emissionFactor: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    label: "module.refrigerant_gwp",
    unit: "[kgCO2-eq/kg of refr]",
    links: ["Refrigerants"],
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
    inputName: (index: number) => `storages.${index}.emission_factor_t2`,
  }
  const transmissionLoss: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    inputName: (index: number) => `storages.${index}.transmission_loss_t2`,
    label: "module.transmission_loss",
    unit: "[%]",
    links: ["Electricity"],
    startProps: { inputType: "number", defaultValue: 0 },
    withoutProps: { inputType: "number", defaultValue: 0 },
    withProps: { inputType: "number", defaultValue: 0 },
    inputType: InputType.PERCENTAGE
  }

  return {
    name: "project.storage",
    inputName: "storages",
    inputGroups: [emissionFactor, ...efRows],
    route: "storages",
    cacheTag: "StorageEntriesDefaults",
    conditionalSection: {
      inputGroups: [electricityEf, countryOfOrigin, emissionFactorSources, transmissionLoss],
      conditionName: "is_electricity_selected"
    }
  }
}

export const getSchemas = () => {
  const moduleSchema: ModuleSchema = {
    initInputGroups: [],
    mandatorySubmodules: [],
    optionalSubmodules: getOptionalSubmodules(),
  };

  const t2EntrySchema = getStorageEntryT2Submodule();

  const t2Schema: T2Schema = {
    submodules: [],
    optionalSubmodules: [t2EntrySchema],
  };

  return { moduleSchema, t2Schema, t2EntrySchema };
}

export const initialValues: ModuleFull = {
  storages: [],
  parent: {
    id: null,
    note: null,
    activity: null,
    start_year: null,
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    status: null,
  }
}

export const refrigerantNullData = {
  refrigerant_type_start: null,
  refrigerant_type_w: null,
  refrigerant_type_wo: null,

  total_refrigerant_leakage_start: null,
  total_refrigerant_leakage_w: null,
  total_refrigerant_leakage_wo: null,
}