import {
	ProjectGasResults,
	ProjectTotalResults,
	ResultsErrors,
} from "../../../app/features/project/projectTypes";
import {
	Emission,
	EmissionsByGas,
	EmissionsTotal,
	GroupingGas,
} from "./resultTypes";

const STANDARD_GAS_TYPES = ['CH4', 'CO2', 'N2O'];
		
/**
 * Calculates total emissions for each gas type from a group of emissions
 * Groups CO, DOC and any non-standard gas types under "OTHER"
 */
const calculateEmissionsByGasType = (statusEmissions: GroupingGas[]): Emission => {
	
	return statusEmissions.reduce(
		(acc, { gas_type, emissions }) => {
			const totalEmissions = emissions.reduce(
				(sum, cur) => sum + cur.value,
				0
			);
			
			// If gas type is standard, add to its category, otherwise add to OTHER
			if (STANDARD_GAS_TYPES.includes(gas_type.name)) {
				return {
					...acc,
					[gas_type.name]: (acc[gas_type.name] ?? 0) + totalEmissions,
				};
			} else {
				return {
					...acc,
					OTHER: (acc.OTHER ?? 0) + totalEmissions,
				};
			}
		},
		createEmptyEmission()
	);
};

/**
 * Extracts emission values from a module's results
 */
const extractModuleEmissions = (module: {
	results: EmissionsByGas | ResultsErrors;
}): Emission => {
	return isValidResult(module.results.balance)
		? calculateEmissionsByGasType(module.results.balance)
		: createEmptyEmission();
};

/**
 * Calculates emissions and cost metrics for each activity
 */
export const calculateEmissionsAndCostByActivity = (
	data: ProjectGasResults | undefined,
) => {
	return data?.activities.map(({ modules, name, cost }) => {
		const totalModuleRes = modules
			.map(extractModuleEmissions)
			.reduce((acc, curr) => mergeEmissions(acc, curr), createEmptyEmission());

		const totalEmissions = Object.values(totalModuleRes).reduce((sum, val) => sum + (val || 0), 0);

		return {
			activityName: name,
			cost,
			RATIO: cost ? Math.round((cost / totalEmissions) * 100) / 100 : 0,
			BALANCE: Math.round(totalEmissions),
			CH4: Math.round(totalModuleRes.CH4),
			CO2: Math.round(totalModuleRes.CO2),
			N2O: Math.round(totalModuleRes.N2O),
			OTHER: Math.round(totalModuleRes.OTHER || 0),
		};
	});
};

/**
 * Aggregates all emissions across project activities
 */
export const aggregateTotalProjectEmissions = (data: ProjectGasResults | undefined) => {
	return data?.activities.reduce(
		(acc, { modules }) => {
			const totalModuleRes = modules
				.map(extractModuleEmissions)
				.reduce((acc, curr) => mergeEmissions(acc, curr), createEmptyEmission());
			
			return mergeEmissions(acc, totalModuleRes);
		},
		createEmptyEmission()
	);
};

/**
 * Calculates total CO2 values for project comparison
 */
export const calculateProjectCO2Balance = (
	data: ProjectTotalResults | undefined,
) => {
	if (!data?.activities) {
		return { total_w: 0, total_wo: 0, balance: 0 };
	}

	return data.activities.reduce(
		(acc, { modules }) => {
			return modules.reduce((moduleAcc, { results }) => ({
				total_w: moduleAcc.total_w + (isValidResult(results?.total_w) ? +results.total_w : 0),
				total_wo: moduleAcc.total_wo + (isValidResult(results?.total_wo) ? +results.total_wo : 0),
				balance: moduleAcc.balance + (isValidResult(results?.balance) ? +results.balance : 0),
			}), acc);
		},
		{ total_w: 0, total_wo: 0, balance: 0 }
	);
};

/**
 * Creates an array of activities with CO2 balance values
 */
export const calculateCO2EmissionsByActivity = (
	data: ProjectTotalResults | undefined,
) => {
	const getModuleRes = (results: ResultsErrors | EmissionsTotal) => 
		isValidResult(results.balance) && typeof results.balance !== "string" ? +results.balance.toFixed(2) : 0;

	return data?.activities.reduce(
		(acc, { name, modules }) => [
			...acc,
			{
				name,
				value: modules.reduce(
					(sum, { results }) => sum + getModuleRes(results),
					0
				),
			},
		],
		[] as { name: string; value: number }[]
	);
};

/**
 * Calculates emissions by project status
 */
export const calculateEmissionByProjectStatus = (data: ProjectGasResults | undefined) => {
	const getStatusResults = (statusEmissions: GroupingGas[]): Emission => {
		
		return statusEmissions.reduce(
			(acc, { gas_type, emissions }) => {
				const totalEmissions = emissions.reduce(
					(sum, cur) => sum + cur.value,
					0
				);
				
				if (STANDARD_GAS_TYPES.includes(gas_type.name)) {
					return {
						...acc,
						[gas_type.name]: parseFloat(totalEmissions.toFixed(2)),
					};
				} else {
					return {
						...acc,
						OTHER: (acc.OTHER ?? 0) + parseFloat(totalEmissions.toFixed(2)),
					};
				}
			},
			createEmptyEmission(false)
		);
	};

	const getModuleRes = (module: { results: EmissionsByGas | ResultsErrors }) => ({
		total_w: isValidResult(module.results.total_w)
			? getStatusResults(module.results.total_w)
			: createEmptyEmission(false),
		total_wo: isValidResult(module.results.total_wo)
			? getStatusResults(module.results.total_wo)
			: createEmptyEmission(false),
		balance: isValidResult(module.results.balance)
			? getStatusResults(module.results.balance)
			: createEmptyEmission(false),
	});

	return data?.activities.reduce(
		(acc, { modules }) => {
			const results = modules
				.map(getModuleRes)
				.reduce(
					(actAcc, curr) => ({
						total_w: mergeEmissions(actAcc.total_w, curr.total_w, 2),
						total_wo: mergeEmissions(actAcc.total_wo, curr.total_wo, 2),
						balance: mergeEmissions(actAcc.balance, curr.balance, 2),
					}),
					{
						total_w: createEmptyEmission(false),
						total_wo: createEmptyEmission(false),
						balance: createEmptyEmission(false),
					}
				);

			return {
				total_w: mergeEmissions(acc.total_w, results.total_w, 2),
				total_wo: mergeEmissions(acc.total_wo, results.total_wo, 2),
				balance: mergeEmissions(acc.balance, results.balance, 2),
			};
		},
		{
			total_w: createEmptyEmission(false),
			total_wo: createEmptyEmission(false),
			balance: createEmptyEmission(false),
		}
	);
};

/**
 * Transforms emissions data into yearly time series
 */
export const calculateYearlyEmissionsTimeSeries = (data: ProjectGasResults | undefined) => {
	const getYearlyResults = (yearlyModEmissions: GroupingGas[]): Emission[] => {
		
		return yearlyModEmissions.reduce((acc, { gas_type, emissions }) => {
			emissions.forEach((gasEmissions, i) => {
				if (!acc[i]) {
					acc[i] = createEmptyEmission();
				}
				
				if (STANDARD_GAS_TYPES.includes(gas_type.name)) {
					acc[i][gas_type.name] = gasEmissions.value;
				} else {
					acc[i].OTHER = (acc[i].OTHER ?? 0) + gasEmissions.value;
				}
			});
			return acc;
		}, [] as Emission[]);
	};

	const getModuleRes = (module: { results: EmissionsByGas | ResultsErrors }): Emission[] => {
		return isValidResult(module.results.balance)
			? getYearlyResults(module.results.balance)
			: [];
	};

	return data?.activities.reduce((acc, { modules }) => {
		const activityEmissionsByYear = modules
			.map(getModuleRes)
			.reduce((yearAcc, currModYrlEmissions) => {
				currModYrlEmissions.forEach((em, i) => {
					yearAcc[i] = yearAcc[i] 
						? mergeEmissions(yearAcc[i], em, 2) 
						: em;
				});
				return yearAcc;
			}, [] as Emission[]);

		activityEmissionsByYear.forEach((em, i) => {
			acc[i] = acc[i] ? mergeEmissions(acc[i], em, 2) : em;
		});
		return acc;
	}, [] as Emission[]);
};

/**
 * Processes emissions data for a single module
 */
export const calculateSingleModuleEmissions = (data: EmissionsByGas | undefined) => {
	return isValidResult(data?.balance) && data && typeof data.balance !== "string"
		? calculateEmissionsByGasType(data.balance)
		: createEmptyEmission();
};

/**
 * Merges two emission objects, with optional precision for decimal places
 */
const mergeEmissions = (
	accEmission: Emission,
	currEmission: Emission,
	precision?: number
): Emission => {
	const merge: Emission = {
		CH4: accEmission.CH4 + currEmission.CH4,
		CO2: accEmission.CO2 + currEmission.CO2,
		N2O: accEmission.N2O + currEmission.N2O,
		OTHER: (accEmission.OTHER ?? 0) + (currEmission.OTHER ?? 0)
	};

	if (precision !== undefined) {
		return Object.fromEntries(
			Object.entries(merge).map(([key, value]) => [key, +value.toFixed(precision)])
		) as unknown as Emission;
	}

	return merge;
};

/**
 * Safely checks if a result field is valid and not a string
 */
const isValidResult = (
	result: any | string | undefined,
): result is GroupingGas[] => {
	return result && typeof result !== "string";
};

/**
 * Creates a default emission object with all values set to 0
 */
const createEmptyEmission = (includeOther: boolean = true): Emission => {
	const base = { CH4: 0, CO2: 0, N2O: 0 };
	return includeOther ? { ...base, OTHER: 0 } : base;
};
