import type { ModuleStatus } from "../../../types/interfaces";
import { useContext, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { MdArrowForward } from "react-icons/md";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import { useGetModuleResultsByGasQuery } from "../../../app/features/api/modules/genericOperationsApiSlice";
import { calculateSingleModuleEmissions } from "../../../pages/project/results/resultsUtils";
import { useGetActivityResultsQuery, useGetModulesStatuesQuery } from "../../../app/features/activities/activityApiSlice";
import { useLocation } from "react-router-dom";
import { ResultSliderItem, TranslationKey } from "../../../types/modulesInterfaces";
import { translate } from "../../../utils/helperFunctions";
import { FEModules } from "../../../utils/moduleList";
import { EventTypes, useFirebaseHook } from "../../../utils/firebaseUtils";
import Accordion, { AccordionContext } from "../../accordion/Accordion";
import useErrorMessage from "../../../utils/useErrorMessage";
import TranslatableText from "../../translations/TranslatableText";
import DescriptionBlock from "../../../pages/project/results/DescriptionBlock";
import Skeleton from "react-loading-skeleton";
import ResultSlider from "../../resultSlider/ResultSlider";

const LiveUpdatesAction = () => {
	const { moduleList, tabsOpen } = useAppSelector(selectCurrentBuilder);
	const { activity, project, activeActivityId } = useAppSelector(selectCurrentProject);
	const { data: moduleStatuses, refetch } = useGetModulesStatuesQuery(activeActivityId ?? 0, { skip: !activeActivityId });

	const atLeastOneIsReady = useMemo(() => moduleStatuses?.some(({ status }) => status.name_en === "READY"), [moduleStatuses]);
	const location = useLocation();
	const isInActivityComplte = useMemo(() => location.pathname.endsWith("complete"), [location.pathname]);

	const areAllModulesReadyExceptLuc = useMemo(() => {
		const modulesIncludeLuc = moduleStatuses?.find(({ feModuleId }) => feModuleId === FEModules.LandUseChange);
		if (!modulesIncludeLuc) return false;

		const isLucEmpty = modulesIncludeLuc.status.name !== "READY";
		const areAllReady = moduleStatuses?.filter(({ feModuleId }) => feModuleId !== FEModules.LandUseChange).every(({ status }) => status.name_en === "READY");

		return isLucEmpty && areAllReady;
	}, [moduleStatuses])

	useEffect(() => {
		if (areAllModulesReadyExceptLuc) { refetch(); console.log("REFETCH") }
	}, [areAllModulesReadyExceptLuc])

	const descriptionBlockValues = useMemo(() => {
		const moisture = activity?.moisture_t2?.name ?? project?.moisture?.name
		const soilType = activity?.soil_type_t2?.name ?? project?.soil_type?.name
		const climate = activity?.climate_t2?.name ?? project?.climate?.name

		// const duration = activity?.duration_t2 ?? project?.implementation_years
		// const startYear = activity?.start_year_t2 ?? project?.start_year_of_activities
		// const activityCapDuration = getCapitalizationYears(startYear, duration, project?.last_year_of_accounting)
		// const capDuration = activityCapDuration !== null ? activityCapDuration : project?.capitalization_years

		return {
			country: project?.country?.name,
			moisture,
			climate,
			soilType,
			implDuration: project?.implementation_years,
			capDuration: project?.capitalization_years,
		}
	}, [project, activity])

	return (
		<motion.section className="live-updates"
			initial={{ width: 0 }}
			animate={{ width: tabsOpen ? "inherit" : 0 }}
			exit={{ width: 0 }}>
			<Accordion header={<TranslatedAccordionHeader label={"activity.activity_description"} />}>
				<div className="d-flex p-2 mb-2 fs-13 lh-1 live-updates-dc description-container">
					<DescriptionBlock
						country={descriptionBlockValues.country}
						moisture={descriptionBlockValues.moisture}
						climate={descriptionBlockValues.climate}
						soilType={descriptionBlockValues.soilType}
						implDuration={descriptionBlockValues.implDuration}
						capDuration={descriptionBlockValues.capDuration}
						isLoading={!activity}
					/>
				</div>
			</Accordion>

			{atLeastOneIsReady && isInActivityComplte && (
				<div className="w-50 pb-3">
					<ActivityBalance />
				</div>)}

			{[...moduleList]
				.map((mod, index) => {
					const header = <AccordionHeader label={mod.name} index={index + 1} key={`acc-header-${mod.uniqueId}`} />;
					return (
						<Accordion header={header} key={`acc-content-${mod.uniqueId}`}>
							<CarbonBalance moduleId={mod.uniqueId} path={mod.path} status={moduleStatuses?.find(({ id }) => id === mod.uniqueId)?.status} />
						</Accordion>
					);
				})}
		</motion.section>
	);
};

const ActivityBalance = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { data: activityResults, isLoading } = useGetActivityResultsQuery(activeActivityId ?? 0, { skip: !activeActivityId, refetchOnMountOrArgChange: true });
	const [balance, setBalance] = useState(0);

	useEffect(() => {
		if (activityResults)
			setBalance(activityResults.balance);
	}, [activityResults]);

	return (
		<Accordion header={<TranslatedAccordionHeader label={"activity.activity_emissions_balance"} />}>
			{isLoading
				?
				<Skeleton
					height={80}
					borderRadius={8}
					key={`Balance-${activeActivityId}`}
				/>
				:
				<div
					className="d-flex f-column"
					key={`Balance-${activeActivityId}`}				>
					<ResultSlider value={balance} />
				</div>
			}
		</Accordion>
	)
}

const AccordionHeader = ({
	label,
	index,
}: {
	label: string;
	index?: number;
}) => {
	const { isOpen, toggleOpen } = useContext(AccordionContext);
	const translatedLabel = useCustomTranslation(label);

	return (
		<motion.div
			initial={false}
			animate={{
				fontFamily: "AIAIAIv4 normal ext",
			}}
			onClick={() => toggleOpen()}
			className="live-updates-accordion-header"
			style={
				index === undefined
					? { borderBottom: "4px solid rgba(50, 41, 37, 0.50)" }
					: undefined
			}
		>
			<span
				className="fs-13"
				style={{ left: index && index >= 10 ? "0" : "8px" }}
			>
				{index !== undefined ? `${index}.` : ""}
			</span>

			<motion.span
				className="fs-13 pe-1 capitalize unselectable ps-3 ff-bold-ext"
				animate={{
					color: isOpen ? "#312A1D" : "rgba(49, 42, 29, 0.75)",
				}}
			>
				{translatedLabel ?? label}
			</motion.span>
			<motion.div
				className="d-flex align-items-center justify-content-center"
				animate={{
					rotate: isOpen ? 90 : 0,
				}}
			>
				<MdArrowForward size={11} color="rgba(49, 42, 29, 0.75)" />
			</motion.div>
		</motion.div>
	);
};

const withTranslation = <P extends {
	label: TranslationKey;
	index?: number;
}>(
	AccordionHeader: React.ComponentType<P>
) => {
	return (props: Omit<P, "label"> & { label: TranslationKey }) => {
		const translatedLabel = useCustomTranslation(props.label);
		return <AccordionHeader {...(props as P)} label={translatedLabel} />;
	};
};

const TranslatedAccordionHeader = withTranslation(AccordionHeader);

const CarbonBalance = ({
	moduleId,
	path,
	status
}: {
	moduleId?: number;
	path: string;
	status?: ModuleStatus
}) => {
	const isReady = useMemo(() => status?.name_en === "READY", [status])
	const { fireEvent } = useFirebaseHook();
	const { data: customRes, isLoading, isError, error } = useGetModuleResultsByGasQuery(
		{
			path,
			uniqueId: moduleId ?? 0,
		},
		{
			skip: !isReady,
			selectFromResult: (result) => ({
				...result,
				data: calculateSingleModuleEmissions(result.data),
			}),
		},
	);
	const { errorMsg } = useErrorMessage({ isError, error })
	const sliders = useMemo((): ResultSliderItem[] => {
		return [
			{ name: `CO<sub>2</sub> ${translate("module.fluxes")}`, value: customRes?.CO2 ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
			{ name: `CH<sub>4</sub> ${translate("module.fluxes")}`, value: customRes?.CH4 ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
			{ name: `N<sub>2</sub>O ${translate("module.fluxes")}`, value: customRes?.N2O ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
			{ name: `${translate("module.OTHER_GHG_fluxes")}`, value: customRes?.OTHER ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
		];
	}, [customRes]);

	useEffect(() => {
		if (isError && errorMsg?.length && isReady)
			fireEvent(EventTypes.module_results_error, { module_id: moduleId, error: errorMsg })
	}, [isError, errorMsg, isReady])

	return (
		<>
			<div
				className={`mt-2 ${isReady ? "mb-3" : "mb-1"} w-fit`}
				style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
			>
				<h2 className="fs-13 text-black">
					<TranslatableText translationKey="main.carbon_balance" />
				</h2>
			</div>
			{isError && errorMsg?.length ?
				<p className={"text-error fs-13 pb-2"}>{errorMsg}</p> :
				isReady ?
					<div className="liveup-co2-blocks-grid pb-4">
						{isLoading
							? sliders.map((slider) => (
								<Skeleton height={80} borderRadius={8} key={slider.name} />
							))
							: sliders.map((slider) => (
								<div
									className="d-flex f-column text-black mb-2"
									key={slider.name}
								>
									<span className="text-black fs-13 ff-bold lh-90" dangerouslySetInnerHTML={{ __html: slider.name }} />
									<TranslatableText className="text-black fs-12 ff-light" translationKey={slider.unit} />
									<ResultSlider value={slider.value} />
								</div>
							))}
					</div> :
					<p className="pb-3 fs-12">Module is not yet ready</p>
			}
		</>
	);
};

export default LiveUpdatesAction;
