import type { ModuleFull } from "./setAsideTypes";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";

import {
	initialValues,
	validationSchema,
	getSchemas,
	t2InitialValues,
} from "./setAside";
import {
	useAppSelector,
	useSyncNotesWithStore,
	useThreadsProcessor,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/beModules";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetSetAsideDefautlsQuery,
	useGetSetAsideQuery,
	useUpdateSetAsideMutation,
} from "../../../../app/features/api/modules/setAsideApiSlice";
import useErrorMessage from "../../../../utils/useErrorMessage";
import useModuleHook from "../useModuleHook";
import useLandUseTypeInput from "../useLandUseTypeInput";

const SetAside = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: setAsideData,
		isLoading,
		isError,
		error,
	} = useGetSetAsideQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	const [
		updateSetAside,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateSetAsideMutation();

	const { data: defaults, error: defaultsError, isError: isDefaultsError, isLoading: isLoadingDefaults } = useGetSetAsideDefautlsQuery(
		setAsideData?.module.id ?? 0,
		{ skip: !setAsideData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });


	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});
	const {processModuleThreads} = useThreadsProcessor<ModuleFull>();
	const { notes } = useSyncNotesWithStore({
		notes: setAsideData?.module.note ?? null,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (setAsideData) {
			setInitValues({
				module: setAsideData.module,
				tiertwo: { ...setAsideData.tiertwo, ...defaults },
			});
			processModuleThreads({
				module: setAsideData.module,
			})
		}
	}, [setAsideData, defaults, processModuleThreads]);

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const data: ModuleFull = JSON.parse(JSON.stringify({ ...values }));
		try {
			await updateSetAside({
				data,
				activityId: activeActivityId ?? 0,
			});
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	const input = useLandUseTypeInput({
		inputName: "module.land_use_type"
	});

	return (
		<Formik
			initialValues={initValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.set_aside"
					t2Schema={t2Schema}
					beModuleType={BEModules.SetAside}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					moduleSchema={moduleSchema}
					isError={isUpdateError}
					note={notes?.content ?? null}
				>
					{() => (
						<div className="py-2">
							<GenericInputGroup inputGroup={input} />
						</div>
					)}
				</GenericModule>
				<GenericTierTwo
					title="project.set_aside"
					t2Schema={t2Schema}
					tabsOpen={tabsOpen}
					warningMessage={defaultsErrorMsg || null}
				/>
			</>
		</Formik>
	);
};

export default SetAside;
