import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { ModuleProps, T2Schema } from "../../../../types/modulesInterfaces";
import { ModuleFull, StorageEntryResponse } from "./storageTypes";
import { useCreateStorageEntryMutation, useDeleteStorageEntryMutation, useGetStorageEntriesQuery } from "../../../../app/features/api/modules/storageApiSlice";
import { FEModules } from "../../../../utils/moduleList";
import { useMemo, SetStateAction, useEffect } from "react";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { useFormikContext } from "formik";
import { isEqual } from 'lodash';
import { mergeLocalAndServerChanges } from "../inputs/utils";
import { useVCEnergyHandler } from "../../../../utils/useVCEnergyHandler";
import GenericModule from "../GenericModule";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";

interface StorageTierOneProps {
  initValues: ModuleFull;
  isEntriesLoading: boolean;
  t2Schema: T2Schema;
  setInitValues: (value: SetStateAction<ModuleFull>) => void
}

const StorageTierOne = ({
  initValues,
  isEntriesLoading,
  setInitValues,
  ...moduleProps
}: ModuleProps & StorageTierOneProps) => {
  const { values } = useFormikContext<ModuleFull>()
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { isReadOnly } = usePermissions()
  const { moduleList } = useAppSelector(selectCurrentBuilder);
  useVCEnergyHandler<ModuleFull, StorageEntryResponse>({ listInputName: "storages", energyTypeInputName: "fuel_type" })

  const parentId = useMemo(() => moduleList.find((m) => m.id === FEModules.Storage)?.uniqueId, [moduleList]);
  const [deleteStorageEntry, { isLoading: isLoadingDeleteStorageEntry }] = useDeleteStorageEntryMutation()
  const [createStorageEntry, { isLoading: isLoadingCreateStorageEntry }] = useCreateStorageEntryMutation()

  const { data: entriesData } = useGetStorageEntriesQuery({ activityId: activeActivityId ?? 0 });
  const list = useMemo(() => {
    const isEntriesUpdated = !isEqual(values.storages, entriesData)
    return isEntriesUpdated ? mergeLocalAndServerChanges({
      currentChangesList: values.storages,
      incomingList: entriesData ?? [],
    }) : values.storages
  }, [entriesData])

  useEffect(() => {
    if (list)
      setInitValues((cur) => ({ ...cur, storages: list }))
  }, [list])

  const handleDeleteStorage = (
    remove: <T>(index: number) => T | undefined,
    index: number,
    id?: number | null,) => {
    remove(index)
    if (id) deleteStorageEntry({ id, activityId: activeActivityId ?? 0, parent: parentId })
  }
  const handleCreateStorage = () => {
    createStorageEntry({ activityId: activeActivityId ?? 0, parent: parentId })
  }

  return (
    <GenericModule {...moduleProps} disableOptionals>
      {
        () => (
          <>
            {moduleProps.moduleSchema.optionalSubmodules?.map((subModule, index) => {
              return (
                <GenericOptionalSubModule<StorageEntryResponse>
                  key={`${subModule.name}-${index}`}
                  optionalSchema={subModule}
                  values={values?.storages}
                  addLabel="module.add_new_storage"
                  createHandler={handleCreateStorage}
                  removeHandler={handleDeleteStorage}
                  fieldArrayName={subModule.name}
                  isOptionalLoading={isEntriesLoading || isLoadingCreateStorageEntry || isLoadingDeleteStorageEntry}
                  isCreateLoading={isLoadingCreateStorageEntry}
                  isDeleteLoading={isLoadingDeleteStorageEntry}
                  isReadOnly={isReadOnly}
                />
              )
            })}
          </>
        )
      }
    </GenericModule>
  )
};

export default StorageTierOne;

