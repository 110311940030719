import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
	t2InitialValues,
	initialValues,
	validationSchema,
} from "./annualCropland";
import {
	useGetAnnualCroplandsDefaultsQuery,
	useGetAnnualCroplandsQuery,
	useUpdateAnnualCroplandsMutation,
} from "../../../../app/features/api/modules/annualCroplandApiSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useAppSelector,
	useThreadsProcessor,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import type { ModuleFull } from "./annualCroplandTypes";
import { BEModules } from "../../../../utils/beModules";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import useAnnualCroplandSchema from "./useAnnualCroplandSchema";
import ModuleContext from "../../ModuleContext";

const AnnualCroplandModule = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { belongsToLuc } = useContext(ModuleContext);

	const {
		data: annualCroplandsData,
		isLoading,
		isError,
		error,
	} = useGetAnnualCroplandsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateAnnualCroplands,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateAnnualCroplandsMutation();

	const { data: defaults, isError: isDefaultsError, error: defaultsError, isLoading: isLoadingDefaults } = useGetAnnualCroplandsDefaultsQuery(annualCroplandsData?.module.id ?? 0, { skip: !annualCroplandsData?.module.id });
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });

	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();
	const { moduleSchema, t2Schema } = useAnnualCroplandSchema();
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});
	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error })

	useEffect(() => {
		if (annualCroplandsData) {
			setInitValues({ module: { ...annualCroplandsData.module, ...defaults }, tiertwo: { ...annualCroplandsData.tiertwo, ...defaults }, });
			processModuleThreads({
				module: annualCroplandsData.module,
			})
		}
	}, [annualCroplandsData, defaults, processModuleThreads]);

	const { notes } = useSyncNotesWithStore({
		notes: annualCroplandsData?.module.note ?? null,
	})
	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module };
		const payload = {
			data: { ...values, module },
			activityId: activeActivityId ?? 0,
		};
		try {
			values.module.id && await updateAnnualCroplands(payload).unwrap();;
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema(belongsToLuc)}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.annual_cropland"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					isLoading={isLoadingUpdate}
					beModuleType={BEModules.AnnualCropland}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="project.annual_cropland"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default AnnualCroplandModule;
