import * as Yup from "yup";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";

import { ModuleSchema, OptionalSubmodule, StartWithWithoutGeneric, T2Schema } from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import { validatePositiveNumber } from "../moduleUtils";
import type { ModuleFull, InputEntryResponse } from "./inputsTypes";
import { DropdownInputGroup } from "../../../../types/modulesInterfaces";

const inputTypes = () => getOptions("inputTypes")

export const validationSchema = Yup.object({
	entries: Yup.array().of(
		Yup.object().shape({
			input_type: validatePositiveNumber.required('validations.input_type_required'),
			value_start: validatePositiveNumber.required('validations.amount_required'),
			value_w: validatePositiveNumber.required('validations.amount_required'),
			value_wo: validatePositiveNumber.required('validations.amount_required'),
		}),
	),
});

const getOptionalSubmodules: () => OptionalSubmodule<InputEntryResponse>[] = () => {
	const inputTypeSelect: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.input_type",
		inputName: (index: number) => `entries.${index}.input_type`,
		dropdownOptions: inputTypes ?? [],
	}

	const valuesSww: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.value",
		unit: "[t/yr]",
		inputName: (index: number) => `entries.${index}.value`,
	}

	return [{
		name: "project.inputs",
		inputName: "entries",
		inputGroups: [inputTypeSelect, valuesSww],
		repeatable: true,
		linkedTierTwoInputs: ["tiertwo.inputs"],
		route: "inputs",
		cacheTag: "InputEntriesDefaults",
	}]
}

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: [],
		mandatorySubmodules: [],
		optionalSubmodules: getOptionalSubmodules(),
	};

	const t2Schema: T2Schema = {
		submodules: [],
		optionalSubmodules: [],
	};

	return { moduleSchema, t2Schema };
}

export const initialValues: ModuleFull = {
	entries: [],
	parent: {
		id: null,
		note: null,
		activity: null,
		start_year: null,
		soc_t2_start: null,
		soc_t2_w: null,
		soc_t2_wo: null,
		status: null,
	}
}