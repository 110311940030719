import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { initialValues, validationSchema, getSchemas } from "./landUseChange";
import { useAppSelector, useThreadsProcessor, useSyncNotesWithStore } from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetLandUseChangesQuery,
	useUpdateLandUseChangeMutation,
} from "../../../../app/features/api/modules/landUseChangeApiSlice";
import type { ModuleFull } from "./landUseChangeTypes";
import { BEModules } from "../../../../utils/beModules";
import GenericModule from "../GenericModule";
import useModuleHook from "../useModuleHook";
import { instanceOfSWW } from "../inputs/utils";
import useErrorMessage from "../../../../utils/useErrorMessage";

const LandUseChange = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: landUseChangeData,
		isLoading,
		isError,
		error,
	} = useGetLandUseChangesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [updateLandUseChange, { error: updateError, isError: isUpdateError }] =
		useUpdateLandUseChangeMutation();

	const getSchemaCallback = () => {
		const { moduleSchema, t2Schema } = getSchemas();
		const isWithDisabled =
			landUseChangeData?.module.module_type_start ===
			landUseChangeData?.module.module_type_w;
		const isWithoutDisabled =
			landUseChangeData?.module.module_type_start ===
			landUseChangeData?.module.module_type_wo;

		if (!isWithDisabled && !isWithoutDisabled)
			return { moduleSchema, t2Schema };

		const editedInitInputGroups = moduleSchema.initInputGroups.map(
			(inputGroup) =>
				inputGroup.inputName !== "module.module_type" &&
					instanceOfSWW(inputGroup)
					? {
						...inputGroup,
						withProps: { ...inputGroup.withProps, disabled: isWithDisabled },
						withoutProps: {
							...inputGroup.withoutProps,
							disabled: isWithoutDisabled,
						},
					}
					: inputGroup,
		);

		return {
			moduleSchema: { ...moduleSchema, initInputGroups: editedInitInputGroups },
			t2Schema,
		};
	};

	const { moduleSchema, t2Schema } = useMemo(getSchemaCallback, [
		landUseChangeData,
	]);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
	});

	const { errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

	useEffect(() => {
		if (landUseChangeData) {
			setInitValues(landUseChangeData);
			processModuleThreads({
				module: landUseChangeData.module
			})
		}
	}, [landUseChangeData, processModuleThreads]);


	const { notes } = useSyncNotesWithStore({
		notes: landUseChangeData?.module.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module, notes };
		try {
			values.module.id &&
				await updateLandUseChange({
					data: { ...values, module },
					activityId: activeActivityId ?? 0,
				}).unwrap();
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.land_use_change"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					beModuleType={BEModules.LandUseChange}
					isLoading={isLoading}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
					/>
			</>
		</Formik>
	);
};

export default LandUseChange;
