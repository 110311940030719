import { ReactNode, useContext, useEffect, useState } from "react";
import { FormStates, SubmodulePlus } from "./setupAssistant.types";
import { differentPurpose, ifNotProject } from "./setupAssistantUtils";
import { BiChevronRight } from "react-icons/bi";
import { RiInformationFill } from "react-icons/ri";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import Accordion, {
	AccordionContext,
} from "../../../../components/accordion/Accordion";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import check from "../../../../assets/icons/check.svg";
import { TranslationKey } from "../../../../types/modulesInterfaces";
import TranslatableText from "../../../../components/translations/TranslatableText";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../../components/tooltip/Tooltip";

const SetupAssistantStep = ({
	step,
	isComplete,
	index,
	children,
}: {
	step: SubmodulePlus;
	index: number;
	isComplete?: boolean;
	children?: ReactNode;
}) => {
	return (
		<>
			<Accordion
				initiallyOpen={!isComplete}
				header={
					<StepHeader
						label={step.name ?? ""}
						isComplete={isComplete}
						index={index}
						moreInfo={step.help?.label}
					/>
				}
			>
				<div className="d-flex f-column">
					{step.step !== FormStates.areaWithoutProject ? (
						step.inputGroups.map((input, index) => (
							<div className="py-1" key={index}>
								<GenericInputGroup
									inputGroup={{
										...input,
										disabled: isComplete,
									}}
								/>
							</div>
						))
					) : (
						<AreaWithoutProjectStep isComplete={isComplete} />
					)}
				</div>
			</Accordion>
			{children ?? null}
		</>
	);
};

const StepHeader = ({
	label,
	isComplete,
	index,
	moreInfo,
}: {
	label: TranslationKey;
	index: number;
	isComplete?: boolean;
	moreInfo?: TranslationKey;
}) => {
	const { isOpen, toggleOpen } = useContext(AccordionContext);
	useEffect(() => {
		toggleOpen(!isComplete);
	}, [isComplete, toggleOpen]);

	return (
		<div
			onClick={() => toggleOpen()}
			className={`submodule-header w-100 pos-relative ${isComplete ? "completed" : "mt-2"}`}
		>
			<div className="submodule-header-arrow">
				<motion.div
					animate={{
						rotate: isOpen ? 90 : 0,
					}}
					className="h-100 d-flex align-items-center justify-content-center"
				>
					<BiChevronRight size={24} color="rgba(28, 27, 31, 0.5)" />
				</motion.div>
			</div>

			<div className="d-flex align-items-center">
				{isComplete ? (
					<img
						src={check}
						alt="completed question"
						className="unclickable ps-1"
					/>
				) : null}
				<h2 className="ff-bold fs-13 px-1">Q{index + 1}:</h2>
				<h2 className="fs-13">
					<TranslatableText translationKey={label} />
				</h2>
				{
					moreInfo && (
						<div style={{ marginLeft: "5px" }}>
							<Tooltip>
								<TooltipTrigger>
									<RiInformationFill size={20} color="var(--quaternary-deep)" />
								</TooltipTrigger>
								<TooltipContent>
									<div style={{ maxWidth: "400px", display: "flex", alignItems: "center", justifyContent: "center", background: "var(--quaternary-deep)",borderRadius: "6px", color: "var(--white)",padding: "10px", }}>
										<TranslatableText className="p-1 ff-normal-ext fs-12 base-tooltip" translationKey={moreInfo}/>
									</div>
								</TooltipContent>
							</Tooltip>
						</div>
					)
				}
			</div>
		</div>
	);
};

interface AWP {
	areaWithoutProject?: number;
	withoutProjectLandUse?: number;
}
//ATTENTION! this is hard-coded to dinamically display the select input
const AreaWithoutProjectStep = ({ isComplete }: { isComplete?: boolean }) => {
	const [showSelect, setShowSelect] = useState(false);
	const { values } = useFormikContext<AWP>();

	useEffect(() => {
		setShowSelect(values?.areaWithoutProject === 1);
	}, [values]);

	return (
		<div className="d-flex f-column">
			<div className="py-1">
				<GenericInputGroup
					inputGroup={{
						...ifNotProject,
						disabled: isComplete,
					}}
				/>
				{showSelect ? (
					<GenericInputGroup
						inputGroup={{
							...differentPurpose,
							disabled: isComplete,
						}}
					/>
				) : null}
			</div>
		</div>
	);
};

export default SetupAssistantStep;
