import { useEffect, useMemo } from "react";
import { usePermissions } from "../../../../app/hooks";
import { EntryComponentProps } from "../GenericOptionalTierTwo";
import { TransportEntryData } from "./transportTypes";
import { getSchemas } from "./transport";
import { useGetTransportEntriesDefaultsQuery } from "../../../../app/features/api/modules/transportApiSlice";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import { TierTwoBlock } from "../GenericTierTwo";
import { useFormikContext } from "formik";
import useErrorMessage from "../../../../utils/useErrorMessage";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";

interface TransportEntryT2Props extends EntryComponentProps<TransportEntryData> { }

const TransportEntryT2 = ({ entry, index, onDefaultsError }: TransportEntryT2Props) => {
  const { isReadOnly } = usePermissions();
  const { t2EntrySchema } = useMemo(() => getSchemas(), []);

  const { data: defaults, isError, error } = useGetTransportEntriesDefaultsQuery(entry.id, { skip: !entry.id });
  const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });
  useEffect(() => {
    if (defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);
  }, [defaultsErrorMsg, onDefaultsError])

  //merge defaults with entry
  const { setFieldValue } = useFormikContext<{ transports: TransportEntryData[] }>();
  const entryWithDefaults: TransportEntryData | null = useMemo(() => defaults ? { ...entry, ...defaults } : null, [defaults, entry])
  useEffect(() => {
    if (entryWithDefaults && index !== undefined)
      setFieldValue(`transports.${index}`, entryWithDefaults);
  }, [entryWithDefaults, setFieldValue, index]);

  return (
    <TranslatedFormSubmodule
      key={entry.id}
      submoduleName={t2EntrySchema.name}
      itemId={entry.id}
      itemIndex={index + 1}
      className="pt-2"
    >
      <TierTwoBlock>
        {t2EntrySchema.inputGroups.map((input) => (
          <GenericInputGroup key={`${input.label}-${entry.id}`} inputGroup={{ ...input, disabled: isReadOnly, index }} />
        ))}
      </TierTwoBlock>
    </TranslatedFormSubmodule>
  );
}

export default TransportEntryT2;