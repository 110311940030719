import * as Yup from "yup";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BackEndSearchTerm, BooleanInputGroup, DropdownInputGroupTierTwo, EmissionsByGasTierTwo, FieldType, InputType, ModuleSchema, StartWithWithoutTierTwo, T2Schema, type OptionalSubmodule, type StartWithWithoutGeneric } from "../../../../types/modulesInterfaces";
import { validatePositiveNumber } from "../moduleUtils";
import { ModuleFull, PackagingEntryResponse } from "./packagingTypes";

const packagingMaterialTypes = () => getOptions("packagingMaterialTypes")
const fuelTypes = () => getOptions("fuelTypes", { searchTerm: BackEndSearchTerm.Stationary })
const countries = () => getOptions("country")
const efSources = () => getOptions("emissionFactorSources")

export const validationSchema = () => {
  return Yup.object({
    packagings: Yup.array().of(
      Yup.object().shape({
        packaging_material_type_start: validatePositiveNumber,
        packaging_material_type_w: validatePositiveNumber,
        packaging_material_type_wo: validatePositiveNumber,
        kg_of_packaging_material_start: validatePositiveNumber.when("packaging_material_type_start", {
          is: (packaging_material_type_start: number) => !!packaging_material_type_start,
          then: validatePositiveNumber.required("validations.kg_of_packaging_material_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        kg_of_packaging_material_w: validatePositiveNumber.when("packaging_material_type_w", {
          is: (packaging_material_type_w: number) => !!packaging_material_type_w,
          then: validatePositiveNumber.required("validations.kg_of_packaging_material_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        kg_of_packaging_material_wo: validatePositiveNumber.when("packaging_material_type_wo", {
          is: (packaging_material_type_wo: number) => !!packaging_material_type_wo,
          then: validatePositiveNumber.required("validations.kg_of_packaging_material_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),

        fuel_type_start: validatePositiveNumber.when("is_electric", {
          is: true,
          then: validatePositiveNumber.required("validations.energy_type_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        fuel_type_w: validatePositiveNumber.when("is_electric", {
          is: true,
          then: validatePositiveNumber.required("validations.energy_type_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        fuel_type_wo: validatePositiveNumber.when("is_electric", {
          is: true,
          then: validatePositiveNumber.required("validations.energy_type_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        quantity_consumed_per_year_start: validatePositiveNumber.when("is_electric", {
          is: true,
          then: validatePositiveNumber.required("validations.energy_consumption_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        quantity_consumed_per_year_w: validatePositiveNumber.when("is_electric", {
          is: true,
          then: validatePositiveNumber.required("validations.energy_consumption_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
        quantity_consumed_per_year_wo: validatePositiveNumber.when("is_electric", {
          is: true,
          then: validatePositiveNumber.required("validations.energy_consumption_required"),
          otherwise: validatePositiveNumber.notRequired(),
        }),
      })
    )
  })
}

const getOptionalSubmodules: () => OptionalSubmodule<PackagingEntryResponse>[] = () => {
  const packagingMaterialTypeInput: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    label: 'module.packaging_material_type_required',
    inputName: (index: number) => `packagings.${index}.packaging_material_type`,
    startProps: { dropdownOptions: packagingMaterialTypes },
    withProps: { dropdownOptions: packagingMaterialTypes },
    withoutProps: { dropdownOptions: packagingMaterialTypes },
  }
  const amountOfPackagingMaterial: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: 'module.kg_of_packaging_material_required',
    unit: '[kg]',
    inputName: (index: number) => `packagings.${index}.kg_of_packaging_material`,
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
  }

  const isElectric: BooleanInputGroup = {
    type: FieldType.BOOLEAN,
    label: 'module.is_electric',
    inputName: (index: number) => `packagings.${index}.is_electric`,
  }
  const fuelTypeInput: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    label: 'module.type_of_energy_required',
    inputName: (index: number) => `packagings.${index}.fuel_type`,
    startProps: { dropdownOptions: fuelTypes },
    withProps: { dropdownOptions: fuelTypes },
    withoutProps: { dropdownOptions: fuelTypes },
  }
  const quantityConsumedPerYear: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: 'module.energy_consumption_required',
    inputName: (index: number) => `packagings.${index}.quantity_consumed_per_year`,
    startProps: { inputType: "number", labelSuffix: (index: number) => `packagings.${index}.fuel_type_start_unit` },
    withProps: { inputType: "number", labelSuffix: (index: number) => `packagings.${index}.fuel_type_w_unit` },
    withoutProps: { inputType: "number", labelSuffix: (index: number) => `packagings.${index}.fuel_type_wo_unit` },
  }

  return [
    {
      name: 'project.packaging',
      inputName: 'packagings',
      inputGroups: [packagingMaterialTypeInput, amountOfPackagingMaterial],
      repeatable: true,
      linkedTierTwoInputs: ['tiertwo.packagings'],
      route: 'packagings',
      cacheTag: 'PackagingEntriesDefaults',
      conditionalSection: {
        inputGroups: [fuelTypeInput, quantityConsumedPerYear],
        conditionName: 'is_electric',
        conditionInput: isElectric,
      }
    }
  ]
}

const getPackagingEntryT2Submodule: () => OptionalSubmodule<PackagingEntryResponse> = () => {
  const efRows: StartWithWithoutTierTwo[] = [
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `packagings.${index}.energy_ef_co2_t2`,
      label: "module.ef_of_fuel_used_co2",
      unit: "[tCO2-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number" },
      withoutProps: { inputType: "number" },
      withProps: { inputType: "number" },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `packagings.${index}.energy_ef_ch4_t2`,
      label: "module.ef_of_fuel_used_ch4",
      unit: "[tCH4-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number" },
      withoutProps: { inputType: "number" },
      withProps: { inputType: "number" },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `packagings.${index}.energy_ef_n2o_t2`,
      label: "module.ef_of_fuel_used_n2o",
      unit: "[tN2O-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number" },
      withoutProps: { inputType: "number" },
      withProps: { inputType: "number" },
    },
  ];

  const electricityEf: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    label: "module.ef_selected_country",
    unit: "[tCO2/MWh]",
    links: ["Electricity"],
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
    inputName: (index: number) => `packagings.${index}.electricity_ef_t2`,
  }
  const countryOfOrigin: DropdownInputGroupTierTwo = {
    type: FieldType.SELECT_T2,
    label: "module.power_country_of_origin",
    inputName: (index: number) => `packagings.${index}.country_t2`,
    dropdownOptions: countries,
    unit: "",
    links: ["Electricity"],
    defaultValue: "",
  }
  const emissionFactorSources: DropdownInputGroupTierTwo = {
    type: FieldType.SELECT_T2,
    label: "module.ef_source_of_electricity",
    inputName: (index: number) => `packagings.${index}.ef_source`,
    dropdownOptions: efSources,
    unit: "",
    links: ["Electricity"],
    defaultValue: "",
  }
  const transmissionLoss: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    inputName: (index: number) => `packagings.${index}.transmission_loss_t2`,
    label: "module.transmission_loss",
    unit: "[%]",
    links: ["Electricity"],
    startProps: { inputType: "number", defaultValue: 0 },
    withoutProps: { inputType: "number", defaultValue: 0 },
    withProps: { inputType: "number", defaultValue: 0 },
    inputType: InputType.PERCENTAGE
  }
  const emissionFactor: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    label: "module.ef_of_packaging_material",
    unit: "[tCO2-eq/t of mat]",
    links: ["Packaging Material"],
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
    inputName: (index: number) => `packagings.${index}.emission_factor_t2`,
  }

  return {
    name: "project.packaging",
    inputName: "packagings",
    inputGroups: [emissionFactor, ...efRows],
    route: "packagings",
    cacheTag: "PackagingEntriesDefaults",
    conditionalSection: {
      inputGroups: [electricityEf, countryOfOrigin, emissionFactorSources, transmissionLoss],
      conditionName: "is_electricity_selected"
    }
  }

}

export const getSchemas = () => {
  const moduleSchema: ModuleSchema = {
    initInputGroups: [],
    mandatorySubmodules: [],
    optionalSubmodules: getOptionalSubmodules(),
  };

  const t2EntrySchema = getPackagingEntryT2Submodule();
  const t2Schema: T2Schema = {
    submodules: [],
    optionalSubmodules: [t2EntrySchema],
  };

  return { moduleSchema, t2Schema, t2EntrySchema };
}

export const initialValues: ModuleFull = {
  packagings: [],
  parent: {
    id: null,
    note: null,
    activity: null,
    start_year: null,
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    status: null,
  }
}

export const energyNullData = {
  fuel_type_start: null,
  fuel_type_w: null,
  fuel_type_wo: null,

  quantity_consumed_per_year_start: null,
  quantity_consumed_per_year_w: null,
  quantity_consumed_per_year_wo: null,
}