
import * as Yup from "yup";
import type {
	PerennialCroplandPayload,
	PerennialCroplandT2Payload,
} from "./perennialCroplandTypes";
import { BEModules } from "../../../../utils/beModules";
import { createStandardLucValidation, standardLucValidation } from "../moduleUtils";
import { BelongsToLuc } from "../../../../types/modulesInterfaces";

export const includedPropList = [
	"id",
	"status",
	"area",
	"is_biomass_burned_start",
	"is_biomass_burned_w",
	"is_biomass_burned_wo",
	"crop_yield_t2_start",
	"crop_yield_t2_w",
	"crop_yield_t2_wo",
	"land_use_type_start",
	"land_use_type_w",
	"land_use_type_wo",
	"tillage_management_type_start",
	"tillage_management_type_w",
	"tillage_management_type_wo",
	"organic_input_type_start",
	"organic_input_type_w",
	"organic_input_type_wo",
	"land_use_type_thread",
	"tillage_management_type_thread",
	"organic_input_type_thread",
	"is_biomass_burned_thread",
	"crop_yield_t2_thread",
	"land_use_change",
	"activity", //!!!
	"minorSeason",
	"note",
];

export const minorSeasonPropsList = [
	"minor_land_use_type_start",
	"minor_land_use_type_w",
	"minor_land_use_type_wo",
	"minor_residue_management_type_start",
	"minor_residue_management_type_w",
	"minor_residue_management_type_wo",
	"minor_yield_start",
	"minor_yield_w",
	"minor_yield_wo",
];

export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"tillage_factor_t2_start",
	"tillage_factor_t2_w",
	"tillage_factor_t2_wo",
	"agb_t2_start",
	"agb_t2_w",
	"agb_t2_wo",
	"bgb_t2_start",
	"bgb_t2_w",
	"bgb_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo",
	"residue_availability_t2_start",
	"residue_availability_t2_w",
	"residue_availability_t2_wo",
	"fire_periodicity_t2_start",
	"fire_periodicity_t2_w",
	"fire_periodicity_t2_wo",
	"agb_max_t2_start",
	"agb_max_t2_w",
	"agb_max_t2_wo",

	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"ag_t2_start",
	"ag_t2_w",
	"ag_t2_wo",
	"bg_t2_start",
	"bg_t2_w",
	"bg_t2_wo",
	"input_factor_t2_start",
	"input_factor_t2_w",
	"input_factor_t2_wo",
];

export const validationSchema = (belongsToLuc?: BelongsToLuc | undefined) => {
	const standardValidation = createStandardLucValidation(belongsToLuc);

	return Yup.object().shape({
		module: Yup.object().shape({
			land_use_type_start: standardValidation('validations.agroforestry_system_required', 'start', BEModules.PerennialCropland),
			land_use_type_w: standardValidation('validations.agroforestry_system_required', 'w', BEModules.PerennialCropland),
			land_use_type_wo: standardValidation('validations.agroforestry_system_required', 'wo', BEModules.PerennialCropland),
			tillage_management_type_start: standardValidation('validations.tillage_management_required', 'start', BEModules.PerennialCropland),
			tillage_management_type_w: standardValidation('validations.tillage_management_required', 'w', BEModules.PerennialCropland),
			tillage_management_type_wo: standardValidation('validations.tillage_management_required', 'wo', BEModules.PerennialCropland),
			organic_input_type_start: standardValidation('validations.organic_input_required', 'start', BEModules.PerennialCropland),
			organic_input_type_w: standardValidation('validations.organic_input_required', 'w', BEModules.PerennialCropland),
			organic_input_type_wo: standardValidation('validations.organic_input_required', 'wo', BEModules.PerennialCropland),
		}),
	});
}


export const initialValues: PerennialCroplandPayload = {
	id: null,
	status: null,
	is_biomass_burned_start: false,
	is_biomass_burned_w: false,
	is_biomass_burned_wo: false,
	area: null,
	crop_yield_t2_start: null,
	crop_yield_t2_w: null,
	crop_yield_t2_wo: null,
	land_use_type_start: null,
	land_use_type_w: null,
	land_use_type_wo: null,
	land_use_type_thread: null,
	tillage_management_type_start: null,
	tillage_management_type_w: null,
	tillage_management_type_wo: null,
	tillage_management_type_thread: null,
	organic_input_type_start: null,
	organic_input_type_w: null,
	organic_input_type_wo: null,
	organic_input_type_thread: null,
	is_biomass_burned_thread: null,
	crop_yield_t2_thread: null,
	note: null,
	activity: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
};




/* T2 */
export const t2InitialValues: PerennialCroplandT2Payload = {
	agb_t2_start: null,
	agb_t2_w: null,
	agb_t2_wo: null,
	tillage_factor_t2_start: null,
	tillage_factor_t2_w: null,
	tillage_factor_t2_wo: null,
	bgb_t2_start: null,
	bgb_t2_w: null,
	bgb_t2_wo: null,
	agb_max_t2_start: null,
	agb_max_t2_w: null,
	agb_max_t2_wo: null,
	input_factor_t2_start: null,
	input_factor_t2_w: null,
	input_factor_t2_wo: null,
	residue_availability_t2_start: null,
	residue_availability_t2_w: null,
	residue_availability_t2_wo: null,
	fire_periodicity_t2_start: null,
	fire_periodicity_t2_w: null,
	fire_periodicity_t2_wo: null,

	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,
	ag_t2_start: null,
	ag_t2_w: null,
	ag_t2_wo: null,
	bg_t2_start: null,
	bg_t2_w: null,
	bg_t2_wo: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
};


