import { apiSlice } from "../apiSlice";
import { convertEmptyStringsToNull, convertIncomingPercentage, convertOutgoingPercentage } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { ProcessingEntryDefaults, ProcessingEntryData, ProcessingEntryResponse, } from "../../../../pages/activityBuilder/modules/processing/processingTypes";
import { EntryCreatePayload, EntryDeletePayload, EntryUpdatePayload, ParentUpdatePayload, ParentResponse, ParentPayload } from "../../../../types/modulesInterfaces";

const parentEndpoint = "processings/"
const entryEndpoint = "processing-entries/"

const processingApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getProcessings: builder.query<ParentResponse, { activityId: number }>({
      query: ({ activityId }) => `processings/?activity=${activityId}`,
      transformResponse: (response: ParentResponse[]) => {
        return response[0]
      },
      providesTags: (_result, _err, { activityId }) => [
        { type: 'Processings', id: activityId }
      ]
    }),
    updateProcessings: builder.mutation<ParentResponse, ParentUpdatePayload<ParentPayload>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `${parentEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'Processings', id: activityId },
        { type: 'ModulesStatuses', id: activityId },
        { type: 'ModuleResults', id: body?.id ? `processings-${body.id}` : undefined },
        { type: 'ModuleHistory', id: body?.id ? `processings-${body.id}` : undefined },
      ]
    }),
    getProcessingEntriesDefaults: builder.query<ProcessingEntryDefaults, number>({
      query: (id) => `${entryEndpoint}${id}/defaults/`,
      providesTags: (_result, _err, id) => [{ type: 'ProcessingEntriesDefaults', id }]
    }),
    getProcessingEntries: builder.query<ProcessingEntryData[], { activityId: number }>({
      query: ({ activityId }) => `${entryEndpoint}?activity=${activityId}`,
      transformResponse: (response: ProcessingEntryData[]) => {
        response.forEach(entry => convertIncomingPercentage(entry, ["transmission_loss_t2"]));
        return response.sort((a, b) => a.id - b.id)
      },
      providesTags: (_result, _err, { activityId }) => [{ type: 'ProcessingEntries', id: activityId }]
    }),
    updateProcessingEntry: builder.mutation<ProcessingEntryResponse, EntryUpdatePayload<ProcessingEntryResponse>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        convertOutgoingPercentage(rest, ["transmission_loss_t2"]);
        return { url: `${entryEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_result, _err, { activityId, body }) => [
        { type: 'ProcessingEntries', id: activityId },
        { type: 'ProcessingEntriesDefaults', id: body?.id ? body?.id : undefined },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    createProcessingEntry: builder.mutation<ProcessingEntryResponse, EntryCreatePayload>({
      query: ({ activityId, parent }) => ({ url: `${entryEndpoint}`, method: 'POST', body: { activity: activityId, parent } }),
      invalidatesTags: (_result, _err, { activityId, parent }) => [
        { type: 'ProcessingEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `processings-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `processings-${parent}` : undefined },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
    deleteProcessingEntry: builder.mutation<void, EntryDeletePayload>({
      query: ({ id }) => ({ url: `${entryEndpoint}${id}/`, method: 'DELETE' }),
      invalidatesTags: (_result, _err, { activityId, parent, id }) => [
        { type: 'ProcessingEntries', id: activityId },
        { type: 'ModuleResults', id: parent ? `processings-${parent}` : undefined },
        { type: 'ModuleHistory', id: parent ? `processings-${parent}` : undefined },
        { type: 'ProcessingEntriesDefaults', id },
        { type: 'ModulesStatuses', id: activityId }
      ]
    }),
  })
});

export const { useGetProcessingsQuery, useUpdateProcessingsMutation, useGetProcessingEntriesQuery, useUpdateProcessingEntryMutation, useCreateProcessingEntryMutation, useDeleteProcessingEntryMutation, useGetProcessingEntriesDefaultsQuery } = processingApiSlice;