import type { FormikHelpers } from "formik";
import type { BuildingPayload, ModuleFull, OtherPayload, RoadPayload } from "./settlementsTypes";
import { Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  initialValues,
  validationSchema,
  getSchemas,
  t2InitialValues,
} from "./settlements";
import { useAppSelector, useSyncNotesWithStore, useThreadsProcessor } from "../../../../app/hooks";
import { BEModules } from "../../../../utils/beModules";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useGetBuildingsQuery, useGetOthersQuery, useGetRoadsQuery, useGetSettlementsDefaultsQuery, useGetSettlementsQuery, useUpdateBuildingsMutation, useUpdateOthersMutation, useUpdateRoadsMutation, useUpdateSettlementsMutation } from "../../../../app/features/api/modules/settlementsApislice";
import SettlementTierOne from "./SettlementTierOne";
import GenericTierTwo from "../GenericTierTwo";
import useErrorMessage from "../../../../utils/useErrorMessage";
import useModuleHook from "../useModuleHook";
import ModuleContext from "../../ModuleContext";

const Settlements = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { belongsToLuc } = useContext(ModuleContext);

  const { data: settlementData, isLoading, isError, error } = useGetSettlementsQuery({ activityId: activeActivityId ?? 0 });
  const [updateSettlements, { error: updateErrorSettlements, isError: isUpdateErrorSettlements, isLoading: isLoadingUpdateSettlements }] = useUpdateSettlementsMutation()
  const { data: roadsData, isLoading: isLoadingRoads, isError: isErrorRoads, error: errorRoads } = useGetRoadsQuery({ activityId: activeActivityId ?? 0 }, {
    refetchOnMountOrArgChange: true,
  })
  const { data: buildingsData, isLoading: isLoadingBuildings, isError: isErrorBuildings, error: errorBuildings } = useGetBuildingsQuery({ activityId: activeActivityId ?? 0 }, {
    refetchOnMountOrArgChange: true,
  })
  const [updateRoads, { error: updateErrorRoads, isError: isUpdateErrorRoads, isLoading: isLoadingUpdateRoads }] = useUpdateRoadsMutation()
  const [updateBuildings, { error: updateErrorBuildings, isError: isUpdateErrorBuildings, isLoading: isLoadingUpdateBuildings }] = useUpdateBuildingsMutation()

  const { data: othersData, isLoading: isLoadingOthers, isError: isErrorOthers, error: errorOthers } = useGetOthersQuery({ activityId: activeActivityId ?? 0 }, {
    refetchOnMountOrArgChange: true,
  })
  const [updateOthers, { error: updateErrorOthers, isError: isUpdateErrorOthers, isLoading: isLoadingUpdateOthers }] = useUpdateOthersMutation()
  const { data: parentDefaults, isError: isDefaultsError, error: defaultsError } = useGetSettlementsDefaultsQuery(settlementData?.module.id ?? 0, { skip: !settlementData?.module.id })
  const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });

  const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
  const [initValues, setInitValues] = useState<ModuleFull>({
    module: initialValues,
    tiertwo: t2InitialValues,
    roads: [],
    buildings: [],
    others: [],
  });

  const { tabsOpen, errorMsg } = useModuleHook({
    skeletonsLoadingDeps: [isLoading, isLoadingRoads, isLoadingBuildings, isLoadingUpdateBuildings, isLoadingUpdateRoads, isLoadingUpdateSettlements, isLoadingOthers, isLoadingUpdateOthers],
    isMutateError: isUpdateErrorSettlements || isUpdateErrorRoads || isUpdateErrorBuildings || isUpdateErrorOthers,
    mutateError: updateErrorSettlements ?? updateErrorRoads ?? updateErrorBuildings ?? updateErrorOthers,
  });
  const { errorMsg: blockingError } = useErrorMessage({ isError, error });
  const { errorMsg: blockingErrorRoads } = useErrorMessage({ isError: isErrorRoads, error: errorRoads });
  const { errorMsg: blockingErrorBuildings } = useErrorMessage({ isError: isErrorBuildings, error: errorBuildings });
  const { errorMsg: blockingErrorOthers } = useErrorMessage({ isError: isErrorOthers, error: errorOthers });
  const { processModuleThreads } = useThreadsProcessor<ModuleFull>();
  useEffect(() => {
    if (!buildingsData || !roadsData || !othersData) return
    processModuleThreads({ roads: roadsData, buildings: buildingsData, others: othersData })
  }, [processModuleThreads, roadsData, buildingsData, othersData])

  const { notes } = useSyncNotesWithStore({
    notes: settlementData?.module.note ?? null,
  })

  useEffect(() => {
    if (settlementData) setInitValues((cur) => ({ ...cur, module: settlementData.module, tiertwo: { ...settlementData.tiertwo, ...parentDefaults } }));
  }, [settlementData, parentDefaults]);

  const handleSubmit = async (
    values: ModuleFull,
    { resetForm }: FormikHelpers<ModuleFull>
  ) => {
    try {
      const promises = [];

      // Update roads
      for (const dist of values.roads) {
        const roadPayload: RoadPayload = {
          body: dist,
          activityId: activeActivityId ?? 0,
        };

        if (dist.id) promises.push(updateRoads(roadPayload));
      }

      // Update buildings
      for (const dist of values.buildings) {
        const buildingPayload: BuildingPayload = {
          body: dist,
          activityId: activeActivityId ?? 0,
        };

        if (dist.id) promises.push(updateBuildings(buildingPayload));
      }

      // Update others
      for (const dist of values.others) {
        const otherPayload: OtherPayload = {
          body: dist,
          activityId: activeActivityId ?? 0,
        };

        if (dist.id) promises.push(updateOthers(otherPayload));
      }

      // Update settlements after all other updates
      await Promise.all(promises);
      if (values.module.id) await updateSettlements({ data: values, activityId: activeActivityId ?? 0 });

      resetForm({ values });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(belongsToLuc)}
      enableReinitialize
    >
      <>
        <SettlementTierOne
          title="project.settlements_and_infrastructure"
          initValues={initValues}
          t2Schema={t2Schema}
          beModuleType={BEModules.Settlements}
          moduleSchema={moduleSchema}
          footerError={errorMsg}
          blockingError={blockingError ?? blockingErrorRoads ?? blockingErrorBuildings ?? blockingErrorOthers}
          isError={isUpdateErrorBuildings || isUpdateErrorRoads || isUpdateErrorOthers || isUpdateErrorSettlements}
          setInitValues={setInitValues}
          note={notes?.content ?? null}
        />
        <GenericTierTwo
          title="project.settlements_and_infrastructure"
          t2Schema={t2Schema}
          tabsOpen={tabsOpen}
          warningMessage={defaultsErrorMsg}
          setInitValues={setInitValues}
        />
      </>
    </Formik>
  );
};

export default Settlements;
